.dashboardComponentMainDiv {
    padding: 1rem 0;

    .threatWatchMainDiv {
        padding: 1rem;
        margin-top: 0.25rem;
        border-radius: 10px;

        .threatWatchTextDiv {
            .title {
                font-size: 1.625rem;
                font-weight: 600;
            }

            .subTitle {
                font-size: 0.875rem;
                font-weight: 600;
            }

            .workSpaceStripeDiv {
                position: relative;
                margin-top: 1.2rem;
                text-align: center;
                color: #55597c;
                background: #FFFFFF;
                width: 245px;
                border-radius: 80px;
                padding: 1rem 0.5rem;
                padding-left: 3rem;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.21;
                letter-spacing: normal;
                color: #31334c;

                @media only screen and (max-width: 1250px) {
                    width: 230px !important;
                    font-size: 11px !important;
                }

                .thumbIconDiv {
                    width: 47px;
                    height: 47px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #ffffff;
                    border-radius: 25px;
                    padding-top: 12px;
                }
            }
        }

        .dashboardPeopleImageMaiDiv {
            img {
                max-width: 100%;
            }
        }
    }

    .dashboardPeopleAndDateMainDiv {
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        margin-bottom: 1rem;

        .dashboardPeopleAndEmployeeMainDiv {
            display: inline-block;
            padding: 0.2rem 0.5rem;
            border-radius: 25px;
            background-color: #55597c;
            color: #FFFFFF;
            font-size: 12px;
            width: 32rem;
            font-size: 0.75rem !important;
            text-align: center;
            font-weight: bold;

            .col-lg-3 {
                width: 25%;
                flex: 0 0 25%;
            }

            .deviceStatus {
                padding: 0 0 4px;
            }

            .peopleOnPremisesInnerDiv {
                padding-left: 1rem;
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                padding-top: 0.25rem;
                width: 120%;


                img {
                    margin-right: 0.5rem;
                }

                span {
                    vertical-align: middle;
                    display: inline-block;
                    width: 47%;
                }
            }

            .employeeCountInnerDiv {
                text-align: left;
                display: inline-block;
                vertical-align: middle;

                .empCount {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }



    .dashboardLanguageMainDiv {
        display: inline-block;
        vertical-align: super;
        width: 20%;
    }

    .dashboardGraphAndIndexMainDiv {
        margin-top: 1rem;
        background: #FFFFFF;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2);

        .populationRiskMainDiv {
            border: solid 1px #e0e0e0;
            background-color: #f5f7fa;
            border-radius: 6px;
            width: 100%;
            cursor: pointer;
        }

        .populationRiskPadding {
            padding: 3rem 1rem;
        }

        .utilityPadding {
            padding: 1.5rem 1rem;
        }

        .indexBox {
            display: flex;
            align-items: center;
            height: 100%;

            .indexText {
                font-size: 1rem;
                font-weight: 600;
            }

            img {
                height: 16px;
                width: 16px;
                margin-left: 5px;
            }

            .indexDesc {
                display: none;
                position: absolute;
                top: 75%;
                left: 75%;
                width: 15rem;
                border-radius: 0.2rem;
                background-color: #000000;
                color: #FFFFFF;
                padding: 8px;
            }
        }

        .riskLevelMainDiv {
            padding: 12px 16px;
            text-align: center;
            border-radius: 0.5rem;

            .riskLevelTitleDiv {
                font-size: 1.2rem;
                font-weight: 600;
            }

            .riskLevelSubtitleDiv {
                font-size: 0.70rem;
                font-weight: 600;
            }
        }

        // .selectedRiskLevelDiv{
        //     background-image: linear-gradient(151deg, #04e06e, #a5e006);
        //     border-radius: 0.5rem;

        // }

        .activeTab {
            background-color: #fef0f4;
            border-color: #ea5d8b;
            color: #ea5d8b;
        }

        .pinkArrowIconDiv {
            text-align: center;
            position: relative;

            img {
                position: absolute;
                right: 1rem;
            }
        }

        .spreadMobilityAreaIndexMainDiv {
            width: 95%;
            margin: 0 auto;
        }

        .negativeMarginTop {
            margin-top: -1.5rem;
        }

        .chartView {
            margin: 2.5rem 0.5rem;
        }
    }
}

.threatWatchAnalyticsMainDiv {
    display: flex;

    .eachColumnDiv {
        flex: 1;

        // padding: 1em;

    }

    .contaminatedMainDiv {
        background: #f5f7fa;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    .contactRankStartDateEndDateMainDiv {
        background: rgba(245, 247, 250, 0.5);
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;

        .contactRankText {
            font-size: 0.875rem;
            color: #31334c;
            padding: 0rem 0.75rem;
        }
    }

    .contaminatedDetails {
        padding: 1rem;
        display: inline-block;
        // vertical-align: middle;
        width: 50%;

        .titleText {
            font-size: 0.875rem;
        }

        &:last-child {
            border-right: thin #e0e0e0 solid;
        }

        .eachRecordDiv {
            display: block;
            vertical-align: middle;

            .countDiv {
                margin-top: 1.8rem;
                font-size: 1.5rem;
            }

            &:first-child {
                margin-left: 0;
            }

            .labelDiv {
                font-size: 0.75rem;
                // display: flex;
            }
        }
    }
}

.greenGradienColor {
    background-image: linear-gradient(96deg, #04e06e 20%, #a5e006 111%);
}

.redGradientColor {
    background-image: linear-gradient(110deg, #ff6f55, #efc56e);
}

.contactRankSelectDropdownDiv {
    padding: 0.15rem 0.75rem;
    position: relative;

    select {
        background: url('../../assets/images/down-arrow.png') no-repeat 98%;
        background-size: 16px;
        width: 100%;
        padding: 0.05rem 0.5rem;
        border: thin #e3e4eb solid;
        outline: none;
        border-radius: 0.25rem;
        background-color: #FFFFFF;
        cursor: pointer;
        -o-appearance: none;
        -ms-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
            border: thin #e3e4eb solid;
            outline: none;
        }
    }

    .dropdownIconDiv {
        position: absolute;
        right: 1.2rem;
        top: 0.15rem;

        img {
            width: 16px;

        }

    }
}

.fontGreen {
    color: #008000;
}

.fontOrange {
    color: #FFA500;
}

.descHelp {
    display: none;
    position: absolute;
    z-index: 100;
    // top: 100%;
    max-width: 15rem;
    border-radius: 0.2rem;
    background-color: #000000;
    color: #ffffff;
    padding: 8px;
}

.ds-helpicon {
    height: 16px;
    width: 16px;
    margin-left: 5px;
}

.align-right.ct-data.col-lg-5 {
    position: relative;
}

.ct-data .ct-graph-data {
    position: absolute;
    right: 15px;
    top: -60px;
    border: solid 1px #e0e0e0;
    background-color: #f5f7fa;
    width: 100%;
    height: 165px;
    border-radius: 8px !important;
    padding: 7px 15px;
    overflow: hidden;
}

.ct-data .ct-graph-data .col {
    border-bottom: 1px solid #ddd;
    line-height: 27px;
}

.ct-graph-data .row {
    margin: 0px;
    cursor: pointer;
}

.ct-data .ct-graph-data .col {
    border-bottom: 1px solid #ddd;
    line-height: 25px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 12px;
    height: 25px;
}

.ct-data .ct-graph-data .row:last-child .col {
    border-bottom: 0px;
}

.ct-data .ct-graph-data .col .font-bold {
    line-height: 25px;
    padding-left: 0px;
    padding-right: 20px;
    font-size: 12px;
    height: 25px;
    font-weight: 400;
    text-align: right;
}

.smaller-header {
    float: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    line-height: 25px;

    .header-left {
        float: left;
    }

    .header-right {
        float: right;
        text-align: right;
    }

    .tab-small {
        div {
            display: inline-block;
            cursor: pointer;
        }

        .tab-1.tab-tiny {
            margin-right: 15px;
        }

        .tab-tiny.active {
            color: #ef5e8c;
            border-bottom: 1px solid;
            margin-bottom: -1px;
        }
    }
}

.loader-inner img {
    max-width: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
}

.loader-inner {
    text-align: center;
    vertical-align: middle;
}

.ct-graph-data.ct-graph-data-false .loader-inner {

    display: none;
}

.ct-data .ct-graph-data .row .col:first-child .font-bold {
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    display: inline-block;
    text-transform: capitalize;
}

.header-right strong {
    font-weight: 500;
    color: #ef5e8c;
    border-bottom: 1px solid;
    line-height: 25px;
    display: inline-block;
    margin-bottom: -1px;
}



.contactRankText.contactRank-range .ant-slider-handle {
    margin-top: -5px;
    background-color: #fff;
    border: solid 2px #ef5e8c;
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.contactRankText.contactRank-range .ant-slider-track {
    background-color: #ef5e8c !important;
}

.contactRankText.contactRank-range .ant-slider:hover .ant-slider-track {
    background-color: #ef5e8c !important;

}

.contactRankText.contactRank-range .ant-slider-dot-active {
    background-color: #ef5e8c !important;
    border-color: #fff;
}

.contactRankText.contactRank-range .ant-slider-mark .ant-slider-mark-text {
    font-size: 12px;
}

.contactRankText.contactRank-range .ant-slider-handle {
    margin-top: -5px;
    background-color: #ef6590;
    border: solid 2px #fff;
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #fff !important;
}

.ant-slider-track::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(96deg, #04e06e 20%, #a5e006 111%);
}

.contactRankText.contactRank-range.contactRank-range-2 .ant-slider-track::before {
    background: linear-gradient(181deg, #fa7e5e, #fecf89);
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #f3f3f3;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #ffff;
}

.threatWatchAnalyticsMainDiv {
    height: 180px;
}

//Responsive 
@media only screen and (max-width: 1500px) {
    .threatWatchAnalyticsMainDiv .contaminatedDetails .titleText {
        font-size: 0.775rem;
    }

    .threatWatchAnalyticsMainDiv .contaminatedDetails {
        padding: .9rem;
    }

    .contactRankStartDateEndDateMainDiv .col-lg-6:last-child {
        padding-left: 0px;
    }

    .threatWatchAnalyticsMainDiv .contactRankStartDateEndDateMainDiv .contactRankText {
        font-size: 0.8rem;
    }

    .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .utilityPadding .col-lg-6 {
        max-width: 35%;
    }

    .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .utilityPadding .col-lg-4 {
        max-width: 40%;
        flex: 0 0 40%;
    }

}