.tabs {
    margin-bottom: 20px;
}

.root-node {
    font-weight: bold;
}

.chart-container ul li li li {
    font-size: 14px;
}

.chart-container ul li li li li {
    font-size: 13px;
}

.chart-container ul li li li li li {
    font-size: 12px;
}

.chart-container ul li li li li li {
    font-size: 12px;
}