.visitor-col .minHeight-200 {
	background-color: #fff;
	padding: 20px;
	border-radius: 4px;
}
.visitor-col .visitor .title {
	font-size: 16px;
	font-weight: 600;
}
.sub-stats-visitor {
	display: table;
    width: 100%;
}
.visitor-col .stats-cols {
	display: table-cell;
	min-width: 30%;
	border-right: 1px solid #ddd;
	text-align: center;
}
.visitor-col .stats-cols .countBigger {
	font-size: 32px;
}
.visitor-col .stats-cols:nth-of-type(2) {
	min-width: 40%;
}
.visitor-col .visitor {
	margin-bottom: 20px;
}
.visitor-col .stats-cols:first-child {
	padding-left: 0px;
}
.visitor-col .stats-cols:last-child{
border-right:0px;
}

.visitor-col .visitor .title img {
	max-width: 60px;
	margin-right: 0px;
	max-height: 40px;
	top: 0;
	bottom: 0;
	vertical-align: middle;
	margin: auto;
	position: absolute;
	left: 0;
	right: 0;
}
.visitor-col .visitor .title .bg-icon {
	max-width: 60px;
	display: inline-block;
	background-color: #ecf2f9;
	max-height: 60px;
	border-radius: 50%;
	width: 70px;
	height: 70px;
	vertical-align: middle;
    position: relative;
}
.minHeight-200.check-in .visitor,.minHeight-200.check-out .visitor{
    margin-bottom: 0px;
}
.minHeight-200.check-in {
    text-align: center;
    margin-bottom: 0px;
    height: 100%;
    vertical-align: middle;
    display: table;
    width: 100%;
    cursor: pointer;
}
.minHeight-200.check-in .visitor, .minHeight-200.check-out .visitor {
    margin-bottom: 0px;
    display: table-cell;
    vertical-align: middle;
}

.visitor-col .visitor .count-visitor {
	position: relative;
	font-size: 28px;
	background-color: #ecf2f9;
	width: 34px;
	text-align: center;
	display: inline-block;
	right: 0;
	border-radius: 20px;
	font-size: 18px;
}
.siteManagementMainDiv-Visitor.siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .col {
	padding-right: 5px;
	padding-left: 5px;
}
.siteManagementMainDiv-Visitor.siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .col:nth-of-type(4) {
	min-width: 9% !important;
	max-width: 9% !important;
}
.siteManagementMainDiv-Visitor.siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .col:nth-of-type(6){
	min-width: 8% !important;
	max-width: 8% !important;
}
.siteManagementMainDiv-Visitor.siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .col:nth-of-type(7){
	min-width: 8% !important;
	max-width: 8% !important;
}
.siteManagementMainDiv-Visitor.siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .col:nth-of-type(8){
	min-width: 7% !important;
	max-width: 7% !important;
}
.siteManagementMainDiv-Visitor.siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .col:last-child{
	min-width: 3% !important;
	max-width: 3% !important;
}
.visitor-col .minHeight-200 {
	background-color: #fff;
	padding: 20px;
	border-radius: 4px;
	transition: all ease-in .3s;

}
.minHeight-200.check-in:hover{
    background-color: #ef5e8c;
}
.visitor-col .minHeight-200.check-in:hover .visitor .title .bg-icon {
	background-color: #fff;
}
.minHeight-200.check-in:hover .visitor {
	color: #fff;
}
.createPolicyMainDiv.createPolicyMainDiv-Less-space {
	padding: 1rem;
}
.createPolicyMainDiv.createPolicyMainDiv-Less-space .actiondiv {
	margin-top: 0px;
	width: 100%;
	padding: 0px 15px;
}
.createPolicyMainDiv.createPolicyMainDiv-Less-space .actiondiv .btn.btn-submit{
	max-width: 100%;
	width: 100%;
}
.createPolicyMainDiv.createPolicyMainDiv-Less-space .activeSelect .ant-select-selection-item {
    display: block;
}
.createPolicyMainDiv.createPolicyMainDiv-Less-space .error-message {
	font-size: 12px;
	background-color: transparent;
	margin-top: 3px;
	margin-bottom: 0px;
	width: 100%;
	border: 0px;
	padding: 0px;
}
.visitor-loader {
	text-align: center;
}
.createPolicyMainDiv-Less-space .visitor-loader {
	display: none;
}
.createPolicyMainDiv-Less-space.loading form{display: none;}
.createPolicyMainDiv-Less-space.loading .message-text{display: none;}
.createPolicyMainDiv-Less-space.loading .visitor-loader {
	display: block;
}
.createPolicyMainDiv .activeSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector{border: 0px;}
.createPolicyMainDiv.createPolicyMainDiv-Less-space.visitorcheckIn {
	text-align: center;
}
.visitorcheckIn .logoutTitle {
	font-size: 24px;
	margin-top: 20px;
	margin-bottom: 20px;
}
.visitorcheckIn .btnText.okBtn.bnt-close-bigger {
	min-width: 200px;
	background-color: #ef5e8c;
	display: inline-block;
	padding: 10px;
	color: #fff;
	border-radius: 4px;
}
.message-text {
	text-align: center;
}


.createPolicyMainDiv input, .createPolicyMainDiv .ant-select .ant-select-selection-item {
	text-transform: capitalize;
}
.crossicon {
	position: absolute;
	right: 10px;
	top: 10px;
	width: 30px;
	cursor: pointer;
}
.VisitorHeaderTab .tabVisitor.commonHeading:first-child {
	border-radius: 10px 0px 0px 10px;
  }
  .VisitorHeaderTab .tabVisitor.commonHeading {
	display: inline-block;
	font-size: 14px;
	padding: 10px 20px;
	background: #fff;
	border-radius: 0px 10px 10px 0px;
	color: #32325d;
	cursor: pointer;
	min-width: 180px;
	text-align: center;
  }
  .VisitorHeaderTab .tabVisitor.commonHeading.tabVisitorActive {
	background: #55597c;
	color: #fff;
  }
   .resp-team-box.col-lg-2 .startDateEndDateMainDiv {
		float: left;
		width: 100px;
	}