

  .css-hdw1oc {
    display: none;
  }
  .MuiButtonBase-root.MuiIconButton-root[aria-label="Choose time"] {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    text-align: right;
    border-radius: 0px !important;
    height: 35px;
    background: transparent !important;
  }
  .MuiButtonBase-root.MuiIconButton-root[aria-label="Choose time"] .MuiSvgIcon-root {
    position: absolute;
    right: 5px;
  }
  .ant-btn.ant-btn-default.autoplayStop.btn-smaller {
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none !important;
    padding: 0px !important;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: -3px;
    width: 60px;
    margin: auto;
    display: none;
  }
  .inlineBlockFilter.inlineBlockFilterLabel label,  .inlineBlockFilter.inlineBlockFilterLabel span {
    margin-bottom: 0px;
    padding: 0px;
    line-height: 35px;
  }
  .nicerFormatRange.row .ant-slider.ant-slider-disabled.ant-slider-horizontal.ant-slider-with-marks {
    margin-bottom: 10px !important;
  }
  
  .nicerFormatRange .ant-slider.ant-slider-horizontal.ant-slider-with-marks {
    margin-bottom: 10px !important;
  }
  .errorDiv.errorMessage {
    color: #b63d3d;
    background: #f2f2f2;
    padding-left: 14px;
    border-left: 2px solid;
    margin-top: 5px;
    line-height: 1;
    font-size: 13px;
    padding: 5px 13px;
  }
  .loaderStyle {
    background: #f2f2f2;
    padding: 10px 25px 1px;
    margin-bottom: 10px;
  }
  .loadingInfo h4 {
    font-size: 18px;
  }
  .loadingInfo h4 span{margin-right: 5px;}
  .legendArea.manStyle.legendArea2 .legendList .legendLeftAreaProd {
    width: 60%;
  }
  .legendArea.manStyle.legendArea2 .legendList .legendRightAreaProd {
    width: 35%;
    float: right;
  }
  .loaderStyle.loadStyleSucess h4 {
    color: #52ad31;
  }
  .loaderStyle.loadStyleSucess {
    border-left: 3px solid #51ac30;
    color: #52ad31;
  }
  .loaderStyle.loadStyleError{
    color:#b63d3d;
    border-left: 3px solid #b63d3d;
  }
  .loaderStyle.loadStyleError h4{
    color:#b63d3d;
  }
  .tooltipCustom {
    position: absolute;
  }
  .tooltipCustom .tooltipArea {
    background: #000;
    width: 75px;
    border-radius: 25px;
    color: #fff;
    font-size: 12px;
    left: -15px;
    position: absolute;
  }
  .sliderLeftTooltip {
    z-index: 9;
    position: relative;
  }
  .legendSwitch {
    height: 35px;
    background: #f2f2f2;
    padding: 5px 10px;
    border-radius: 25px;
    margin-bottom: 5px;
  }
  .legendSwitch span.title {
    line-height: 17px;
    display: inline-block;
    padding-left: 5px;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    top: 2px;
  }
  .legendArea.manStyle.legendArea2 .legendList.three-colsHeader,
  .legendArea.manStyle.legendArea2 .legendList.three-colsHeader{font-size: 11px;}
  .legendArea.manStyle.legendArea2 .legendList.three-colsHeader .legendLeftAreaProd {
    width: 50%;
  }
  .legendArea.manStyle.legendArea2 .legendList.three-colsHeader .legendLeftAreaTime {
    width: 25%;
    float: left;
    text-align: center;
  }
  .legendArea.manStyle.legendArea2 .legendList.three-colsHeader .legendRightAreaProd {
    width: 25%;
  }
  .legendSmallDate {
    font-size: 12px;
    position: relative;
    right: 0px;
    top: 0px;
    background: #ecf2f9;
    padding: 4px 10px;
    border-radius: 3px;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
    text-align: left;
  }
  .legendLeftAreaProd .empName {
    display: block;
    line-height: 1;
    font-size: 10px;
    color: #000;
    margin-bottom: 4px;
  }
  @media only screen and (max-width:767px){
    .nicerFormatRange.row {
      width: 95%;
      margin: 15px auto;
    }
  }
  @media only screen and (min-width:767px){
 
  .manStyle .inlineBlockFilter {
    float: left;
  }
  .nicerFormatRange.row {
    margin: 10px 0px;
  }

}
.inlineBlockFilterRight .ant-select .ant-select-selector {
  height: 34px;
  overflow: hidden;
  padding: 0px 4px;
  border: 1px solid #ddd;
  margin-top: -5px;
}
// .inlineBlockFilterRight .ant-select  {
//   border: 1px solid #ddd;
//   height: 34px;
//   background: #f5f5f5;
// }
.inlineBlockFilterRight .ant-select.ant-select-multiple .ant-select-selector {
  height: 34px;
  overflow-y: scroll;
}
// .inlineBlockFilterRight .ant-select-selection-overflow {
//   height: 30px;
// }
.inlineBlockFilterRight .ant-select.ant-select-multiple .ant-select-selection-item {
  display: inline-block;
  font-size: 12px;
}
.inlineBlockFilterRight .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #ddd;
  height: 34px;
  box-shadow:unset;
}
.inlineBlockFilterRight .ant-select.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content {
  padding-right: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.inlineBlockFilterRight .ant-select-multiple .ant-select-selection-item-remove {
  position: absolute;
  right: 0px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 7px;
  line-height: 10px;
  background: #ef5e8c;
  color: #fff;
  padding: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.inlineBlockFilterRight {
  float: right;
}
.inlineBlockFilterRight .ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
  margin-right: 0px;
}
.lagLoader {
  top: 0;
  bottom: 0;
  position: fixed;
  text-align: center;
  background: #e0dfdf80;
  left: 0;
  right: 0;
}
.lagLoader .lagInner {
  height: 300px;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  text-align: center;
  width: 300px;
  left: 0;
  right: 0;
}