.createPolicyMainDiv {
    background: #FFFFFF;
    padding: 2rem;
    border-radius: 0.5rem;

    .activeSelect {
        &::before {
            position: absolute;
            right: 20px;
            top: 20px;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
            margin-left: auto;
            content: "";
            z-index: 5;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid #ef5e8c;
            pointer-events: none;
        }

        select {
            color: #434343;
            border-bottom: thin #e4e4e4 solid;
            width: 100%;
            font-weight: 600;
            font-size: 0.875rem;
            position: relative;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';
        }
    }

    label {
        font-size: 0.85rem;
        color: #a4a4a4;
        margin: 0;
        padding: 0;
    }

    input {
        padding: 0.5rem 0rem;
        border-bottom: thin #e4e4e4 solid;
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        width: 100%;
        color: #434343;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        border-bottom: thin #e4e4e4 solid;
        padding-left: 0;
        padding-right: 0;
    }

    .ant-select-focused {
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        box-shadow: none;
        border-bottom: thin #e4e4e4 solid;
        padding-left: 0;
        padding-right: 0;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-top: none;
        border-left: none;
        border-right: none;
        outline: none;
        border-color: none !important;
        box-shadow: none;
        border-bottom: thin transparent solid;
        padding-left: 0;
        padding-right: 0;
    }

    .policySpecificationsMainDiv {
        margin: 1rem 0;

        .eachPolicySpecificationMainDiv {
            background: #f6f7fb;
            padding: 0.75rem 1rem;
            margin: 0.5rem 0;
            cursor: pointer;

            ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #FFFFFF;
            }

            :-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #FFFFFF;
            }

            ::-ms-input-placeholder { /* Microsoft Edge */
                color: #FFFFFF;
            }

            input {
                padding: 0.5rem 0;
                border-bottom: thin #FFFFFF solid;
                border-top: none;
                border-left: none;
                border-right: none;
                outline: none;
                width: 100%;
                color: #FFFFFF;
                background: transparent;
            }

            .labelDiv {
                display: inline-block;
                vertical-align: middle;
                width: 60%;
                text-align: left;
                font-weight: 600;
            }

            .roundDiv {
                display: inline-block;
                vertical-align: middle;
                width: 15px;
                height: 15px;
                border-radius: 8px;
                margin-top: 0.25rem;
                float: right;
                border: thin #ef5e8c solid;
            }
        }

        .activePolicy {
            background: #ef5e8c;

            .labelDiv {
                color: #FFFFFF;
            }

            .roundDiv {
                background: #FFFFFF;
            }
        }
    }

    .addAreaPolicyMainDiv {
        width: 65%;
        float: right;
        background: #434343;
        padding: 0.85rem;
        border-radius: 0.5rem;

        h6 {
            margin: 0;
            padding: 0;
        }

        .eachPolicyAreaMainDiv {
            background: linear-gradient(90deg, #5c5e73, #44475a);
            padding: 0.5rem;
            margin-bottom: 0.5rem;
            color: #FFFFFF;
            position: relative;
            border-radius: 0.35rem;

            .addIconDiv {
                position: absolute;
                width: 18px;
                height: 18px;
                background: #FFFFFF;
                border-radius: 10px;
                right: 0.5rem;
                top: 0.5rem;
                color: #ef5e8c;
                cursor: pointer;

                span {
                    position: absolute;
                    top: -0.2rem;
                    left: 0.25rem;
                    font-size: 1rem;
                }
            }

            .propsMainDiv {
                .eachPropDiv {
                    background: transparent;
                    border-radius: 20px;
                    border: thin #FFFFFF solid;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 0.25rem 0.5rem;
                    display: inline-block;
                    vertical-align: top;
                    margin-bottom: 0.5rem;
                    margin-right: 0.5rem;
                }
            }
        }
    }

    select {
        background: transparent;
        padding: 0 0.15rem;
        padding-bottom: 0.5rem;
        color: #FFFFFF;
        border: none;
        outline: none;
        border-bottom: thin #FFFFFF solid;

        option{
            color: #434343;
        }
    }
}

.selectedPersonDiv {
    .eachSelectedDiv {
        background: #303249;
        padding: 0.15rem 0.35rem;
        border-radius: 0.15rem;
        color: #FFFFFF;
        display: inline-block;
        vertical-align: top;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        font-size: 0.75rem;

        .closeDiv {
            margin-left: 0.25rem;
            cursor: pointer;
        }
    }
}


.capacityViewDiv{
    input{
        display: inline-block;
        width: 70% !important;
        vertical-align: bottom;
    }

    .plusMinusDiv{
        display: inline-block;
        vertical-align: bottom;
        position: relative;
        right: 2.5rem;
        bottom: 0.5rem;
        
        div{
            width: 20px;
            height: 20px;
            border:thin white solid;
            text-align: center;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            cursor: pointer;
            font-size: 1rem;

        }
    }
}
.check_allow .roundDiv-checkbox {
	float: left;
}
.check_allow {
	float: left;
	width: 100%;
	text-align: left;
	margin-bottom: 0px;
}
.check_allow .roundDiv-checkbox .ant-radio-checked .ant-radio-inner::after {
	//top: 4px;
}
.createPolicyMainDiv .activeSelect.activeSelectStaus .ant-select-selection-item{
    display:block;
    }
.create-policy-modal {
    min-height: 630px;
    width: 1215px !important;
}
.actiondiv {
	text-align: right;
	padding: 0px 20px;
}
.createPolicyMainDiv label {
	margin: 0;
	padding: 0;
	font-size: 13px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: normal;
	text-align: left;
	color: #5b5757;
    width: 100%;
margin-top: 15px;
}
.createPolicyMainDiv .policySpecificationsMainDiv .activePolicy .labelDiv {
	margin-bottom: 10px;
}
.create-policy-modal .closer-modal {
	position: absolute;
	right: 0px;
	width: 35px;
	height: 35px;
	background-color: transparent;
	cursor: pointer;
	color: #ef5e8c;
	border: 1px solid #ef5e8c;
    z-index: 999;
    text-align: center;
    line-height: 35px;
}
.createPolicyMainDiv .activeSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: 0px !important;
	width: 100% !important;
	border-bottom: 1px solid #ddd !important;
	border-radius: 0px !important;
}
.createPolicyMainDiv .activeSelect .ant-select {
	width: 100% !important;
}

.createPolicyMainDiv input,
.createPolicyMainDiv .ant-select .ant-select-selection-item{

	border-bottom: 1px solid #dddd;
	padding: 10px 0px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.createPolicyMainDiv .activeSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: auto !important;
}
.createPolicyMainDiv .top-title h6 {
	line-height: 30px;
	font-weight: 600;
	color: #0d0d0e;
	font-size: 14px;
	color: #32325d !important;

}
.createPolicyMainDiv form{display: block;}
.createPolicyMainDiv .loader-icon{display: none;}
.createPolicyMainDiv.loading form {
	display: none;
}
.label-in-input {
	margin-bottom: 10px;
}
.label-in-input label {
	font-weight: bold;
	color: #000;
	position: absolute;
	line-height: 35px;
	padding-left: 20px;
	z-index: 999;
}
.label-in-select .ant-select {
	width: 100% !important;
}

.label-in-select .ant-select {
	width: 100% !important;
	border: 1px solid #dddd;
	padding: 0px 20px;
	padding-left: 20px;
	padding-left: 150px;
	line-height: 35px;
	border-radius: 6px !important;
}
.createPolicyMainDiv .label-in-select .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-bottom:0px  !important;
    
}
.label-in-input .activeSelect::before {
	display: none;
}

.tab-view.smaller-size {
	margin: 0px;
}
.loaderdov img {
	max-width: 60px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #646060;
	border-radius: 50%;
}
.loaderdov {
	text-align: center;
}
.tab-view.smaller-size .tab.tab-activity {
	line-height: 35px;
	padding: 0px;
	max-width: 100px;
	border: 1px solid #ddd;
}
.tab-view.smaller-size .tab.tab-activity.active {
	border-color: #ef5e8c;
}
.createPolicyContainer .createPolicyMainDiv {
    padding: 30px;
}
.m-t.margin-zero.row {
	margin-left: 0px;
	margin-right: 0px;
}
.createPolicyMainDiv .policySpecificationsMainDiv .eachPolicySpecificationMainDiv{
    background: #f5f7fa;
}
.createPolicyMainDiv .policySpecificationsMainDiv .eachPolicySpecificationMainDiv.activePolicy{
    background: #ef5e8c;
}
.createPolicyMainDiv .addAreaPolicyMainDiv .eachPolicyAreaMainDiv .propsMainDiv .eachPropDiv {
	width: 47%;
}
.createPolicyMainDiv .addAreaPolicyMainDiv .eachPolicyAreaMainDiv .propsMainDiv .eachPropDiv .countdiv {
	font-weight: bold;
	font-size: 16px;
	display: inline-block;
	padding-right: 7px;
	line-height: 30px;
	float: left;
	padding-left: 5px;
	text-align: left;
}
.createPolicyMainDiv .addAreaPolicyMainDiv .eachPolicyAreaMainDiv .propsMainDiv .eachPropDiv .fnt-small {
	line-height: 30px;
	float: left;
}
.createPolicyMainDiv .addAreaPolicyMainDiv .eachPolicyAreaMainDiv .propsMainDiv .eachPropDiv input {
	padding: 0px;
	line-height: 25px;
	background-color: transparent;
	height: 25px !important;
	display: block;
	box-sizing: border-box;
	border-bottom: 2px solid #fff;
	border-radius: 0px;
	color: #fff;
	float: left;
	max-width: 20px;
    text-align: center;
}

.actiondiv {text-align: right;margin-top: 15px;}
.actiondiv .btn.btn-reset{
display: inline-block;
background-color: #dcddde;
font-weight: 600;
border-radius: 4px;
color: #10141a;
min-width: 100px;
text-align: center;
margin-right: 10px;
}
.eachPolicySpecificationMainDiv .tableBox {
	background: #f6f7fb;
	border: 1px solid #ddd;
}
.capacityViewDiv .plusMinusDiv div {
	width: 20px;
	height: 20px;
	border: thin white solid;
	text-align: center;
	display: inline-block;
	justify-content: center;
	align-items: center;
	color: #FFFFFF;
	cursor: pointer;
	font-size: 1rem;
	line-height: 15px !important;
	cursor: pointer;
}
.eachPolicySpecificationMainDiv .employee-detail-table-tr-td {
	text-align: left;
}
.createPolicyMainDiv .policySpecificationsMainDiv .activePolicy .roundDiv-checkbox label{
    color:#fff;
    
}
.activePolicy .roundDiv-checkbox label::before{
    border-color:#fff;
}
.activePolicy .roundDiv-checkbox input:checked + label::after{
    background-color:#fff;
}
.actiondiv .btn.btn-submit{
    display: inline-block;
    line-height: 35px;
    padding: 0px;
    min-width: 100px;
    max-width: 100px;
    background-color: #ef5e8c;
    color: #fff;
    font-weight: 600;
    border-color: #ef5e8c;
}
.createPolicyMainDiv .addAreaPolicyMainDiv {
	width: 100%;
    height:100%;
}
.roundDiv-checkbox {
	float: right;
}
.roundDiv-checkbox input {
	display: none;
}
.roundDiv-checkbox label {
	position: relative;
	padding-left: 0px;
    padding-right: 10px;
	line-height: 16px;
    display: inline-block;
    margin-top: 0px;
    width: auto;
    cursor: pointer;
}
.roundDiv-checkbox .ant-radio-inner::after {
	background-color: #ef5e8c;
	// width: 9px !important;
	// height: 9px !important;
	// border-radius: 50%;
	// left: 2px;
	// top: 3px !important;
}
.check_allow .roundDiv-checkbox .ant-radio-inner::after {
	//top: 2.3px !important;
}
.createPolicyMainDiv .employee-detail-table-tr .ant-checkbox-wrapper {
	
	margin-top: 8px;
	margin-bottom: 8px;
}
.createPolicyMainDiv input[type="checkbox"] {
	float: left;
	width: auto;
    margin-top: 6px;
}
.createPolicyMainDiv .head-align {
	line-height: 25px;
	padding-left: 10px;
}
.roundDiv-checkbox .ant-radio-checked .ant-radio-inner {
	border-color: #ef5e8c;
}
.createPolicyMainDiv  .activeSelect .ant-select-selection-item {
	display: none;
}
.activeSelect::before {
	display: none;
}
.addAreaPolicyMainDiv .bg-grey {
    background: #f5f7fa;    padding:20px;
    margin-top: 15px;
}

