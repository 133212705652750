.manpowerManagementEmployeeListMainDiv {
  .report-filter {
    .ant-select {
      border: 1px solid #ddd;
      height: 37px;
    }

    .ant-select.ant-select-disabled {
      background-color: #f5f5f5;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
      padding-left: 10px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none;
      padding-left: 0px;
      border-radius: 0px !important;
    }

    .ant-select.ant-select-multiple .ant-select-selector {
      height: 34px;
      overflow-y: scroll;
    }

    .ant-select.ant-select-multiple .ant-select-selection-item {
      display: inline-block;
      font-size: 12px;

      .ant-select-selection-item-content {
        padding-right: 25px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .ant-select-multiple .ant-select-selection-item-remove {
      position: absolute;
      right: 0px;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      font-size: 7px;
      line-height: 10px;
      background: #ef5e8c;
      color: #fff;
      padding: 0px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}

@keyframes dot-keyframes {
  0% {
    opacity: .4;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: .4;
    transform: scale(1, 1);
  }
}

.loading-dots {
  text-align: center;
  width: 100%;

  &--dot {
    animation: dot-keyframes 1.5s infinite ease-in-out;
    background-color: #ef5e8c;
    border-radius: 10px;
    display: inline-block;
    height: 5px;
    width: 5px;
    margin-left: 2px;

    &:nth-child(2) {
      animation-delay: .5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }
  }
}

.loadingInfo .pink {
  font-weight: normal;
  color: #ef5e8c;
}

.loadingInner {
  width: 240px;
  margin: auto;
  background: #ecf2f9;
  padding: 10px;
  border-radius: 25px;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 90px;
}

.loadingInfo .pink {
  font-weight: bold;
  color: #ef5e8c;
}

.loadingInfo.loadingInfo2 .loadingInner {
  margin: 0px;
  text-align: center;
}

.anticon.anticon-check svg {
  fill: #ef5e8c;
}

.filterField {
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #fff;
  background: #f4f4f4;
}

.manpowerManagementEmployeeListMainDiv .report-filter .ant-select {
  border: 1px solid #ddd;
  height: 37px;
  background: #fff;
}

.manpowerManagementEmployeeListMainDiv .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #00000040;
  background: #fff;
  cursor: not-allowed;
}

.manpowerManagementEmployeeListMainDiv .report-filter .ant-select.ant-select-disabled {
  background-color: #fff;
  opacity: .8;
}

.report-filter .filterMinMax input {

  border: 1px solid #ddd !important;
}

.report-filter .filterMinMax {
  display: inline-block;
  max-width: 50%;
}

.report-filter .filterMinMax input {
  border: 0px;
}

.report-filter .filterMinMax:first-child input {
  border-right: 0px !important;
  border-radius: 0px !important;
}

.report-filter .filterMinMax input {
  box-shadow: none !important;
  font-weight: bold;
}

.report-filter.report-filter-less .ant-checkbox-group label {
  position: relative;
  top: -2px;
}

.report-filter.col-lg-1 .startDateEndDateMainDiv {
  padding: 0px;
}

.report-filter .ant-slider-rail {
  background-color: #fff;
  transition: background-color 0.3s;
}

.report-filter .ant-checkbox-group {
  background-color: #fff;
  padding: 5px;
  width: 100%;
}

.report-filter .ant-slider-with-marks {
  margin-bottom: 20px;
}

.ag-cell--textUppercase {
  text-transform: capitalize;
}

.ant-slider-with-marks {
  margin-bottom: 20px;
}

.mgSpace {
  padding: 5px 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.mgSpace strong {
  color: #ef5e8c;
}

.mgSpace label {
  margin-bottom: 0px;
}

.mgSpace .ant-slider-mark-text {
  font-size: 10px;
  font-weight: bold;
}

.report-filter .filterMinMax:last-child input {
  border-left: 0px !important;
  border-radius: 0px !important;
}

.report-filter .filterMinMax input {
  box-shadow: none !important;
}

.loadingInfo span {
  display: inline;
}

.btndiv .btn.btn-submit.btn-filter {
  background: #ef5e8c;
  border: 1px solid #ef5e8c;
  color: #fff;
}

.btn.btn-submit.btn-filter.btn-export {
  margin-left: 10px;
  background: #ef5e8c;
  border-color: #ef5e8c;
  color: #fff;
}

.btndiv .btn.btn-submit.btn-filter:hover {
  background: #ef2766;
  border-color: #ef2766;
  color: #fff;
}

.btn.btn-submit.btn-filter.btn-export:hover {
  background: #ef2766;
  color: #fff;
  border-color: #ef2766;
}

.reportChart .accordionHeader svg {
  display: inline-block;
  max-width: 15px;
  margin-right: 10px;
}

.reportChart .accordionHeader {
  line-height: 25px;
  display: inline-block;
  background: #ecf2f9;
  width: 100%;
  padding: 6px 10px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

.ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-bottom .ant-tooltip-inner {
  padding: 5px 10px;
  font-size: 13px;
  font-weight: 600;
}

.accordionContentReport {
  margin: 0px 0px 10px;

  padding: 20px 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.buttonChartFilter.button.btn:first-child {
  margin-left: 20px;

}

.buttonFilterArea {
  margin-bottom: 20px;
}

.rportArea h2 {
  color: #b92b2b;
  font-size: 16px;
  padding: 10px 20px;
  border: 2px solid;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 4px;
}

.btn.btn-edit.btn-smaller.btn-icon svg {
  max-width: 15px;
}

.btn.btn-edit.btn-smaller.btn-icon {
  font-size: 13px;
  padding: 0px;
  vertical-align: top;
  margin-top: 6px;
}

.listingRecordMainDiv.manpowerManagementEmployeeListMainDiv .ag-theme-alpine .ag-ltr .ag-cell {
  line-height: 30px !important;
}

.rportArea {
  width: 100%;
}

.buttonChartFilter.button.btn {
  background: #ddd;
  background: #fff !important;
  border-radius: 5px !important;
  border: 1px solid #ddd;
  margin-right: 4px;
  font-size: 12px !important;
  margin-bottom: 5px;
  padding: 5px 7px !important;
}

.buttonChartFilter.button.btn:hover,
.buttonChartFilter.buttonChartFilterActive.button.btn {
  background: #ef5e8c !important;
  color: #fff !important;
  border-color: #ef5e8c;
}

.bodyAreaModal .modalbodyList.row svg {
  max-width: 14px;
  cursor: pointer;
}

.singleRowList.active .contentBodyObject .row .serialHeader.contentAreaHeaderLi.timeStamp {
  width: 10%;
}

.singleRowList.active .contentBodyObject .row .serialHeader.contentAreaHeaderLi.contentAreaHeaderLiHour {
  width: 10%;
  border-right: 1px solid #ddd;
}

.contentBodyAreaSub.contentBodyAreaSubTime {
  height: 0px;
}

.contentBodyAreaSub.contentBodyAreaSubActive div {
  background: #ffd3e1 !important;
}

.contentBodyAreaSub.contentBodyAreaSubTime.active {
  height: auto;
}

.keyAGTable.keyAGTableLessZindex.keyAGTableLessSpace .ag-header-cell {
  padding: 0px 4px;
  font-size: 12px;
  text-align: center;
}

.keyAGTable.keyAGTableLessZindex.keyAGTableLessSpace .ag-cell {
  font-size: 12px;
  padding: 0px 4px;
}

.headerHightDouble .ag-header.ag-focus-managed.ag-pivot-off {
  height: 100px !important;
  min-height: 100px !important;
}

.ag-pinned-left-header {
  z-index: 99999;
  background: #f8f8f8;
}

.ag-pinned-left-cols-container .ag-row {
  border-right: 1px solid #ddd;
}

.borderRightCell {
  border-right: 1px solid #ddd;
}

.ag-header-cell.ag-header-cell-sortable.plannedTitle.ag-focus-managed {
  background: #f3f4f3;
  //border-bottom: 1px solid #b9b9b9 !important;
  color: #ef5e8c;
  padding: 0px 10px;
}

.headerHightDouble .ag-header-cell.ag-header-cell-sortable.ag-focus-managed,
.headerHightDouble .ag-header-group-cell.borderRightCell.ag-header-group-cell-with-group.ag-focus-managed {
  padding: 0px 10px !important;
}

.headerHightDouble .ag-header.ag-focus-managed.ag-pivot-off {
  height: 61px !important;
  min-height: 61px !important;
  line-height: 30px !important;
}

.headerHightDouble .ag-header-cell,
.headerHightDouble .ag-header-row.ag-header-row-column,
.headerHightDouble .ag-header-row.ag-header-row-column-group,
.headerHightDouble .ag-theme-alpine .ag-header-cell-resize {
  height: 30px !important;
  min-height: 30px !important;
  line-height: 30px !important;
  font-size: 12px !important;

}

.listingRecordMainDiv.manpowerManagementEmployeeListMainDiv .headerHightDouble .ag-theme-alpine .ag-ltr .ag-cell {
  font-size: 12px !important;
  padding: 0px 10px !important;
}

.headerHightDouble .ag-center-cols-viewport {
  transform: rotateX(180deg);
  overflow-x: auto;
  background-color: #caccce;
}

.headerHightDouble .ag-center-cols-container {
  transform: rotateX(180deg);
}

.headerHightDouble .ag-pinned-left-cols-container div.ag-row-level-0 {
  margin-top: 10px;
}

.headerHightDouble .ag-center-cols-viewport::before {
  content: '';
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 10px;
}

.ag-paging-row-summary-panel {
  display: none;
}

.mgSpace.disabledSlide {
  opacity: .5;
}

.headerHightDouble.bottomScroll .ag-center-cols-viewport {
  transform: rotateX(0deg);
  overflow-x: hidden;
  background-color: #caccce;
}

.headerHightDouble.bottomScroll .ag-center-cols-container {
  transform: rotateX(0deg);
}

.headerHightDouble.bottomScroll .ag-pinned-left-cols-container div.ag-row-level-0 {
  margin-top: 0px;
}

.headerHightDouble .ag-body-horizontal-scroll {
  position: absolute;
  top: 62px;
}

.ag-center-cols-clipper {
  overflow: hidden;
}

.headerHightDouble .ag-center-cols-viewport {
  margin-top: -10px;
}

.headerHightDouble .ag-horizontal-left-spacer {
  visibility: hidden;
}

.mgSpace.mgSpaceBreakTime {
  padding: 0px 10px;
}

.mgSpace.mgSpaceBreakTime .ant-slider-with-marks {
  margin-bottom: 5px;
}

.mgSpace.mgSpaceBreakTime .ant-slider-mark {
  font-size: 14px;
}

.mgSpace.mgSpaceBreakTime .ant-slider-with-marks {
  margin-bottom: 18px;
  margin-top: 6px;
}

.buttonFilterArea.buttonFilterArea2 .buttonChartFilter.button.btn {
  margin-left: 0px !important;
  margin-bottom: 0px;
  min-width: 100px;
}

.buttonFilterArea.buttonFilterArea2 {
  margin-bottom: 3px;
  border: 1px solid #ddd;
  padding: 7px 7px;
  border-radius: 8px;
}

.ratingFill.ratingAG svg {
  max-width: 16px;
}

.marginmore {
  margin-left: 0px;
  margin-right: 0px;
}

.marginmore .col h4.smallerFontTitle {
  font-size: 14px;
}

.marginmore .smallerFont {
  font-size: 12px;
  color: #695e5e;
}

.marginmore .col {
  background: #fff;
  margin-right: 10px;
  border-radius: 10px;
  vertical-align: middle;
  text-align: center;
  padding: 20px 10px;
}

.marginmore .col:last-child {
  margin-right: 0px;
}

.sublinetablist.sublinetablistTitle {
  position: relative;
  line-height: 1;
  top: -15px;
}

.sublinetablist.report-filter input {
  padding: 0px 10px;
  width: 100px !important;
}

.startDateEndDateReportBY .reportBtn {
  display: inline-block;
  float: left;
}

.startDateEndDateReportBY .reportBtn .button.button-primary {
  padding: 0px 10px;
  background: #ddd;
  border: 0px;
  border-radius: 4px;
  margin-right: 7px;
  line-height: 30px;
  font-size: 13px;
}

.startDateEndDateReportBY .reportBtn .button.button-primary.button-active {
  background: #ef5e8c;
  color: #fff;
}

.dateBox.minimalDiv .startDateEndDateMainDiv .react-datepicker-wrapper input {
  padding: 0px 10px;
  line-height: 28px;
  font-size: 12px;
  width: 100px !important;
}

.dateBox.minimalDiv.row h2 {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 0px;
}

.dateBox.minimalDiv.btBDr {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}

.chartMainDiv.chartMainDivProductivity.chartMainDivReport .chart .titleBox {
  padding: 7px 20px;
  background: #ecf2f9;
}

.legendBox.legendBoxReport.col h4 {
  font-size: 13px;
  padding: 5px 0px;
  margin-bottom: 3px;
  border-bottom: 1px solid #ddd;
}

.legendReportContainer .legendBox.legendBoxReport.col:last-child {
  margin-right: 0px;
}

.titleHeaderReport {
  font-size: 16px;
  padding-bottom: 13px;
  margin-bottom: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.legendBox.legendBoxReport.col {
  display: inline-block;
  // max-width: 31.33%;
  background: #f1f1f1;
  margin-right: 10px;
  padding-bottom: 5px;
  border-radius: 8px;
  vertical-align: top;
  min-height: 100px;
  text-align: left;
}

.legendListReport .col {
  max-width: 50%;
  display: inline-block;
  padding: 0px;
  font-size: 12px;
}

.legendListReport .ant-select.ant-select-single .ant-select-selection-item {
  padding-left: 15px;
  text-align: left;
}

.legendReportContainer {
  background: #ecf2f9;
  text-align: center;
  padding: 15px 9px;
  border-radius: 8px;
}

.legendBox.legendBoxReport.col {
  background: #f1f1f1;
  background: #fff;
  text-align: left;
}

.legendBox.legendBoxReport .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
  padding-left: 0 px;
  border-radius: 4px !important;
  border: 1px solid #ddd !important;
}

.loadCenter {
  text-align: center;
}

.legendReportContainer .legendBox.legendBoxReport.legendBoxReport2.col {
  min-height: 50px;
  padding: 12px 20px;
  line-height: 26px;
  font-size: 16px;
}

.legendReportContainer .legendBox.legendBoxReport.legendBoxReport2.col strong {
  background: #101535;
  text-align: right;
  float: right;
  color: #fff;
  padding: 4px 20px;
  border-radius: 4px;
  line-height: 20px;
  font-size: 14px;
}

.legendReportContainer .legendBox.legendBoxReport.legendBoxReport2.col {
  width: 23%;
}

.legendBox.legendBoxReport.legendBoxReportAuto.col {
  min-height: 40px;
  padding: 0px 10px;
  margin-right: 8px;
  line-height: 40px;
  font-size: 13px;
}


.legendBox.legendBoxReport.legendBoxReportAuto.col .ratingFill.ratingAG {
  display: inline-block;
  width: 80px;
}

.legendReportContainer .row {
  margin: 0px;
}

.legendBox.legendBoxReport.legendBoxReportAuto.col strong {
  text-align: right;
  width: auto;
  text-align: right;
  float: right;
  line-height: 18px;
  background: #101535;
  color: #fff;
  padding: 0px 8px;
  font-size: 12px;
  margin-top: 12px;
  border-radius: 4px;
}

.legendReportContainer.legendReportContainerSmallSpace {
  padding: 10px;
}

.dateBox.minimalDiv .startDateEndDateMainDiv {
  max-width: 113px;
  float: left;
}

.labelMergeInput .zIndiLabelNew {
  z-index: 9;
  position: absolute;
  font-size: 11px;
  background: #fff;
  left: 5px;
  top: -8px;
  padding: 0px 10px;
  color: #ef5e8c;
  font-weight: bold;
}

.labelMergeInput .form-control {
  height: 30px;
  max-width: 150px;
  float: right;
  font-size: 12px;
}

.labelMergeInput {
  position: relative;
  max-width: 150px;
  float: right;
}

.headerArea.legendBox.legendBoxReport.legendBoxReportAuto.col h6 {
  font-size: 14px;
  line-height: 36px;
  margin: 0px;
}

.headerArea.legendBox.legendBoxReport.legendBoxReportAuto.col .ant-select {
  float: right;
}

.legendReportContainer.legendReportContainerSmallSpace.legendReportContainerSmallSpace3 .ant-select {
  margin-top: 4px !important;
}

.legendReportContainer.legendReportContainerSmallSpace.legendReportContainerSmallSpace3 .ant-select-single .ant-select-selector .ant-select-selection-item {
  padding-left: 18px !important;
  font-size: 12px;
}

.headerArea.legendBox.legendBoxReport.legendBoxReportAuto.col .ant-select .ant-select-selector {
  font-size: 12px;
  padding: 0px 10px;
  border: 1px solid #ecf2f9 !important;
  margin: 3px;
  max-width: 138px;
  float: right;
}

.agTableStart .ag-header,
.agTableStart .ag-header * {
  line-height: 35px !important;
  height: 35px !important;
  max-height: 35px !important;
  min-height: 35px !important;
}

.agTableStart .ag-body-viewport .ag-row,
.agTableStart .ag-body-viewport .ag-row * {
  line-height: 30px;
  font-size: 12px;
}

.chartMainDiv.chartMainDivProductivity.chartMainDivReport .titleBox {
  font-size: 12px;
}

.legendReportContainer.legendReportContainerSmallSpace .legendBox.legendBoxReport.col h4 {
  font-size: 14px;
  margin: 10px 0px;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.legendReportContainer.legendReportContainerSmallSpace .col {
  font-size: 14px;
  padding: 6px 14px 10px 12px;
  border-bottom: 2px dotted #ddd;
}

.headerArea.legendBox.legendBoxReport.legendBoxReportAuto.col,
.headerArea.legendBox.legendBoxReport.legendBoxReportAuto.col .col {
  border: 0px;
}

.legendReportContainer.legendReportContainerSmallSpace .legendListReport:last-child .col {
  border-bottom: 0px;
}

.titleHeaderReport.titleHeaderReportAvg.col-lg-6 {
  line-height: 14px;
  font-size: 14px;
  text-align: right;
}

.titleHeaderReport.titleHeaderReportAvg.col-lg-6 strong {
  background: #55597c;
  color: #fff;
  padding: 0px 10px;
  border-radius: 4px;
  font-weight: 500;
}

// .averageStyle {
//   width: 300px;
//   float: right;
//   text-align: left;
// }
.titleHeaderReport.titleHeaderReportAvg.col-lg-6 strong.normalStyleStrong {
  background: transparent;
  color: #000000d9;
  font-weight: 500;
}

// .titleHeaderReport.titleHeaderReportAvg.p-l-0.col-lg-6 div {
//   line-height: 12px;
//   font-size: 14px;
// }
// .averageStyle {
//   margin-top: -10px;
// }

.titleHeaderReport.titleHeaderReportAvg.col-lg-6 div {
  display: inline-block;
}

.button.button-primary.button-active.btnpdfgenerate {
  padding: 0px 10px;
  background: #ef5e8c;
  border: 0px;
  border-radius: 4px;
  margin-right: 7px;
  line-height: 25px;
  font-size: 13px;
  display: inline-block;
  margin-left: 5px;
  color: #fff;
}

.reportChild.hideAll {
  display: none;
}

.legendBox.legendBoxReport.legendBoxReportAuto.noBorderBtm.col {
  border: 0px;
}

.startDateEndDateReportBY .reportBtn .button.button-primary.button-active img {
  max-width: 16px;
  margin-right: 2px;
  margin-top: -4px;

}

.legendBox.legendBoxReport.legendBoxReportAuto.col .relative .helpicon {
  max-width: 16px;
}

.legendBox.legendBoxReport.legendBoxReportAuto.col .relative {
  display: inline-block;
  margin-left: 10px;
}

.selectStyle2 {
  margin-right: 10px;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  height: 30px;
  overflow-y: scroll;
  float: left;
}

.selectStyle2 .ant-select.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content {
  padding-right: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}

.selectStyle2 .ant-select-multiple .ant-select-selection-item-remove {
  position: absolute;
  right: 0;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 7px;
  line-height: 10px;
  background: #ef5e8c;
  color: #fff;
  padding: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.reportChart .accordionHeader {

  margin-bottom: 10px;

}

//here print start
@media print {
  @page :footer {
    display: none
  }

  @page :header {
    display: none
  }
}

@media print {
  .leftSideBarDiv {
    display: none;
  }

  .withLoginRightSideDiv {
    margin-left: 0px !important;
    width: 100%;
  }

  .manpowerManagementMainDiv {
    background: #ecf2f9;
  }

  .reportChild.hideAll {
    display: block !important;
  }

  @page {
    margin: 0px 0px;
    padding: 90px 0px;
  }

  body {
    margin: 60px 0px;
  }

  * {
    background: #fff !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact !important;
  }

  .manpowerManagementEmployeeListMainDiv.m-t {
    background: #fff;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact !important;
  }

  #header,
  #footer,
  #nav {
    display: none !important;
    color: #fff !important
  }

  @page :footer {
    color: #fff !important;
    height: 1px;
  }

  @page :header {
    color: #fff;
    color: #fff !important;
    height: 1px;
  }

  .hideInPdf {
    display: none;
  }

  .reportChild {
    break-inside: avoid;
  }

  .invoiceTable {
    font-size: 16px;
  }

  .titleHeaderReport {
    font-size: 16px;
    border-bottom: 0px;
    margin: 0px;
    padding: 10px 20px;
  }

  .manpowerManagementEmployeeListMainDiv.plannedWorkForce {
    background-color: #ddd;
  }

  .titleHeaderReport.titleHeaderReportAvg.col-lg-6 strong {
    background: #55597c !important;
    color: #fff;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact !important;
  }

  .manpowerManagementEmployeeListMainDiv.plannedWorkForce div {
    position: relative;
    vertical-align: middle;
    margin: 0px;
    padding: 0px;
  }

  .manpowerManagementEmployeeListMainDiv.plannedWorkForce div.col-lg-6 {
    width: 50%;
    float: left;
    flex: unset;
    display: block;
  }

  .titleHeaderReport.titleHeaderReportAvg.col-lg-6 strong.normalStyleStrong {
    background: transparent important;
    color: #000000d9 important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    font-weight: 500;
  }

  .bdrHeader {
    position: relative;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }

  .manpowerManagementEmployeeListMainDiv.plannedWorkForce div.averageStyle {
    padding: 10px 20px;
  }

  .manpowerManagementEmployeeListMainDiv.plannedWorkForce div.bdrHeader.row {
    margin-bottom: 10px;
  }

  .chartMainDiv.chartMainDivProductivity.chartMainDivReport .chart .titleBox {

    background: #ecf2f9 !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }

  .chartMainDiv.chartMainDivProductivity.chartMainDivReport .chart .titleBox div,
  .chartMainDiv.chartMainDivProductivity.chartMainDivReport .chart .titleBox strong,
  .chartMainDiv.chartMainDivProductivity.chartMainDivReport .chart .titleBox span {
    background: #ecf2f9 !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }

  .legendReportContainer {
    background: #ecf2f9 !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }

  .legendBox.legendBoxReport.legendBoxReportAuto.col strong {
    background: #101535 !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;

  }

  .noBorderBtm {
    border: 0px !important;
  }

  table {
    page-break-inside: auto;
    width: 100%
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto
  }

  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-footer-group
  }

  table.withBorder {
    width: 100%;
    border: 1px solid #ddd;
  }

  table th {
    padding: 10px 20px;
    font-weight: normal;
    background: #ecf2f9 !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    border-right: 1px solid #ddd;
  }

  table tr th:last-child {
    border-right: 0px;
  }

  table tbody tr td {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  table tbody tr td:last-child {
    border-right: 0px;
  }

  .ratingFill.ratingAG {
    width: 80px;
  }

  table tbody tr:nth-child(2n) {
    background: #f5f5f5;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }

  table tbody tr:nth-child(2n) td {
    background: #f5f5f5;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }

  a[href]:after {
    content: none !important;
  }
}



.dividerSection {
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}

.customAccord2 .ant-collapse-header .anticon.anticon-right.ant-collapse-arrow {
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 13px;
}

.titleComp {
  display: block;
}

.titleCompSmaller {
  font-size: 12px;
  margin-right: 10px;
}

.ant-collapse.ant-collapse-icon-position-left.customAccord.customAccord2 .col {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: left;
  align-items: baseline;
  display: flex;
}

.titleCompSmaller span {
  font-size: 14px;
}

.redBgPerform {
  height: 100%;
  display: block;
  background: #f0a9a9;
  color: #881c21;
}

.redHeader.headerSmallSize {
  font-size: 13px;
}

.greenHeader.headerSmallSize {
  font-size: 13px;
}

.ag-row-odd.ag-row-no-focus.ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-last.ag-after-created {
  border: 0px;
}

.redHeader.headerSmallSize,
.greenHeader.headerSmallSize,
.disabledCell.headerSmallSize {
  font-size: 12px;
  background: transparent !important;
}

.ag-theme-alpine .ag-row .ag-cell:first-child div.redHeader.headerSmallSize,
.ag-theme-alpine .ag-row .ag-cell:first-child div.greenHeader.headerSmallSize,
.ag-theme-alpine .ag-row .ag-cell:first-child div.disabledCell.headerSmallSize {
  font-size: 12px;
  background: transparent !important;
}

.WholeTableFLow {
  width: 100%;
  overflow-x: scroll;
}

.holiday-7 .sc-htpNat button:first-child {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}

.holiday-6 .sc-htpNat button:nth-of-type(2) {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}

.holiday-5 .sc-htpNat button:nth-of-type(3) {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}

.holiday-4 .sc-htpNat button:nth-of-type(4) {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}

.holiday-3 .sc-htpNat button:nth-of-type(5) {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}

.holiday-2 .sc-htpNat button:nth-of-type(6) {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}

.holiday-1 .sc-htpNat button:nth-of-type(7) {
  opacity: .5;
  cursor: not-allowed;
  pointer-events: none;
}

.costBodayDept {
  width: 100% !important;
}

.customAccord2.customAccord3 .ant-collapse-header .anticon.anticon-right.ant-collapse-arrow {
  top: 12px;
}

.subLineTab .btnExport.sublinetablist {
  float: right
}

.btnExport.sublinetablist button {
  background: transparent;
  border: 1px solid #ddd;
  padding: 0px 8px;
  font-size: 13px;
  border-radius: 6px;
  top: -6px;
  position: relative;
  line-height: 30px;
  border-radius: 6px;
  border: 1px solid rgba(54, 65, 65, 0.50);
  color: #364141;
}

.modalShareFooter {
  padding-top: 15px;
  border-top: 1px solid #ddd;
  margin-top: 20px;
  text-align: right;
}

.ant-select.form-control.form-control-my .ant-select-selection-item {
  padding-left: 10px !important;
}

body iframe.iframeEmail {
  display: block !important;
}

.form-control.error {
  border-color: #ae2929;
}

.modalShareHeader {
  margin-bottom: 10px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ddd;
}

.report-filter.report-filter2 .ant-slider-rail {
  background-color: #d8d3d3;
  transition: background-color 0.3s;
}

.middleAreaStats {
  padding: 15px 10px;
}

.someStyleHeadline {
  vertical-align: middle;
}

.middleAreaStats h4 {
  font-size: 15px;
}



.middleAreaStats h2 {
  color: #ef5e8c;
  font-size: 90px;
  margin-bottom: 0px;
}

.ant-tabs-tab .ant-tabs-tab-btn:hover {
  color: #ef5e8c !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ef5e8c;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-ink-bar {
  background: #ef5e8c;
}

.middleHeader {
  display: table;
  width: 100%;
  background: #eeebeb;
  padding: 7px 10px;
  border: 1px solid #ddd;
  border-bottom: 0;
  text-align: left;
}

.middleBodyArea .middleLegend .middleLegendList div {
  display: table-cell;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
}

.middleLegendList div:first-child {
  min-width: 90%;
  width: 90%;
}

.middleLegendList div:last-child {
  min-width: 10%;
  width: 10%;
}

.middleBodyArea .middleLegend .middleLegendList span.bgLegend {
  width: 6px;
  height: 6px;
  vertical-align: middle;
  max-width: 15px;
  max-height: 10px;
  border-radius: 50%;
  margin-right: 2%;
  display: inline-block;
  margin-left: 5px;
}

.middleFooterRight strong,
.middleHeaderRight strong {
  color: #ef5e8c;
}


.middleHeaderRight {
  height: 100%;
  vertical-align: middle;
  width: 15%;
  display: table-cell;
}

.middleFooter .middleFooterLeft {
  display: table-cell;
  width: 85%;
  font-weight: 700;
}

.middleFooterRight {
  display: table-cell;
  width: 10%;
}

.middleHeader .middleHeaderLeft {
  width: 85%;
  display: table-cell;
  font-size: 13px;
  padding-left: 5px;
  font-weight: 700;
}

.middleLegendList {
  display: table;
  width: 100%;
  vertical-align: middle;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px solid #ddd;
}

.middleFooter {
  display: table;
  width: 100%;
  padding: 5px 15px;
  font-size: 13px;
  background: #eeebeb;
  border: 1px solid #ddd;
  border-top: 0;
  text-align: left;
}

bod .middleAreaStats {
  padding: 7px 10px;
  border: 1px solid #ddd;
}

.highcharts-credits {
  display: none;

}

.ant-collapse-tbody td {
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  font-size: 12px;
}

.ant-collapse-content table {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.ant-collapse-tbody tr:nth-child(2n) {
  background: #fff;
}

.disabledRow.row {
  cursor: not-allowed;
  opacity: .4;
  border: 1px solid #ddd;
  padding: 20px;
  background: #f5f5f5de;
}

.customTableSource th,
.customTableSource td {
  padding: 3px 10px;
  border: 1px solid #e6e6e6;
  font-size: 10px;
  font-weight: normal;
}

.customTableSource {
  border: 1px solid #ddd;
}

.customTableSource tbody td:first-child {
  font-size: 10px;
}

.middleAreaStats {
  padding: 2px 10px;
  border: 1px solid #ddd;
}

.coveringspace {
  margin: -7px;
}

.buttonFilterArea.buttonFilterArea2.buttonFilterArea3 .buttonChartFilter.button.btn {
  font-size: 14px !important;
}

.fncBtn .btn.btn-submit {
  background: #ddd;
  padding: 2px 15px;
  font-size: 15px;
}

.fncBtn .btn.btn-submit.btn-active {
  background: #ef5e8c;
  color: #fff;
}

.bgNameTooltip {
  width: 7px;
  height: 7px;
  display: inline-block;
  margin-right: 6px;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -3px;
}

.textCap.textCapNoDot {
  font-weight: 600 !important;
}

.textCap.textCapNoDot::before {
  display: none !important;
}

.textCap.textCapOrange {
  color: #dca13a;
  font-weight: 600;
}

.ant-select.withBorder.ant-select-single {
  border: 1px solid #ddd;
  padding: 0px 10px;
  margin-bottom: 10px;
  border-radius: 7px;
}

.keyAGTableSmallPlan.keyAGTableSmallPlanHeader .ag-header-cell-text {
  font-size: 12px;
  line-height: 1;
}

.keyAGTableSmallPlan.keyAGTableSmallPlanHeader .ag-header-cell.ag-header-cell-sortable.ag-focus-managed {
  padding: 0px 10px;
}

.keyAGTableSmallPlan.keyAGTableSmallPlanHeader .ag-cell-value.ag-cell {
  font-size: 13px;
}

.newTripple .ant-collapse-item {
  margin-top: 15px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
}

.middleHeader2 {
  border: 1px solid #dddd;
  font-size: 12px;
  padding: 5px 0px;
  margin: 0;
  font-weight: bold;
  background: #e9e9e9;
}

.middleAreaStats2 {
  border: 1px solid #ddd;
}

.middleAreaStats2 .row {
  padding: 5px 0px;
  margin: 0px auto;
  font-size: 12px;
}

.middleHeader2 div,
.middleAreaStats2 .row div {
  padding: 0px 5px;
}

.middleHeader2 div:first-child,
.middleAreaStats2 .row div:first-child {
  padding-left: 10px;
}

.middleAreaStats2 .row {
  padding: 5px 0px;
  margin: 0px auto;
  font-size: 12px;
  border-bottom: 1px solid #ddd;
}

.middleBodyArea2 .middleLegend2 .middleLegendList2:nth-child(2n) .row {
  background: #f4f4f4;
}

.recommendBox {
  padding: 10px;
  min-height: 100px;
  float: right;
  max-width: 100%;
  width: 100%;
  margin-bottom: 15px;
}

.recommendBox h4 {
  font-size: 12px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.rightTitle {
  max-width: 30%;
  float: right;
}

.leftTitle {
  max-width: 70%;
  float: left;
}

.rightTitle .buttonFilterArea .buttonChartFilter {
  margin: 0px;
  border-radius: 10px 0px 0px 10px !important;
  min-width: 70px;
}

.rightTitle .buttonFilterArea .buttonChartFilter:focus {
  box-shadow: unset !important;
}

.rightTitle .buttonFilterArea .buttonChartFilter:last-child {
  border-radius: 0px 10px 10px 0px !important;
}

.ant-tabs-tab:hover {
  color: #ef5e8c !important;
}

.someStyleHeadline .keyAGTableSmallPlan * {
  font-size: 12px;
}

.reloadRecommend {
  float: right;
  line-height: 17px;
  font-size: 10px;
  padding: 0px 5px;
  background: #fff;
  color: #ef5e8c;
  margin-top: -6px;
  border: 1px solid #ef5e8c;
  cursor: pointer;
}

.reloadRecommend:hover {
  background: #ef5e8c;
  color: #fff;
}

.recommentContent ul {
  padding-left: 5px;
  font-size: 12px;
}

.recommentContent ul li li {
  border: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.recommentContent ul li:empty {
  display: none;
}

.recommendationEye {
  float: right;
  background: #ef5e8c;
  width: 30px;
  height: 19px;
  margin-top: -6px;
  margin-left: 5px;
  text-align: center;
  padding: 2px;
  cursor: pointer;
}

.recommendationEye svg {
  fill: #fff;
}

.recommendationEye svg {
  fill: #fff;
  max-width: 12px;
}

.ant-modal-body ul li:first-child {
  padding-top: 0px !important;
}

.ant-modal-body ul li {
  list-style: none;
  padding-left: 0px !important;
  padding: 10px 0px;
  padding-left: 0px;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.ant-modal-body ul {
  padding-left: 0px;
}

.ant-modal-body ul li:empty {
  display: none;
}

.recommentContent ul li {
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #dddd;
  line-height: 16px;
  width: 100%;
  float: left;
  padding-left: 0px !important;
}

.recommentContent ul li:last-child {
  border-bottom: 0px;
}

.ant-modal-body ul li:last-child {
  border-bottom: 0px !important;
}

.recommentContent ul li span:last-child {
  float: right;
  width: calc(100% - 30px);
}

.recommentContent ul li span:first-child {
  width: 20px;
  height: 20px;
  background: #ef5e8c;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  float: left;
}

.ant-modal-body ul li span:first-child {
  width: 30px;
  height: 30px;
  background: #ef5e8c;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  float: left;
  line-height: 30px;
}

.ant-modal-body ul li {
  float: left;
  width: 100%;
}

.ant-modal-body {

  overflow: auto;
}

.ant-modal-body ul li span:last-child {
  width: calc(100% - 40px);
  float: right;
}

.sucessColor {
  color: green;
}

.redColor {
  color: red;
}

.normalGrey {
  color: #7e7878;
}

.report-filter .startDateEndDateMainDiv {
  z-index: 9999;
}

.tableStyle2 tr td {
  font-size: 12px;
  text-align: left;
}

.tableStyle2 tr td {
  font-size: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding: 10px 10px;
}

.tableStyle2 thead {
  display: table-header-group;
}

.tableStyle2 tbody {
  display: table-row-group;
  width: 100%;
}