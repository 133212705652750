.loginComponentMainDiv {
    height: 100vh;
    position: relative;

    .firstRowDiv {
        height: 68vh;
        background: #F8F4DA;
    }

    .secondRowDiv {
        height: 32vh;
        background: #9EC7A9;
    }

    .loginFormWithLogoDiv {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin: 20px 10px;

        @media screen and (min-width: 1200px) {
            min-width: 1020px;
        }

        .logoDiv {
            text-align: center;
        }

        .loginFormMainDiv {
            margin: 2rem 0;
            background: #FFFFFF;
            border-radius: 0.5rem;
            padding: 2.5rem;

            .loginText {
                color: #394584;
                font-size: 1.65rem;
                font-weight: 600;
            }

            .loginForm {
                .eachElement {
                    margin: 1rem 0;
                    position: relative;

                    img{
                        position: absolute;
                        top: 2.5rem;
                        cursor: pointer;
                        right: 0.5rem;
                        width: 25px;
                    }

                    label {
                        color: #394584;
                        display: block;
                        font-size: 1rem;
                    }

                    input {
                        display: block;
                        width: 100%;
                        height: calc(1.5em + 1.25rem + 2px);
                        padding: 0.625rem 0.75rem;
                        font-size: 0.875rem;
                        font-weight: 400;
                        line-height: 1.5;
                        color: #8898aa;
                        background-color: #fff;
                        border: 1px solid #cad1d7;
                        border-radius: 0.375rem;
                        box-shadow: none;
                        outline: none;
                        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);

                        &:focus {
                            outline: none;
                            color: #8898aa;
                            background-color: #fff;
                            border: 1px solid #cad1d7;
                        }
                    }
                }
            }

            .forgetPasswordText{
                color: #394584;
                font-size: 1rem;
                cursor: pointer;

                &:hover{
                    text-decoration: underline
                }
            }

            .loginFormButton{
                margin: 2rem auto 1rem;
                display: block;
                padding: 0.75rem;
                border: none;
                outline: none;
                border-radius: 0.35rem;
                text-align: center;
                background: #0E4ED3;
                min-width: 80%;
                color: #FFFFFF;
            }
        }
    }
}
