    @media only screen and (max-width: 1450px) {

      .siteViewRightSideDiv .card-box-align.col-lg-4 {
        padding-left: 0px;
      }

      .siteViewRightSideDiv .card-box-align.col-lg-4:first-child {
        padding-left: 15px;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .headerNameDiv {
        font-size: 1.5rem;
      }

      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div .recommendListMainDiv .eachRecommendCardDiv div {
        font-size: 12px !important;
        line-height: 15px;
      }

      .left-no-space-resp {
        padding-left: 0px;
      }

      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div {
        font-size: 18px !important;
      }

      .chartTitle {
        font-size: 1rem !important;
      }

      .white-bg.m-t.wrapper.areaIndexChartMainDiv {
        padding: 15px;
      }

      .eachPeakHoursDiv {
        padding: 0.2rem 0;
        font-size: 12px;
      }

      .areaIndexChartMainDiv h6.font-bold {
        font-size: 13px;
      }

      .areaIndexChartMainDiv .col-lg-9 {
        padding-left: 0px;
      }

      .barChartMainDiv .chart .titleBox .title,
      .manpowerManagementMainDiv .chartMainDiv .chart .titleBox .title {

        font-size: .9rem !important
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv {
        font-size: .9rem !important
      }

      .viewAllEmployeesButton {
        font-size: 0.875rem;
      }

      .employeeDetailsMainDiv .m-t.row .col-lg-8 {
        padding-left: 0px;
      }

      .employeeDetailsMainDiv .m-t.row .col-lg-8 {
        padding-left: 0px;
      }

      .employeeDetailsMainDiv .indexMainDiv .col-lg-3 {
        padding-right: 0px;
      }

      .employeeDetailsMainDiv .indexMainDiv .col-lg-3:last-child {
        padding-right: 15px;
      }

      .eachIndexDiv h5.font-bold {
        font-size: 13px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .indexMainDiv .eachIndexDiv .riskPercentageMainDiv .increaseDecreasePercentageDiv {
        padding: 0.05rem 0.4rem;
        font-size: 11px;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv span {
        font-size: 12px;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .col-lg-10 {
        padding-right: 0px;
        padding-left: 5px;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .text-right.col-lg-2 {
        float: right;
        padding-right: 10px;
        padding-left: 0px;
      }

      .employeeDetailsMainDiv .indexMainDiv .font-normal {
        font-size: 13px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv {
        padding: 1rem;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .col-lg-6 {
        padding-right: 0px !important;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .tableBox th {
        padding: 0.5rem;
        font-size: 14px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv.inflex-some-space .infectionFlex .col-lg-7 {
        padding: 0px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv.inflex-some-space .infectionFlex .col-lg-3 {
        padding-right: 0px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv.inflex-some-space .infectionFlex .col-lg-5 {
        padding-right: 0px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv.inflex-some-space .history-footer .infectionFlex .col-lg-6 {
        padding-left: 0px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv.inflex-some-space .history-footer .infectionFlex .col-lg-6:last-child {
        padding-right: 0px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex h5 {
        margin-bottom: 5px !important;
        font-size: 15px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex .indictaor-button-covid {
        margin-bottom: 5px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .batteryMainDiv {
        padding: 0.75rem .7rem;
        white-space: nowrap;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex .col-lg-7 {
        padding-left: 0px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex .col-lg-5 {
        padding-right: 0px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .batteryMainDiv {
        padding: 0.75rem .6rem;
        font-size: 0.8rem;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .batteryMainDiv img {
        max-width: 10px;
        margin-right: -2px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv {
        padding: 1rem;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .col-lg-8 .col-lg-3 {
        width: 160px;
        max-width: 200px;
        flex: 0 0 auto;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .indexMainDiv .eachIndexDiv .riskPercentageMainDiv .increaseDecreasePercentageDiv {
        padding: 0.05rem 0.3rem;
      }

      .commonLangaugeStyleDiv {
        width: 30%;
      }

      .siteHeadingDatePickerDiv .react-datepicker-wrapper {
        width: 70% !important;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .col-lg-7,
      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .col-lg-5 {
        flex: 0 0 auto;
        max-width: 50%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv.mostInteractedMainDiv2 .col-lg-6 {
        padding-right: 20px !important;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv.mostInteractedMainDiv2 .col-lg-6:last-child {
        padding-right: 0px !important;
      }

      .white-bg.wrapper.policy-management-date.areaIndexChartMainDiv .col-lg-8 {
        padding-left: 15px;
      }

      /* after change from 1400 to 1450px */
      .leftSideBarDiv .adminName {
        font-size: 18px !important;
        margin-top: 1.5rem;
      }

      body .leftSideBarDiv .designation {
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 15px;
      }

      body .dashboardComponentMainDiv .threatWatchMainDiv .threatWatchTextDiv .title {
        font-size: 1.2rem !important;
      }

      body .dashboardComponentMainDiv .threatWatchMainDiv .threatWatchTextDiv .workSpaceStripeDiv {
        width: 230px;
        font-size: 11px;
      }

      body .threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList {
        width: 32%;
        margin-right: 5px !important;
        padding: 10px;
      }

      body .threeList .newHeadline {
        font-size: 24px;
      }

      body .threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList {
        width: 31%;
      }

      body .livearea {
        line-height: 1.4;
        font-size: 12px;
      }

      body .threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 strong.skeleton-text {
        font-size: 24px;
      }

      body .threatWatchAnalyticsMainDiv {
        height: 127px;
      }

      body .threeList .skeleton-text .iconShape div.downicon {
        height: 24px;
        bottom: -px;
      }

      body .threeList h4 {
        font-size: 14px;
      }

      body .threeList .skeleton-text .iconShape svg {
        width: 10px;
      }

      body .threeList .skeleton-text .iconShape {
        height: 20px;
      }
    }


    @media only screen and (max-width: 1280px) {
      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv {
        padding: 15px;
      }



      .threeList .skeleton-text .iconShape div.downicon {
        top: 0px;
        height: 0px;
        bottom: -40px;
      }

      .dashboardComponentMainDiv .threatWatchMainDiv.whitebgShadow .threatWatchTextDiv .workSpaceStripeDiv {
        line-height: 1;
        height: 50px;
        padding: 10px 0px 0px 32px;
      }

      .fullSmallDesktop {
        flex: 0 0 100%;
        max-width: 100%;
      }

      /* .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div {
          font-size: 10px !important;
          line-height: 15px;
        } */
      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div .recommendListMainDiv .eachRecommendCardDiv div {
        font-size: 12px !important;
        line-height: 15px;
        display: table;
        width: 100%;
      }

      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div .recommendListMainDiv .eachRecommendCardDiv div.col-lg-8 {
        max-width: 60%;
        width: 60%;
        display: table-cell;
        vertical-align: middle;
        padding-right: 0px;
      }

      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div .recommendListMainDiv .eachRecommendCardDiv div.col-lg-4 {
        max-width: 40%;
        width: 40%;
        display: table-cell;
        vertical-align: middle;
        padding-right: 0px;
      }

      body .threatWatchMainDiv .col-lg-3 {
        width: 100% !important;
        max-width: 100%;
        flex: 0 0 100%;
      }

      .threatWatchMainDiv .col-lg-3 .dashboardPeopleImageMaiDiv {
        display: none;
      }


      .parent-lg-6.col-lg-6 {
        flex: 0 0 65%;
        max-width: 65%;
      }

      .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .populationRiskMainDiv {
        width: 23.333% !important;
        float: left;
        margin-right: 1% !important;
        height: 90px;
      }

      body .workSpaceStripeDiv {
        position: absolute !important;
        right: 15px;
        top: -25px;
      }

      .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .populationRiskMainDiv:last-child {
        margin-right: 0% !important;
        width: 24.333% !important;
      }

      .parent-lg-6.halftablet.col-lg-5 {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .col-lg-4 {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .halftablet.col-lg-4 {
        max-width: 48% !important;
        flex: 0 0 48% !important;
        padding-left: 0px;
      }

      body .threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList {
        margin-right: 8px !important;
      }

      body .threatWatchAnalyticsMainDiv {
        height: 120px;
      }

      body .commonHeadingDateMainDiv {
        width: 20%;
      }

      body .dashboardComponentMainDiv .dashboardPeopleAndDateMainDiv {
        margin-top: 1rem;
      }

      body .siteHeadingDatePickerDiv {
        width: 40% !important;
      }

      .tabletfull {
        flex: 0 0 100%;
        max-width: 100%;
      }

      body .threatWatchMainDiv.whitebgShadow .col-lg-3 {
        max-width: 100%;
      }

      .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .col-lg-8 {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }

      .report-filter.col-lg-1 {
        width: 15%;
        max-width: 15%;
        flex: 0 0 15% !important;
      }

      .filterField .col-lg-2 {
        max-width: 30%;
        flex: 0 0 30%;
      }

      .headerDevice h3 {
        font-size: 12px;

      }

      .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .m-l-lg {
        margin-left: 0px;
      }

      .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .align-right.ct-data.col-lg-4 {
        flex: 0 0 50%;
      }

      .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .col-lg-2 {
        display: none;
      }

      .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .utilityPadding {
        width: 31.333%;
        margin-right: 2%;
      }

      .tab-view .tab.tab-activity {
        font-size: 12px;
        padding: 6px;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .col-lg-7,
      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .col-lg-5 {
        flex: 0 0 auto;
        max-width: 100%;
        padding: 0px 10px;
      }

      .eachRecommendCardDiv .icon-click {
        text-align: left;
      }

      .eachRecommendCardDiv h4.fnt-20 {
        font-size: 16px;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .cols-2-list.cols.col-lg-7 {
        flex: 0 0 50%;
      }

      .eachRecommendCardDiv .last-updated-on.row .cols-1-list,
      .eachRecommendCardDiv .last-updated-on.row .cols-3-list {
        font-size: 12px;
        line-height: 16px;
        padding: 0px 10px;
      }

      .eachRecommendCardDiv .bg-highlight {
        height: 16px;
        width: 16px;

      }

      .eachRecommendCardDiv .icon-click div {
        width: 22px;
        height: 22px;
      }

      .eachRecommendCardDiv .icon-click img {
        max-width: 13px;
      }

      .eachRecommendCardDivOverflow.eachRecommendCardDiv {
        max-width: 100%;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv {
        font-size: .8rem !important;
      }

      .empTeamTabMainDiv .eachTab {
        width: 100px;
      }

      .h6,
      h6 {
        font-size: .8rem;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .headerNameDiv {
        font-size: 1.2rem;
      }

      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div {
        white-space: normal;
        word-wrap: break-word;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .col-lg-8 {
        padding-right: 0;
        max-width: 50%;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .col-lg-4 {
        padding-right: 0;
        max-width: 50%;
      }
    }

    @media only screen and (max-width:1023px) {
      .responsiveSpace {
        margin-top: 20px;
      }

      body .threeList .newHeadline small {

        font-size: 10px;
        margin-top: 5px;
      }

      body .threeList h4 {
        font-size: 12px;
      }

      .left-no-space-resp {
        padding-left: 15px;
      }

      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div .recommendListMainDiv {
        font-size: 12px !important;
      }

      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div .col-lg-8 {
        width: 60%;
      }

      .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div .col-lg-4 {
        width: 40%;
      }

      .manpowerManagementEmployeeListProductivity .col-lg-8 {
        width: 60%;
      }

      .manpowerManagementEmployeeListProductivity .col-lg-4 {
        width: 40%;
      }

      .manpowerManagementEmployeeListProductivity .col-lg-8 .col-lg-6 {
        width: 50%;
      }

      .areaCapacityMainDiv .col-lg-4 {
        width: 33.333%;
      }

      .withLoginRightSideDiv {
        margin-left: 0px;
        width: 100%;
      }

      .header-resp {
        position: fixed;
        background-color: #fff;
        padding: 15px 0px;
        left: 0;
        right: 0;
        z-index: 99;
        border-bottom: 1px solid #ddd;
      }

      .header-resp .logo {
        float: left;
        max-width: 190px;
        max-height: 48px;
      }

      .dashboardComponentMainDiv .dashboardPeopleAndDateMainDiv {
        margin-top: 10px;
      }

      .manpowerManagementMainDiv.siteViewMainDiv .col-lg-7 {
        width: 70%;
      }

      .manpowerManagementMainDiv.siteViewMainDiv .col-lg-5 {
        width: 30%;
      }

      .menu-area .menu-anime {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 20px;
        height: 1px;
        background: #000;
        margin: auto;
      }

      .leftSideBarDiv {
        left: -250px;
        width: 250px;
        max-width: 100vw;
        z-index: 999;
        transition: left ease-in .3s;
      }

      .leftSideBarDiv.activeMenu {
        left: 0px;
      }

      .RightSideDiv.withLoginRightSideDiv {
        margin-top: 75px;
      }

      .leftSideBarDiv .adminName {
        margin-top: 1rem;
        font-size: 20px !important;
      }

      .leftSideBarDiv .designation {
        text-align: center;
        margin-top: .5rem;
        margin-bottom: 0rem;
        font-size: 18px;
      }

      .menu-area {
        float: right;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        position: relative;
        cursor: pointer;
      }

      .menu-area .menu-anime::before {
        content: '';
        position: absolute;
        top: -10px;
        width: 30px;
        height: 2px;
        background-color: #000;
      }

      .menu-area .menu-anime::after {
        content: '';
        width: 30px;
        height: 2px;
        background: #000;
        bottom: -10px;
        position: absolute;
      }

      .dashboardComponentMainDiv .dashboardPeopleAndDateMainDiv .dashboardPeopleAndEmployeeMainDiv .col-lg-6 {
        float: left;
        max-width: 50%;
      }

      .threatWatchMainDiv .col-lg-3 {
        width: 35% !important;
        max-width: 35%;
      }

      .contactRankStartDateEndDateMainDiv .col-lg-6:last-child {
        padding-left: 15px !important;
      }

      .start-date-threat.col-lg-6,
      .end-date-threat.col-lg-6 {
        max-width: 50%;
      }

      .start-date-threat.col-lg-6 .startDateEndDateMainDiv {
        padding-right: 0px !important;
      }

      .threatWatchAnalyticsMainDiv {
        height: 105px !important;
      }

      .threatWatchAnalyticsMainDiv .contactRankStartDateEndDateMainDiv .end-date-threat .contactRankText,
      .threatWatchAnalyticsMainDiv .contactRankStartDateEndDateMainDiv .end-date-threat .startDateEndDateMainDiv {
        padding: 0px;
      }

      body .threeList .skeleton-text .iconShape div.downicon {
        height: 0px;
      }


      .dashboardComponentMainDiv .text-right.col-lg-9 {
        max-width: 75%;
      }

      .dashboardComponentMainDiv .m-t-sm.col-lg-3 {
        max-width: 25%;
        float: left;
      }

      .commonHeadingDateMainDiv {
        width: 18%;
      }

      .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .m-l-lg .col-lg-3 {
        max-width: 25%;
      }

      .header-breadcrumb.container .col-lg-6 {
        max-width: 50%;
      }

      .siteHeadingDatePickerDiv {
        width: 40% !important;
      }

      .siteManagementChartMainDiv .dateBox .col-lg-2,
      .manpowerManagementEmployeeListMainDiv .dateBox .col-lg-2 {
        max-width: 30%;
      }

      .left-no-space-resp {
        padding-left: 15px;
        margin-top: 15px;
      }

      .card-box-align.col-lg-4 {
        max-width: 33.333%;
      }

      .resp-team-box.col-lg-4 {
        max-width: 50%;
        margin-bottom: 10px;
      }

      .align-search-btn.col-lg-7 {
        max-width: 90%;
      }

      .align-search-btn-search {
        max-width: 10%;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv.eachRecommendCardDivOverflow {
        max-width: 48%;
        float: left;
        width: 48%;
        margin-right: 4%;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv.eachRecommendCardDivOverflow:nth-child(2n+1) {
        margin-right: 0px;
      }

      .align-breadcrumb-right {
        max-width: 50%;
      }

      .align-breadcrumb-left {
        max-width: 50%;
      }

      .header-breadcrumb.container .row {
        z-index: 99 !important;
      }

      .employeeDetailsMainDiv .m-t.row .col-lg-8 {
        padding-left: 15px;
        padding-top: 15px;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .col-lg-4 {
        padding-right: 15px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex.row .col-lg-8 {
        max-width: 70%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex .col-lg-7 {
        max-width: 70%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex .col-lg-5 {
        padding-right: 15px;
        max-width: 30%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex.row .col-lg-4 {
        max-width: 30%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex .text-right.col-lg-3 {
        max-width: 10%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex .col-lg-2 {
        max-width: 10%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv.inflex-some-space.m-t {
        max-width: 100%;
        width: 100%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv {
        max-width: 100%;
        float: left;
        width: 100%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv:nth-child(2n) {
        margin-right: 0%;
      }

      .dashboardPeopleAndEmployeeMainDiv {
        margin-top: 10px;
      }

      .siteViewMainDiv .ht-100 {
        float: left;
        width: 100%;
      }

      .employeeDetailsMainDiv .indexMainDiv .col-lg-3 {
        width: 25%;
      }

      .employeeDetailsMainDiv .indexMainDiv .eachIndexDiv .col-lg-8 {
        max-width: 65%;
        padding-top: 0px;
      }

      .employeeDetailsMainDiv .indexMainDiv .eachIndexDiv .col-lg-4 {
        max-width: 35%;
      }

      .employeeDetailsMainDiv .indexMainDiv .siteViewDetailsLeftSideDiv .col-lg-2 {
        max-width: 20%;
      }

      .employeeDetailsMainDiv .indexMainDiv .siteViewDetailsLeftSideDiv .col-lg-8 {
        max-width: 80%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .col-lg-4 {
        max-width: 40%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .col-lg-8 {
        max-width: 60%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .col-lg-6 {
        max-width: 50%;
      }

      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .col-lg-7,
      .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .col-lg-5 {
        max-width: 50%;
        padding: 0px 10px;
      }

      .eachRecommendCardDiv .cols-1-list.col-lg-2 {
        max-width: 15%;
      }

      .eachRecommendCardDiv .cols-3-list {
        max-width: 35%;
      }

      .white-bg.wrapper.policy-management-date.areaIndexChartMainDiv .col-lg-6 {
        max-width: 33.333%;
      }

      .white-bg.wrapper.policy-management-date.areaIndexChartMainDiv .col-lg-3 {
        max-width: 33.333%;
      }

      .white-bg.wrapper.policy-management-date.areaIndexChartMainDiv .siteManagementMainDiv.siteManagementMainDivAuto .col-lg-8 {
        max-width: 60%;
      }

      .white-bg.wrapper.policy-management-date.areaIndexChartMainDiv .siteManagementMainDiv.siteManagementMainDivAuto .col-lg-4 {
        max-width: 40%;
      }

      .header-breadcrumb.container .col-lg-8 {
        width: 70%;
      }

      .header-breadcrumb.container .col-lg-4 {
        width: 30%;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .compliance-header .col-lg-2 {
        width: 20%;
      }

      .siteViewMainDiv.siteManagementMainDiv.siteManagementMainDiv-Visitor .resp-team-box.col-lg-2 {
        width: 20%;
      }

      .siteViewMainDiv.siteManagementMainDiv.siteManagementMainDiv-Visitor .col-lg-4 {
        margin-top: 40px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .compliance-header .col-lg-7 {
        width: 60%;
      }

      .manpowerManagementMainDiv.productivityManagementDiv .header-breadcrumb.container .col-lg-3 {
        width: 30%;
        max-width: 30%;
      }

      .manpowerManagementMainDiv.productivityManagementDiv .header-breadcrumb.container .col-lg-9 {
        width: 70%;
        max-width: 70%;
      }

      .siteViewMainDiv.manpowerManagementMainDiv.productivityManagementDiv .manpowerManagementEmployeeListMainDiv .col-lg-3 {
        width: 100%;
        max-width: 100%;
      }

      .siteViewMainDiv.manpowerManagementMainDiv.productivityManagementDiv .manpowerManagementEmployeeListMainDiv .col-lg-9 {
        width: 100%;
        max-width: 100%;
        padding: 0px 15px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .compliance-header .col-lg-1 {
        display: none;
      }

      .legendarea .legendList span.textTitle {
        width: 100%;
        height: 35px;
        text-align: center;
        line-height: 1;
        margin-top: 8px;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .col-lg-4 {
        max-width: 40%;
        width: 33.333%;
      }

      .legendarea .legendList {
        text-align: center;
      }

      .legendarea .legendList span {
        float: none;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .tableBox th {
        padding: 0.8rem !important;
        font-size: 0.8rem !important;
      }

      .manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .tableBox .occurence-center .sorticon {
        right: 10px;
      }

      .date-align-right .p-l-0 {
        width: 130px;
      }

      .employee-detail-table-tr th:last-child img {
        display: none;
      }

      .manpowerManagementEmployeeListMainDiv.topHeaderVisitor .visitor-col {
        width: 25%;
      }

      .manpowerManagementEmployeeListMainDiv.topHeaderVisitor .visitor-col.visitor-stats {
        width: 50%;
      }

      .visitor-col .visitor .title .bg-icon {
        margin: auto;
      }

      .manpowerManagementEmployeeListMainDiv.topHeaderVisitor .visitor-col .check-in {
        text-align: center;
      }

      .siteViewMainDiv.siteManagementMainDiv.siteManagementMainDiv-Visitor .col-lg-8 {
        max-width: 70%;
        float: left;
      }

      .siteViewMainDiv.siteManagementMainDiv.siteManagementMainDiv-Visitor .col-lg-4 {
        max-width: 30%;
        float: left;
      }

      .Parencat .col-lg-2 {
        width: 16.6666%;
      }

      .Parencat.Parencatheader .col-lg-2 {
        width: 16.666%;
        font-size: 13px;
      }

      .innerDivAccord.active .SecondLevelCat .col-lg-2 {
        width: 16.666%;
      }
    }