@media only screen and (min-width: 1920px) {
  .siteHeadingDatePickerDiv .react-datepicker-wrapper {
    width: 35% !important;
    vertical-align: middle;
    cursor: pointer;
  }
  .commonHeadingDateMainDiv .react-datepicker-wrapper {
    width: 40% !important;
    vertical-align: middle;
    cursor: pointer;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    padding-right: 18px;
    padding-left: 0px;
  }
}
.dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .utilityPadding {
  padding: 2.5rem 1rem !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ea5d8b;
  border-color: #ea5d8b;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
  padding-left: 0 px;
}

.commonLanguageDropDownDiv .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 50px !important;
  padding: 0 16px !important;
  min-width:100px;
  text-align:left;
}

.startDateEndDateMainDiv .dropdownIconDiv {
  margin-top: 22 px;
}

.p-l-0.col-lg-1 {
  display: none;
}
.manpowerManagementMainDiv .dateBox {
  display: flex;
  justify-content: flex-end;
}
.manpowerManagementMainDiv
.employeeDetailsMainDiv
.indexMainDiv
.eachIndexDiv {
  text-align: right;
  // display: flex;
  // flex-direction: column;
}

.riskPercentageMainDiv {
  justify-content: flex-end;
  display: flex;
}
.flex.row {
  display: flex;
  justify-content: flex-end;
}
button.ant-btn.ant-btn-default {
  color: #fff !important;
}
.manpowerManagementMainDiv {
  padding: 1rem 0;

  .populationRiskMainDiv {
    background: #202236;
    padding: 1rem;
    border-radius: 10px;

    .titleText {
      font-size: 1.2rem;
    }

    .numberText {
      font-size: 1.2rem;
    }

    .percentageDiv {
      padding: 0.15rem 0.75rem;
      border-radius: 2rem;
      background: #ffffff;
      display: inline-block;
    }

    .lowText {
      font-size: 1.2rem;
    }
  }

  .dateBox {
    margin: 0 0 1.5rem;

    .contactRankText {
      color: #ffffff;
    }
  }

  .chartMainDiv {
    display: flex;
    width: 100%;
    justify-content: center;

    .chart {
      width: 32%;
      margin-right: 18px;
      border-radius: 0.5rem;
      background-color: #ffffff;
      overflow: hidden;

      .titleBox {
        display: flex;
        align-items: center;

        .title {
          padding: 8px 12px;
          font-weight: bold;
          color: #34422e;
          font-size: 1.25rem;
        }

        .relative {
          position: relative;
          flex: 1;
          top: -3px;

          .helpicon {
            height: 18px;
            width: 18px;
          }

          .descHelp {
            display: none;
            position: absolute;
            z-index: 100;
            top: 100%;
            max-width: 15rem;
            border-radius: 0.2rem;
            background-color: #000000;
            color: #ffffff;
            padding: 8px;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .attendanceTrendMainDiv {
    padding: 1rem;
    background: #ffffff;
    border-radius: 10px;
    position: relative;

    .dateText {
      font-size: 0.75rem;
    }

    .yesterdayPresentMainDiv {
      padding: 0.5rem 1.2rem;
      background: #55597c;
      border-radius: 0.5rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      div {
        font-size: 0.75rem;
      }
      .valueDiv {
        font-size: 1.2rem;
        padding: 0.25rem 0;
        color: #ffffff;
      }
    }
  }

  .teamsMainDiv {
    padding: 1rem;
    background: #ffffff;
    border-radius: 10px;
    position: relative;

    .allOrPinnedMainDiv {
      width: 100%;

      .eachDiv {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        text-align: center;

        .badgeBox {
          width: 25px;
          height: 25px;
          padding: 0.5rem;
          border: solid 1px #2f3249;
          border-radius: 50%;
          color: #2f3249;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: center;
          display: inline-block;

          span {
            position: relative;
            left: 0rem;
            top: -0.15rem;
          }
        }

        .activeBadge {
          border: solid 1px #ea5d8b;
          border-radius: 50%;
          color: white;
          background-color: #ea5d8b;
        }
      }
      .active {
        background-color: #fef0f4;
        border: #ea5d8b solid thin;
        color: #ea5d8b;
        z-index: 0 !important;
      }
    }

    .teamListDiv {
      img {
        width: 30px;
      }
      span {
        font-size: 1rem;
        padding-left: 0.5rem;
        width: 60%;
        vertical-align: middle;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .employeeDetailsMainDiv {
    .batteryMainDiv {
      background-image: linear-gradient(277deg, #404255, #6a6d83);
      padding: 0.75rem 1.5rem;
      border-radius: 2rem;
      font-size: 0.8rem;
    }

    .historyOfInfectionDiv {
      background-image: linear-gradient(294deg, #404255, #6a6d83);
      padding: 1rem 0.5rem;
      border-radius: 0.5rem;
      position: relative;

      .infectionFlex {
        align-items: center;
        margin: 0;

        strong {
          font-size: 1.125rem;
        }
      }

      .attendancePercentageRoundDiv {
        position: absolute;
        top: 1rem;
        right: 0.5rem;
        width: 75px;
        height: 75px;
        background: #81eda6;
        border-radius: 50px;
        border: 5px solid #79e127;

        .percentageText {
          position: relative;
          color: #2f3249;
          text-align: center;
          top: 1rem;
          font-size: 0.975rem;
        }

        .thisMonthText {
          font-size: 10px;
        }
      }

      .percentageBorderDiv {
        position: absolute;
        top: 1rem;
        right: 0.5rem;
        width: 75px;
        height: 75px;
        background: #000000;
        border-radius: 50px;
        border: 5px solid #79e127;
        z-index: 10001;
      }

      .infectedText {
        font-size: 0.85rem;
      }

      .attendanceDaysMainDiv {
        .noOfDays {
          font-size: 1.5rem;
          display: inline-block;
          vertical-align: middle;
        }

        .daysText {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }

    .indexMainDiv {
      .eachIndexDiv {
        padding: 1rem;
        border-radius: 10px;

        .riskPercentageMainDiv {
          text-align: right;
          .riskPercentagenNumber {
            font-size: 1.3rem;
            display: inline-block;
            vertical-align: middle;
          }

          .increaseDecreasePercentageDiv {
            padding: 0.05rem 0.8rem;
            border-radius: 14px;
            border: solid 1px #ffffff;
            background-color: #ffffff;
            vertical-align: middle;
            display: inline-block;
            margin-left: 0.5rem;
            font-size: 0.75rem;
            height: 24px;
          }
        }
      }

      .lowIndexGradientColor {
        background: linear-gradient(181deg, #04e06e, #a5e006);
      }

      .highIndexGradientColor {
        background: linear-gradient(181deg, #ff6f55, #ffdb91);
      }

      .mediumIndexGradientColor {
        background: linear-gradient(181deg, #f5b608, #ffdc91);
      }

      .naIndexGradientColor {
        background: linear-gradient(181deg, #7C828A, #BFC2C7);
      }
    }

    .mostInteractedMainDiv {
      padding: 2rem;
      border-radius: 0.5rem;
      background: #ffffff;
      box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.2);

      .tableBox {
        background-color: #ecf2f9;
        border-radius: 0.25rem;
        height: 100%;

        .viewAllEmployeesButton {
          padding: 0.35rem 1.25rem;
          border-radius: 0.25rem;
          font-weight: bold;
        }

        .alertDiv {
          text-align: right;
          margin: 0.5rem;
        }

        th {
          padding: 1rem;
          font-size: 0.95rem;
          position: relative;

          img {
            height: 18px;
            width: 18px;
            margin-left: 8px;
            cursor: pointer;
          }

          .descHelp {
            display: none;
            position: absolute;
            z-index: 100;
            top: 100%;
            border-radius: 0.2rem;
            background-color: #000000;
            color: #ffffff;
            padding: 8px;
            font-weight: 500;
            font-size: 0.875rem;
          }
        }

        td {
          padding: 0.5rem 1rem;
        }
      }
    }
  }
}



.indictaor-button-covid {
	line-height: 30px;
	min-width: 70px;
	border: 1px solid var(--green);
	color: var(--green);
	text-align: center;
	font-weight: 700;
	border-radius: 25px;
	text-transform: capitalize;
}
.indictaor-button-covid.btn-covid-positive{
  border-color: var(--red);
  color:var(--red);
}
.history-header::before {
	content: '';
	position: absolute;
	left: -8px;
	right: -8px;
	background: #87878f;
	height: 1px;
	bottom: 1px;
}
.resp-team-box.col-lg-2 .contactRankText {
  float: left;
  line-height: 40px;
  margin-right: 10px;
}
.manpowerManagementEmployeeListMainDiv .startDateEndDateMainDiv .react-datepicker-wrapper {
  float: left;
  width: 100%;
}
.siteViewMainDiv .siteViewDetailsLeftSideDiv .col-lg-10 {
  max-width: 80%;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .history-header {
	padding-bottom: 10px;
	margin-bottom: 10px;
	position: relative;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex .indictaor-button-covid {
	margin-bottom: 10px;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex h5 {
	line-height: 30px;
	margin-bottom: 10px !important;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv.inflex-some-space .infectionFlex {
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv.inflex-some-space .history-header .infectionFlex {
	margin-top: 0px !important;
	margin-bottom: 0px !important;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv.inflex-some-space .history-footer .infectionFlex{
  margin-bottom: 0px !important;
}

.manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .ant-switch-checked {
	background-color: #04e06e;
	background-image: linear-gradient(96deg, #04e06e 20%, #a5e006 111%);
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .ant-switch-checked:focus{
box-shadow: unset;
}
.view-history.red-font {
	height: 100%;
	vertical-align: middle;
	line-height: 100%;
	line-height: 45px;
}
.history-footer {
	margin-top: 20px;
}
.view-history.red-font {
	font-weight: bold;
	color: #ef5e8c;
  cursor: pointer;
}
.history-footer .viewAllEmployeesButton {
	font-weight: 600;
}
.logoutModal.MarkforPositive .logoutTitle,.logoutModal.MarkforPositive {
	background-color: #fff;
}
.logoutModal.MarkforPositive .logoutTitle{
    font-weight: 600;
}
.logoutModal.MarkforPositive .actionButton {
	text-align: center;
}
.logoutModal.MarkforPositive .actionButton span.btnText.okBtn {
	background-color: #ef5e8c;
	padding: 0px 20px;
	color: #fff;
	border-radius: 4px;
	margin-right: 20px;
}
.logoutModal.MarkforPositive .actionButton span.btnText.text-design {
	color: #ef5e8c;
}
.logoutModal.MarkforPositive .actionButton {
	text-align: center;
	margin-top: 30px;
}
.ViewCovidHistory table th, .ViewCovidHistory table td {
	padding: 13px 10px;
	min-width: 80px;
	border-bottom: 1px solid #ddd;
}
.logoutModal.ViewCovidHistory tr:last-child td {
	border-bottom: 0px;
}
.logoutModal.MarkforPositive .actionButton span{display:inline-block;line-height: 50px;cursor: pointer;font-weight: 600;}
.manpowerManagementEmployeeListMainDiv {
  padding: 1rem;
  border-radius: 0.5rem;
  background: #ffffff;
}

.ViewCovidHistory .closer-modal {
	position: absolute;
	line-height: 24px;
	font-size: 15px;
	border: 1px solid #ef5e8c;
	height: 30px;
	width: 30px;
	text-align: center;
	color: #ef5e8c;
	border-radius: 50%;
	right: 10px;
	top: 10px;
	padding: 0px;
	font-weight: 600;
	cursor: pointer;
}
.logoutModal.ViewCovidHistory table {
	width: 100%;
}
.ViewCovidHistory {
	padding: 20px;
}
.logoutModal.ViewCovidHistory table td {
	color: rgba(0, 0, 0, 0.85);
}
.logoutModal.ViewCovidHistory table th {
	color: #32325d;
	font-weight: 600;
	font-size: 16px;
}
.lightboxmodalhistory {
	position: absolute;
	z-index: 999999999999;
	box-shadow: -1px 4px 5px 0px rgba(178,161,161,0.75);
	-webkit-box-shadow: -1px 4px 5px 0px rgba(136, 112, 112, 0.4);
	-moz-box-shadow: -1px 4px 5px 0px rgba(178,161,161,0.75);
	min-width: 420px;
	top: -40px;
	left: 145px;
	border-radius: 8px !important;
	height: auto;
	background-color: #fff;
	/* overflow: hidden; */
}
.lightboxmodalhistory::before {
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 40px solid #fff;
	content: '';
	transform: rotate(270deg);
	position: absolute;
	left: -30px;
	top: 45px;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .historyOfInfectionDiv .infectionFlex .lightboxmodalhistory.lightboxmodalhistory-nodata h5 {
	color: #393963;
	padding-top: 5px;
	padding-bottom: 0px;
	margin-bottom: 0px !important;
  font-size: 16px;
}
.lightboxmodalhistory.lightboxmodalhistory-nodata::before {
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 30px solid #fff;
	content: '';
	left: -28px;
	top: 22px;
}
.lightboxmodalhistory.lightboxmodalhistory-nodata {
	top: -10px;
	max-width: 300px;
	min-width: 275px;
}
.lightboxmodalhistory .logoutModal.ViewCovidHistory {
	background: transparent;
}
.empAttendanceMainDiv {
  margin-top: 1rem;

  .eachAttendanceDiv {
    margin-bottom: 0.5rem;
  }
  .monthDiv {
    display: inline-block;
    vertical-align: middle;
    padding-right: 0.5rem;
  }

  .progressBarDiv {
    display: inline-block;
    vertical-align: middle;
    width: 57%;
    background: #5e586c;
    border-radius: 0.5rem;
    margin: 0 0.15rem;

    .daysProgressBG {
      padding: 0.35rem;
      border-radius: 0.5rem;
      background: #ef5e8c;
    }
  }

  .daysDiv {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.5rem;
  }
}

.testedPositiveDatePickerMainDiv {
  position: relative;

  .downArrowDiv {
    position: absolute;

    top: 0.15rem;
    right: 0rem;
    img {
      width: 15px;
    }
  }
  .react-datepicker-wrapper {
    max-width: 100%;
  }
  input {
    max-width: 100%;
    background: transparent;
    border: thin transparent solid;
    border-bottom: thin #e9ecef solid;
    outline: none;
    color: #e9ecef;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #e9ecef;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #e9ecef;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #e9ecef;
  }
}

.manPowerEmpListMainDiv {
  .eachCardDiv {
    border-radius: 0.5rem;
    background: #4b4d61;
    margin-bottom: 0.75rem;

    .headingNameMainDiv {
      padding: 0.5rem 0.75rem;
      .empName {
        font-size: 1rem;
      }
      .empTitle {
        font-size: 0.75rem;
      }

      .priDiv {
        .priValue {
          font-size: 1rem;
        }
      }
    }
  }
}

.eachMostVisitedAreaDiv {
  .iconDiv {
    width: 40px;
    height: 40px;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    border-radius: 50%;

    img {
      margin-top: 0.85rem;
    }
  }

  .areaNameDiv {
    width: 70%;
    display: inline-block;
    vertical-align: middle;
    padding-left: 1rem;

    .categoryName {
      border: thin #202236 solid;
      text-align: center;
      vertical-align: middle;
      display: inline-block;
      margin: 0 0.5rem;
      border-radius: 1rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.75rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.extra-box-dropdown {
  width: 160px;
  border-bottom: 1px solid blue;
  border-top: none;
  border-left: none;
  border-right: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  padding-left: 0px;
}

.extra-box-label {
  display: block;
  padding: 0px;
  font-size: 10px;
  color: gray;
}

@media only screen and (max-wdith: 1400px){
.siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div {
	font-size: 18px !important;
}
.chartTitle {
	font-size: 1rem;
}
.employeeDetailsMainDiv .m-t.row .col-lg-8 {
	padding-left: 0px;
}
.employeeDetailsMainDiv .indexMainDiv .col-lg-3 {
  padding-right: 0px;
}
.employeeDetailsMainDiv .indexMainDiv .col-lg-3:last-child {
  padding-right: 15px;
}
}