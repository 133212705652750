.singleRowList {
    
    .contentAreaHeader{
        display: none;
    }
    .contentAreaHeaderSub{
        display: none;
    }
    .contentAreaBody{
        display: none;
    }
    .headerNewFormat {
      padding: 0px 20px;
      border: 1px solid #ddd;
      margin-bottom: 10px;
      border-radius: 5px;
      line-height: 33px;
      font-size: 13px;     
    }
    .icons {
        max-width: 10px;
        display: inline-block;
        margin-right: 7px;
        cursor: pointer;

      }
}
.singleRowList.active{
 .contentBodyObject {
    display: block;
  }
  .contentAreaHeader {
    display: block;
  }
  .contentAreaHeaderSub {
    display: block;
  }
  .contentAreaBody{
    display: block;
  }

}
.singleRowList.active .headerNewFormat {
    margin-bottom: 0px;
}
.singleRowList.active .contentBodyObject {
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 4px;
}
.headerNewFormat.headerNewFormatHeader {
  padding: 0px 20px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 5px;
  line-height: 33px;
  font-size: 14px;
}
.singleRowList.active .contentBodyObject .row {
    margin: 0px;
}
.singleRowList.active .contentBodyObject .row .serialHeader.contentAreaHeaderLi{
    width:20%;
    border-left: 0px;
    font-weight: normal;
}
.singleRowList.active .contentBodyObject .row .contentAreaHeaderLi div {
    width: 50%;
    display: inline-block;
    text-align: center;
    float: left;
  }
.singleRowList.active .contentBodyObject .row .contentAreaHeaderLi {
    padding: 0px;
    background: #f2f2f2;
    width:40%;
    border-bottom: 1px solid #ddd;
    border-left: 1px solid #ddd;
    text-align: center;
    line-height: 33px;
  }
  .singleRowList.active .contentBodyObject .contentAreaBody .contentAreaHeaderLi {
    background: #fff;
    height: 33px;
    padding: 0px;
    line-height: 33px;
    font-size: 12px;
  }
  .singleRowList.active .contentBodyObject .contentAreaBody .contentAreaHeaderLi div{line-height: 35px;}
  .singleRowList.active .contentBodyObject .contentAreaBody .contentAreaHeaderLi.headerActualnoSpace{padding: 0px;line-height: 50px;} 
  .singleRowList.active .contentBodyObject .contentAreaBody .contentAreaHeaderLi.contentAreaHeaderLiGreen div:first-child{
    //background-color: #00b050;
    color:#00b050;
    font-weight: 600;
  }
  .singleRowList.active .contentBodyObject .contentAreaBody .contentAreaHeaderLi.contentAreaHeaderLiRed div:first-child{
    // background-color: #ff0000;
    color:#ff0000;
    font-weight: 600;
  }
  .singleRowList.active .contentBodyObject .contentAreaBody .contentAreaHeaderLi.contentAreaHeaderLiOrange div:first-child{
    //background-color: #f4b084;
    color:#ee640a;
    font-weight: 600;
  }
  
  .singleRowList.active .contentAreaHeader div {
    background: #e3e3e3 !important;
  }
  .modalBtn svg {
    max-width: 13px;
    fill: #000;
    margin-top: -2px;
  }
  .modalBtn {
    cursor: pointer;
width: 100%;

display: inline-block;
text-align: right;
  }
  .tooltipcustomDate {
    position: absolute;
    top: 4px;
    font-size: 10px;
    font-weight: 700;
  }
  .headerNewFormat.headerNewFormatHeader .col-lg-2 {
    text-align: center;
  }
  .modalBtn .viewsize {
    width: 27px;
    display: inline-block;
    text-align: left;
  }
  .singleRowList .headerNewFormat .firstrowWithTitle {
    width: 60%;
    float: left;
    max-width: 60%;
  }
  .thirdRows.col-lg-2 {
    max-width: 20%;
    flex: 0 0 20%;
  }
  .modal-cyclecount .modalHeader2 div {
    padding: 5px 15px;
    border: 1px solid #ddd;
    font-size: 13px;
    font-weight: 600;
  }
  .modal-cyclecount .titleArea h4 {
    font-size: 16px;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    text-transform: capitalize;
  }
  .headerAreaModal .row {
    margin: 0px;
  }
  .bodyAreaModal .modalbodyList.row {
    margin: 0px;
  }
  .bodyAreaModal .modalbodyList.row {
    margin: 0px;
    border: 1px solid #ddd;
    border-top: 0px;
    text-transform: capitalize;
  }
  .bodyAreaModal .modalbodyList.row div:first-child{
    border-right: 1px solid #ddd;
  }
  .singleRowList .icons svg {
    width: 10px;
    fill: #ef5e8c;
  }
  .textCapitalize.textCap {
    text-transform: capitalize;
  }
  .textCapitalize.textCap .icon svg {
    max-width: 14px;
  }
  .alignRightFixed {
    position: absolute;
    background: #ef5e8c;
    border: 0px;
    width: 25px;
    height: 25px;
    color: #fff;
    right: 20px;
  }
  .bodyAreaModal .modalbodyList.row div {
    padding: 4px 20px;
    font-size: 12px;
  }
  .keyAGTable.keyAGTableLessZindex.keyAGTableLessSpace .ag-header-cell.NewerDay {
    color: #293f68;
  }
  .keyAGTable.keyAGTableLessZindex.keyAGTableLessSpace .ag-header-cell.NewerDay {
    color: #ef5e8c;
  }
  .ag-theme-alpine .ag-row .ag-cell .redFont {
    color: red !important;
  }
  .ag-theme-alpine .ag-row .ag-cell .greenFont {
    color: green;
  }
  .noDataError {
    background: #fde8e8;
    padding: 8px 15px;
    border-left: 4px solid #e24545;
  }
  .noDataError h4 {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .headerFilter {
    margin-bottom: 20px;
    padding: 0px 15px;
  }