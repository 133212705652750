.siteManagementMainDiv {
    padding: 1rem 0rem;

    .siteListMainDiv {
        padding: 1.4rem 1.5rem;
        border-radius: 0.5rem;
        background: #FFFFFF;

        .priSriMriText {
            color: #55597c !important;
            font-size: 0.81rem;
        }

        .emplStatusDiv {
            padding: 0px 10px;
            text-align: center;
            border-radius: 16px;
            border: solid 1px #ff6e6e;
            font-size: 0.83rem;
            color: #ff6e6e;
            margin-top: 0.5rem !important;
        }

        .chartMainDiv {
            display: flex;
            width: 100%;
            justify-content: center;

            .chart {
                width: 48%;
                margin-right: 18px;
                border-radius: 0.5rem;
                background-color: #FFFFFF;

                .title {
                    padding: 8px 16px;
                    font-weight: bold;
                    color: #34422e;
                    font-size: 1.25rem;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .locationsListing {}

        .listingRecordMainDiv {
            margin-top: 1rem;

            .eachCard {
                background-color: #f5f7fa;
                margin-bottom: 1rem;
                border-radius: 0.5rem;

                //border: 1px solid rgba(0, 0, 0, 0.05);
                cursor: pointer;

                .card-body {
                    padding: 0.5rem 1.2rem;

                    .eachTag {
                        width: 140px;
                        display: inline-block;
                        text-align: center;
                        margin: 0 5px;
                        padding: 0.25rem 1rem;
                        border-radius: 5px;
                        background-color: #202236;
                        font-size: 14px;
                        color: #ffffff;

                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    .flexDiv {
                        display: flex;
                        align-items: center;
                        padding-right: 8px;
                        padding-left: 8px;
                    }

                    .helpicon {
                        height: 16px;
                        width: 16px;
                        margin-left: 5px;
                        margin-right: 5px;
                    }

                    .sorticon {
                        height: 16px;
                        width: 16px;
                        margin-left: auto;
                    }

                    .descHelp {
                        display: none;
                        position: absolute;
                        z-index: 100;
                        top: 100%;
                        max-width: 15rem;
                        border-radius: 0.2rem;
                        background-color: #000000;
                        color: #FFFFFF;
                        padding: 8px;
                    }

                    .riskDiv {
                        text-align: center;
                        color: #FF0000;
                        border: 2px solid #FF0000;
                        border-radius: 20px;
                        font-size: 12px;
                        font-weight: bold;
                        margin-top: 8px;
                        padding: 4px 0;
                        width: 100%;
                        text-transform: capitalize;
                    }

                    .riskDivNegative {
                        text-align: center;
                        color: #111111;
                        border: 2px solid #d8cdcd;
                        border-radius: 20px;
                        font-weight: bold;
                        font-size: 12px;
                        margin-top: 8px;
                        padding: 4px 0;
                        width: 100%;
                        text-transform: capitalize;
                        color: var(--green);
border: 2px solid var(--green);
                    }

                    .locationNameDiv {
                        font-weight: 600;
                        // font-size: 1.3rem;
                        font-size: 1rem;
                        margin-top: 0;
                        margin-bottom: 0.5em;
                        color: rgba(0, 0, 0, 0.85);
                        text-align: center;
                        display: block;
                    }

                    .nearByLocationDiv {
                        font-size: 0.75rem;
                        color: #55597c;
                    }

                    .indexCircleStatus {
                        width: auto;
                        padding: 0.25rem 0.75rem;
                        border-radius: 1rem;
                        margin-left: 1rem;
                        font-size: 12px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.21;
                        letter-spacing: normal;
                        text-align: left;
                    }

                    .lowStatus {
                        border: solid 1px #2fdf84;
                        color: #2fdf84;
                    }

                    .arrowDiv {
                        width: 50%;
                        float: right;

                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}
.siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .col:first-child .locationNameDiv{
    font-weight: 600;
font-size: 1.3rem;
margin-top: 0;
margin-bottom: 0.5em;
color: rgba(0, 0, 0, 0.85);
font-size: 1.25rem;
text-align: left;
}
.font-bold.top-seperator {
	padding-top: 10px;
}
.siteViewMainDiv {
    padding: 1rem 0;

    .siteViewDetailsLeftSideDiv {
        background: #202236;
        padding: 1.25rem;
        border-radius: 0.5rem;
        color: #FFFFFF;

        strong {
            font-size: 1rem;
        }

        .flexDiv {
            display: flex;
            justify-content: space-between;

            .sendMessageDiv {
                text-align: center;
                font-weight: bold;
            }
        }

        .headerNameDiv {
            font-size: 1.75rem;
        }

        .subHeaderDiv {
            font-size: 1rem;
        }

        .separaterLine {
            margin: 0.5rem 0;
            border: 0;
            border-top: 1px solid rgba(255, 255, 255, 0.3);
        }

        .recommendMainDiv {
            .recommendListMainDiv {
                .eachRecommendCardDiv {
                    margin-bottom: 1.25rem;
                    padding: 1rem;
                    border-radius: 0.45rem;
                    background-image: linear-gradient(279deg, #404255, #5c5e74);
                    font-size: 1rem;
                }
            }
        }
    }

    .siteViewRightSideDiv {
        .areaIndexMainDiv {
            padding: 1rem;
            border-radius: 12px;
            // background-image: linear-gradient(to bottom, #04e06e, #a5e006);

            .areaIndexValue {
                display: inline-block;
                vertical-align: super;
                color: #111111;
            }

            .areaIndexRiskPercentageDiv {
                padding: 0.2rem 0.70rem;
                border-radius: 14px;
                border: solid 1px #ffffff;
                background-color: #ffffff;
                vertical-align: super;
                display: inline-block;
                margin-left: 0.5rem;
                font-size: 0.75rem;
            }

            .riskLevelText {
                color: #34422e;
                font-size: 0.75rem;
            }
        }

        .footfallMainDiv {
            padding: 1.5rem;
            border-radius: 12px;
            box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.5);
            background-color: #ffffff;

            .dayWeekButtonMainDiv {
                .buttonDiv {
                    width: 95px;
                    padding: 7.8px 17.8px 8px 18px;
                    border-radius: 100px;
                    border: solid 1px #ef5e8c;
                    background-color: #fef0f4;
                    color: #ef5e8c;
                    font-size: 15px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.18;
                    letter-spacing: normal;
                    text-align: center;
                    margin: 0 0.5rem;

                    &:first-child {
                        margin-left: 0;
                    }
                }

                .activeFootfall {
                    background-color: #ef5e8c;
                    border-color: #ea5d8b;
                    color: white;
                }
            }
        }
    }
}
.siteViewMainDiv .ht-100{
		height: 100%;
}
.overCrowdedGradientColor {
    background: linear-gradient(106deg, #ff6f55 10%, #ffdb91 92%) !important;
    color: #000000 !important;
}

.normalGradientColor {
    background: linear-gradient(106deg, #04e06e 10%, #a5e006 92%) !important;
    color: #000000 !important;
}

.crowdedGradientColor {
    background: linear-gradient(106deg, #f5b608 10%, #ffdc91 92%) !important;
    color: #000000 !important;
}

.siteViewHeaderDiv {
    .smallHeader {
        font-size: 1rem;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        font-weight: 600;
        color: #969aa9;
    }

    .breadCrumbArrow {
        font-size: 1.5rem;
        color: #969aa9;
        margin: 0 1rem;
        display: inline-block;
        vertical-align: middle;
    }

    .mediumHeader {
        font-size: 1.5rem;
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        font-weight: 600;
        color: #383b52;
    }
}

.globalSearch {

    display: flex;
    align-items: center;

    input {
        background-color: white;
        width: 25rem;
        padding: 8px 24px;
        border-radius: 16px 0 0 16px;
        outline: none;
        font-size: 0.875rem;
        border: none;
        box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2);
    }

    .suggestionBox {
        position: absolute;
        top: 42px;
        width: 25rem;
        background-color: #FFFFFF;
        z-index: 100;
        border-radius: 4px;
        box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2);
        overflow: auto;
        max-height: 15rem;

        .suggestion {
            font-size: 0.875rem;
            font-weight: bold;
            padding: 12px;
            cursor: pointer;
            border-bottom: 0.063rem solid #bfc2c7;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    .optionBox {
        background-color: white;
        width: fit-content;
        border-radius: 0 16px 16px 0;

        .btn-primary {
            background-color: #FFFFFF !important;
            padding: 8.5px 20px;
            font-weight: bold;
            font-size: 0.875rem;
            box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2) !important;
            border: none;
            outline: none;
            border-radius: 0 16px 16px 0;
            color: #34422e !important;
        }
    }
}

.locationTabMainDiv {
    text-align: end;
    width: 100%;

    .eachTab {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 130px;
        padding: 0.25rem 0.5rem;
        border: thin #ea5d8b solid;
        cursor: pointer;

        span {
            background-color: #5e586c;
            color: #FFFFFF;
            border-radius: 50%;
            padding: 6px;
            font-size: 10px;
        }

        &:first-child {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            border-right: none;
        }

        &:last-child {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }

    .activeTabBG {
        background: #ea5d8b;
        color: #FFFFFF !important;
    }
}

.areaIndexChartMainDiv {
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2);
}

.siteHeadingDatePickerDiv {
    width: 20%;
    display: inline-block;
    vertical-align: middle;
    padding: 0 1rem;
    padding-left: 0;

    .react-datepicker-popper {
        left: -200px !important;
    }

    .react-datepicker__triangle {
        left: 13rem !important;
    }

    .react-datepicker-wrapper {
        width: 47% !important;
        vertical-align: middle;
        cursor: pointer;

        input {
            border: none !important;
            outline: none !important;
            background: none !important;
            width: 100% !important;
            text-align: right;
            cursor: pointer;
            caret-color: transparent;
        }
    }
}

.mostInteractedListMainDiv {
    background: #f5f7fa;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;

    .dateInnerMainDiv {
        padding: 0.35rem 0;
        border-bottom: thin #e3e5eb solid;

        img {
            width: 18px;
            transform: rotate(90deg);
            display: inline-block;
            vertical-align: middle;
        }
    }

    .mostInereractedEmpDiv {
        padding: 0.5rem 0;
        border-bottom: thin #e3e5eb solid;

        &:last-child {
            border-bottom: none;
        }
    }
}

.empTeamTabMainDiv {
    .eachTab {
        display: inline-block;
        vertical-align: middle;
        width: 110px;
        text-align: center;
        padding: 0.25rem 0.5rem;
        border: thin #ea5d8b solid;
        cursor: pointer;

        &:first-child {
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            border-right: none;
        }

        &:nth-child(2) {
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }
    }

    .activeTabBG {
        background: #ea5d8b;
        color: #FFFFFF !important;
    }
}

.listingSearchMainDiv {
    input {
        width: 100%;
        border-radius: 0.35rem;
        border: 1px solid #cad1d7;
        color: #8898aa;
        outline: none;
        padding: 0.5rem;

        &:focus {
            border: 1px solid #cad1d7;
            color: #8898aa;
            outline: none;
        }
    }
}

.eachPeakHoursDiv {
    padding: 0.3rem 0;

    img {
        width: 18px;
        margin-right: 0.5rem;
        vertical-align: middle;
    }

    span {
        vertical-align: middle;
    }
}

.locationsOverviewMainDiv {
    padding: 1rem;
    border-radius: 0.5rem;
    background: #202236;

    .randomBubbleMainDiv {
        position: relative;
        height: 400px;

        .eachBubbleMainDiv {
            position: absolute;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .greenBubbleSize {
            width: 80px;
            height: 80px;
        }

        .orangeBubbleSize {
            width: 160px;
            height: 160px;
        }

        .yellowBubbleSize {
            width: 120px;
            height: 120px;
        }
    }
}


.bubbleViewLocationsMainDiv{
    padding: 1rem 1rem 0 1rem;

    .pinnedHighRiskMainDiv{
        .eachTabDiv{
            width: 100px;
            padding: 0.15rem 0.25rem;
            display: inline-block;
            vertical-align: middle;
            border-radius: 0.25rem;
            border: thin #eeedf0 solid;
            color: #7f7f89;
            border-left: none;
            text-align: center;
            cursor: pointer;

            span{
                display: inline-block;
                vertical-align: middle;
            }


            .numberDiv{
                background: transparent;
                display: inline-block;
                vertical-align: middle;
                margin-left: 0.25rem;
                width: 18px;
                height: 18px;
                text-align: center;
                font-size: 0.7rem;
                border-radius: 9px;
                border:thin #eeedf0 solid;
            }

        }

        .activeTab{
            border: thin #ea618f solid;
            
            color: #ea5d8b;
            background: #fdeff5;
            .numberDiv{
                background: #ea5d8b;
                color: #FFFFFF;
                border: thin #ea618f solid;
            }

        }

        .filterDiv{
            float: right;
            display: inline-block;
            vertical-align: middle;
            border: thin #eeedf0 solid;
            padding: 0.15rem 0.75rem;
            cursor: pointer;
            border-radius: 0.15rem;
            img{
                margin-left: 0.5rem;
                width: 15px;
            }
        }
    }
}
.crowd-count.font-bold {
	color: #fff;
	padding: 0px;
	background: #ff9696;
	line-height: 20px;
	display: inline-block;
	font-size: 12px;
	border-radius: 50%;
	height: 20px;
	width: 20px;
	text-align: center;
	position: absolute;
	left: 0px;
	top: 12px;
}
.employee-detail-table-tr td {
	text-transform: capitalize;
}
.ChartOverCrowded.eachPeakHoursDiv {
	position: relative;
}
.siteManagementChartMainDiv {
    padding: 1rem;
    border-radius: 0.5rem;
    background: #ffffff;
  }

  .dateBox {
    margin: 0 0 1.5rem;
    justify-content: flex-end;

    .contactRankText {
      color: #ffffff;
    }
  }

  .startDateEndDateMainDiv .dropdownIconDiv {
    margin-top: 22 px;
  }

  .barChartMainDiv {
    display: flex;
    width: 100%;
    justify-content: center;

    .chart {
      width: 48%;
      margin-right: 18px;
      border-radius: 0.5rem;
      background-color: #ffffff;
      overflow: hidden;
      .titleBox {
        display: flex;
        align-items: center;

        .title {
          padding: 8px 12px;
          font-weight: bold;
          color: #34422e;
          font-size: 1.25rem;
        }

        .relative {
          position: relative;
          flex: 1;
          top: -3px;

          .helpicon {
            height: 18px;
            width: 18px;
          }

          .descHelp {
            display: none;
            position: absolute;
            z-index: 100;
            top: 100%;
            max-width: 15rem;
            border-radius: 0.2rem;
            background-color: #000000;
            color: #ffffff;
            padding: 8px;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .box-font{
      font-weight: 700;
      font-size: 1rem;
  }

  .lowIndexGradientColor {
    background: linear-gradient(181deg, #04e06e, #a5e006);
  }

  .highIndexGradientColor {
    background: linear-gradient(181deg, #ff6f55, #ffdb91);
  }

  .mediumIndexGradientColor {
    background: linear-gradient(181deg, #f5b608, #ffdc91);
  }
  .ag-theme-alpine .ag-row .ag-cell:first-child div {
    height: 100%;
  }

  .naIndexGradientColor {
    background: linear-gradient(181deg, #7C828A, #BFC2C7);
  }
  .siteWidgetTable img {
    filter: brightness(0) invert(1);
  }

  .chartTitle{
    background-color: #f8efef;
    font-size: 1.2rem;
    border-radius: 10px;
    font-weight: 700;
    color: #111111;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  .areaCapacityMainDiv h6.text-white {
    font-size: 13px;
  }

  .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv .eachRecommendCardDiv {
    border-bottom: 1px solid #efe8e8;
    padding: 5px 0px;
    line-height: 18px;
    font-size: 12px;
  }
  .siteWidgetTable {
    background-image: linear-gradient(294deg, #404255, #6a6d83);
    border-radius: 0.5rem;
    position: relative;
    margin-top: 20px;
    padding: 5px;
  }
  .siteWidgetTable img {
    max-width: 18px;
    margin-left: 10px;
    position: relative;
    top:-2px;
  }
  .siteWidgetTable .employee-detail-table-tr th, .siteWidgetTable .employee-detail-table-tr td {
	border-bottom: 1px solid #ffffff4d;
}
  .align-right.ct-data.ct-data-site .ct-graph-data.ct-graph-data-false {
    left: 15px;
    width: calc(100% - 30px);
    top:0px;
  }
  .siteWidgetTable .employee-detail-table-tr th {
    line-height: 30px;
  }

  .headerNameDivBtn::before {
    content: '';
    width: 5px;
    height: 5px;
    background: #04e06e;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 10px;
    border-radius: 50%;
  }
  .headerNameDivBtn {
    border-radius: 20px;
    color:#04e06e;
    position: relative;
    padding-left: 20px;
    background: #fff;
    width: 130px;
  }
  
  .globalSearch.globalSearchAdvance input {
    z-index: 999;
  }
  .globalSearch.globalSearchAdvance {
    z-index: 999;
    position: relative;
  }
  .searchOFFOverlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
  }





  .headerNameDivBtn.productive-btn {
    width: 100px;
  }

  .headerNameDivBtn.lessproductive-btn{
    color: #ffa500;
  }
  .headerNameDivBtn.lessproductive-btn::before{
    background: #ffa500;
  }
  .headerNameDivBtn.nonproductive-btn{
    color: #db0c0c;
  }
  .headerNameDivBtn.nonproductive-btn::before{
    background: #db0c0c;
  }
  
   
  @media only screen and (max-width: 1400px) {

    .siteViewMainDiv .siteViewDetailsLeftSideDiv .headerNameDiv {
      font-size: 1.5rem;
    }
    .left-no-space-resp {
        padding-left: 0px;
    }
    .siteViewMainDiv .siteViewRightSideDiv .footfallMainDiv div {
        font-size: 18px !important;
    }
    .chartTitle {
        font-size: 1rem;
    }
    .white-bg.m-t.wrapper.areaIndexChartMainDiv {
        padding: 15px;
    }
    .eachPeakHoursDiv {
        padding: 0.2rem 0;
        font-size: 12px;
    }
    .areaIndexChartMainDiv h6.font-bold {
        font-size: 13px;
    }
    .areaIndexChartMainDiv .col-lg-9 {
        padding-left: 0px;
    }
    .barChartMainDiv .chart .titleBox .title {
        font-size: .9rem;
    }
    .siteViewMainDiv .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv {
        font-size: .9rem;
    }
}
.align-right.ct-data {
    overflow: hidden;
  }
  .align-right.ct-data.overflow-visible {
    overflow: visible;
  } 
  .ct-graph-data.ct-graph-data-false {
    overflow: hidden;
  }
  .siteWidgetTable.siteWidgetTable2 .ag-cell[col-id="time"] {
    position: absolute;
  }
  .siteWidgetTable.siteWidgetTable2 .ag-cell[col-id="time"]::before{
    display:none;
  } 