.dashboardComponentMainDiv {
    padding: 1rem 0;

    .threatWatchMainDiv {
        padding: 1rem;
        margin-top: 0.25rem;
        border-radius: 10px;

        .threatWatchTextDiv {
            .title {
                font-size: 1.425rem;
                font-weight: 600;
            }

            .subTitle {
                font-size: 0.875rem;
                font-weight: 600;
            }

            .workSpaceStripeDiv {
                position: relative;
                margin-top: 1.2rem;
                text-align: center;
                color: #55597c;
                background: #FFFFFF;
                width: 245px;
                border-radius: 80px;
                padding: 1rem 0.5rem;
                padding-left: 3rem;
                font-size: 12px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.21;
                letter-spacing: normal;
                color: #31334c;

                @media only screen and (max-width: 1250px) {
                    width: 230px !important;
                    font-size: 11px !important;
                }

                .thumbIconDiv {
                    width: 47px;
                    height: 47px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #ffffff;
                    border-radius: 25px;
                    padding-top: 12px;
                }
            }
        }

        .dashboardPeopleImageMaiDiv {
            img {
                max-width: 100%;
            }
        }
    }

    .dashboardPeopleAndDateMainDiv {
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        margin-bottom: 1rem;

        .dashboardPeopleAndEmployeeMainDiv {
            display: inline-block;
            padding: 0.2rem 0.5rem;
            border-radius: 25px;
            background-color: #55597c;
            color: #FFFFFF;
            font-size: 12px;
            width: 32rem;
            font-size: 0.75rem !important;
            text-align: center;
            font-weight: bold;

            .col-lg-3 {
                width: 25%;
                flex: 0 0 25%;
                width: 25%;
                flex: 0 0 25%;
            }

            .deviceStatus {
                padding: 0 0 4px;
            }

            .peopleOnPremisesInnerDiv {
                padding-left: 1rem;
                text-align: left;
                display: inline-block;
                vertical-align: middle;
                padding-top: 0.25rem;
                width: 120%;


                img {
                    margin-right: 0.5rem;
                }

                span {
                    vertical-align: middle;
                    display: inline-block;
                    width: 47%;
                }
            }

            .employeeCountInnerDiv {
                text-align: left;
                display: inline-block;
                vertical-align: middle;

                .empCount {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }



    .dashboardLanguageMainDiv {
        display: inline-block;
        vertical-align: super;
        width: 25%;
    }

    .dashboardGraphAndIndexMainDiv {
        margin-top: 1rem;
        background: #FFFFFF;
        padding: 1rem;
        border-radius: 10px;
        box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2);

        .populationRiskMainDiv {
            border: solid 1px #e0e0e0;
            background-color: #f5f7fa;
            border-radius: 6px;
            width: 100%;
            cursor: pointer;
        }

        .populationRiskPadding {
            padding: 3rem 1rem;
        }

        .utilityPadding {
            padding: 1.5rem 1rem;
        }

        .indexBox {
            display: flex;
            align-items: center;
            height: 100%;

            .indexText {
                font-size: 1rem;
                font-weight: 600;
            }

            img {
                height: 16px;
                width: 16px;
                margin-left: 5px;
            }

            .indexDesc {
                display: none;
                position: absolute;
                top: 75%;
                left: 75%;
                width: 15rem;
                border-radius: 0.2rem;
                background-color: #000000;
                color: #FFFFFF;
                padding: 8px;
            }
        }

        .riskLevelMainDiv {
            padding: 12px 16px;
            text-align: center;
            border-radius: 0.5rem;

            .riskLevelTitleDiv {
                font-size: 1.2rem;
                font-weight: 600;
            }

            .riskLevelSubtitleDiv {
                font-size: 0.70rem;
                font-weight: 600;
            }
        }

        // .selectedRiskLevelDiv{
        //     background-image: linear-gradient(151deg, #04e06e, #a5e006);
        //     border-radius: 0.5rem;

        // }

        .activeTab {
            background-color: #fef0f4;
            border-color: #ea5d8b;
            color: #ea5d8b;
        }

        .pinkArrowIconDiv {
            text-align: center;
            position: relative;

            img {
                position: absolute;
                right: 1rem;
            }
        }

        .spreadMobilityAreaIndexMainDiv {
            width: 95%;
            margin: 0 auto;
        }

        .negativeMarginTop {
            margin-top: -1.5rem;
        }

        .chartView {
            margin: 2.5rem 0.5rem;
        }
    }
}

.spreadMobilityAreaIndexMainDiv.disabled {
    opacity: .7;
    cursor: auto !important;
    pointer-events: none;
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2 {
    border-radius: 10px !important;
    overflow: hidden;
}

.threeList {
    width: 32.333%;
    display: inline-block;
    margin: 0 auto;
    padding: 50px;
    text-align: center;

    h4 {
        font-size: 15px;
    }

    .newHeadline {
        font-size: 20px;

        small {

            display: block;
            font-size: 14px;
            margin-top: 1px;
        }
    }
}

.dashboardComponentMainDiv .threatWatchMainDiv.whitebgShadow {
    margin-top: 0.25rem;
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2);
}

.dashboardComponentMainDiv .threatWatchMainDiv.whitebgShadow .threatWatchTextDiv .workSpaceStripeDiv {
    border: 1px solid #ddd;
}

.threeList .newHeadline {
    font-size: 30px;
    font-weight: bold;
}

.legendArea.legendArea4.manStyle .legendLeftAreaProd {
    width: 40% !important;
}

.legendArea.legendArea4.manStyle .legendLeftAreaProdSmall,
.legendArea.legendArea4.manStyle .legendList.contentArea .legendLeftAreaProdSmall {
    width: 30% !important;
}

.chartContainerStyle h4 {
    font-size: 14px;
}

.chartContainerStyle {
    background: #f4f4f4;

    padding: 10px;
    margin-bottom: 10px;
}



.btnINline {
    margin-top: 0;
    display: inline-block;
    background: #dddd;
    border-radius: 8px;
    overflow: hidden;
}

.btnINline .btn.btn-pink.btn-primary {
    background: transparent;
    color: #000;
    border: 0px !important;
    border-radius: 0px !important;
    font-size: 13px;
    line-height: 15px;
    padding: 6px 10px;
}

.btnINline .btn.btn-pink.btn-primary:focus {
    box-shadow: none !important;
}

.errorMessage.errorMessageHighlight {
    background: #f1f1f1;
    padding: 5px 13px;
    border-left: 2px solid #8a2222;
    color: #8a2222;
    margin-bottom: 10px;
    border-radius: 2px;
}

.smallLabel {
    font-size: 10px;
    display: block;
    margin-bottom: 0px;
}

.btnINline .btn.btn-pink.btn-primary[disabled]:not(.active) {
    background: transparent !important;
    color: #000 !important;
    border: 0px !important;
    border-radius: 0px !important;
    font-size: 13px;
    line-height: 15px;
    padding: 6px 15px;
}

.smallTitleDate {
    font-size: 10px;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.btnINline .btn.btn-pink.btn-primary:hover,
.btnINline .btn.btn-pink.btn-primary.active {
    color: #fff !important;
    background: #ef5e8c !important;
}

.smallTitleDate {
    font-size: 10px;
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

.skeleton * {
    color: transparent !important;
}


.skeleton img,
.skeleton svg {
    opacity: 0;
}

.skeleton.threeList .skeleton-text {
    background: #e6e6e6;
}

.skeleton.threeList .skeleton-text,
.skeleton .skeleton-text {
    background: #f0f0f0;
    width: 100%;
    display: block;
    border-radius: 6px;
}

.threeList .skeleton-text * {
    display: inline-block;
}

.threeList .skeleton-text .iconShape {
    width: 30px;
    // float: left;
    position: relative;
    height: 30px;
    top: -10px;
}

.threeList .skeleton-text .iconShape div.upicon {
    top: -2px;
    width: 15px;
    transform: rotate(270deg);
    left: 0px;
}



.skeleton-text.live {
    color: #18935a;
}




.threeList .skeleton-text .iconShape svg {
    width: 20px;
}

.threeList .skeleton-text .iconShape div {
    width: 100%;
    position: absolute;
}

.threeList .skeleton-text .iconShape div.upicon.active svg {
    fill: #18935a;
}

.threeList .skeleton-text .iconShape div.downicon.active svg {
    fill: #da3c3c;
}


@keyframes skeleton-loading {
    0% {
        background-color: hsl(200, 20%, 80%);
    }

    100% {
        background-color: hsl(200, 20%, 95%);
    }
}

.skeleton .skeleton-text {
    width: 100%;
    // height: 0.7rem;
    // margin-bottom: 0.5rem;
    // border-radius: 0.25rem;
}

.skeleton-text__body {
    width: 75%;
}

.skeleton-footer {
    width: 30%;
}

.threatWatchAnalyticsMainDiv {
    display: flex;

    .eachColumnDiv {
        flex: 1;

        // padding: 1em;

    }

    .contaminatedMainDiv {
        background: #f5f7fa;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    .contactRankStartDateEndDateMainDiv {
        background: rgba(245, 247, 250, 0.5);
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;

        .contactRankText {
            font-size: 0.875rem;
            color: #31334c;
            padding: 0rem 0.75rem;
        }
    }

    .contaminatedDetails {
        padding: 1rem;
        display: inline-block;
        // vertical-align: middle;
        width: 50%;

        .titleText {
            font-size: 0.875rem;
        }

        &:last-child {
            border-right: thin #e0e0e0 solid;
        }

        .eachRecordDiv {
            display: block;

            .countDiv {
                margin-top: 1.8rem;
                font-size: 1.5rem;
            }

            &:first-child {
                margin-left: 0;
            }

            .labelDiv {
                font-size: 0.75rem;
                // display: flex;
            }
        }
    }
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2 .contaminatedMainDiv {
    background: transparent;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}



.greenGradienColor {
    background-image: linear-gradient(96deg, #04e06e 20%, #a5e006 111%);
}

.redGradientColor {
    background-image: linear-gradient(110deg, #ff6f55, #efc56e);
}

.contactRankSelectDropdownDiv {
    padding: 0.15rem 0.75rem;
    position: relative;

    select {
        background: url('../../assets/images/down-arrow.png') no-repeat 98%;
        background-size: 16px;
        width: 100%;
        padding: 0.05rem 0.5rem;
        border: thin #e3e4eb solid;
        outline: none;
        border-radius: 0.25rem;
        background-color: #FFFFFF;
        cursor: pointer;
        -o-appearance: none;
        -ms-appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
            border: thin #e3e4eb solid;
            outline: none;
        }
    }

    .dropdownIconDiv {
        position: absolute;
        right: 1.2rem;
        top: 0.15rem;

        img {
            width: 16px;

        }

    }
}

.fontGreen {
    color: #008000;
}

.fontOrange {
    color: #FFA500;
}

.descHelp {
    display: none;
    position: absolute;
    z-index: 100;
    // top: 100%;
    max-width: 15rem;
    border-radius: 0.2rem;
    background-color: #000000;
    color: #ffffff;
    padding: 8px;
}

.ds-helpicon {
    height: 16px;
    width: 16px;
    margin-left: 5px;
}

.align-right.ct-data.col-lg-5 {
    position: relative;
}

.ct-data .ct-graph-data {
    position: absolute;
    right: 15px;
    top: -60px;
    border: solid 1px #e0e0e0;
    background-color: #f5f7fa;
    width: 100%;
    height: 165px;
    border-radius: 8px !important;
    padding: 7px 15px;
    overflow: hidden;
}

.ct-data .ct-graph-data .col {
    border-bottom: 1px solid #ddd;
    line-height: 27px;
}

.ct-graph-data .row {
    margin: 0px;
    cursor: pointer;
}

.ct-data .ct-graph-data .col {
    border-bottom: 1px solid #ddd;
    line-height: 25px;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 12px;
    height: 25px;
}

.ct-data .ct-graph-data .row:last-child .col {
    border-bottom: 0px;
}

.ct-data .ct-graph-data .col .font-bold {
    line-height: 25px;
    padding-left: 0px;
    padding-right: 20px;
    font-size: 12px;
    height: 25px;
    font-weight: 400;
    text-align: right;
}

.smaller-header {
    float: left;
    width: 100%;
    border-bottom: 1px solid #ddd;
    font-size: 12px;
    line-height: 25px;

    .header-left {
        float: left;
    }

    .header-right {
        float: right;
        text-align: right;
    }

    .tab-small {
        div {
            display: inline-block;
            cursor: pointer;
        }

        .tab-1.tab-tiny {
            margin-right: 15px;
        }

        .tab-tiny.active {
            color: #ef5e8c;
            border-bottom: 1px solid;
            margin-bottom: -1px;
        }
    }
}

.loader-inner img {
    max-width: 70px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
}

.loader-inner {
    text-align: center;
    vertical-align: middle;
}

.ct-graph-data.ct-graph-data-false .loader-inner {

    display: none;
}

.ct-data .ct-graph-data .row .col:first-child .font-bold {
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
    display: inline-block;
    text-transform: capitalize;
}

.header-right strong {
    font-weight: 500;
    color: #ef5e8c;
    border-bottom: 1px solid;
    line-height: 25px;
    display: inline-block;
    margin-bottom: -1px;
}



.contactRankText.contactRank-range .ant-slider-handle {
    margin-top: -5px;
    background-color: #fff;
    border: solid 2px #ef5e8c;
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.contactRankText.contactRank-range .ant-slider-track {
    background-color: #ef5e8c !important;
}

.contactRankText.contactRank-range .ant-slider:hover .ant-slider-track {
    background-color: #ef5e8c !important;

}

.contactRankText.contactRank-range .ant-slider-dot-active {
    background-color: #ef5e8c !important;
    border-color: #fff;
}

.contactRankText.contactRank-range .ant-slider-mark .ant-slider-mark-text {
    font-size: 12px;
}

.contactRankText.contactRank-range .ant-slider-handle {
    margin-top: -5px;
    background-color: #ef6590;
    border: solid 2px #fff;
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #fff !important;
}

.ant-slider-track::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: linear-gradient(96deg, #04e06e 20%, #a5e006 111%);
}

.contactRankText.contactRank-range.contactRank-range-2 .ant-slider-track::before {
    background: linear-gradient(181deg, #fa7e5e, #fecf89);
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #f3f3f3;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #ffff;
}

.threatWatchAnalyticsMainDiv {
    height: 170px;
}

//Responsive 
@media only screen and (max-width: 1500px) {
    .threatWatchAnalyticsMainDiv .contaminatedDetails .titleText {
        font-size: 0.775rem;
    }

    .threatWatchAnalyticsMainDiv .contaminatedDetails {
        padding: .9rem;
    }

    .contactRankStartDateEndDateMainDiv .col-lg-6:last-child {
        padding-left: 0px;
    }

    .threatWatchAnalyticsMainDiv .contactRankStartDateEndDateMainDiv .contactRankText {
        font-size: 0.8rem;
    }

    .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .utilityPadding .col-lg-6 {
        max-width: 35%;
    }

    .dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .utilityPadding .col-lg-4 {
        max-width: 40%;
        flex: 0 0 40%;
    }

}

// .threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList.greenGradienColor {
//     background-image: linear-gradient(96deg, #04e06e 20%, #a5e006 111%);
// }
.threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList.greenGradienColor {

    border: 1px solid rgb(4, 224, 110);
    background-color: rgb(231, 246, 239);
}

// .threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList.orangeGradientColor {
//     background-image: linear-gradient(181deg, #fa7e5e, #fecf89);
// }
.threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList.orangeGradientColor {
    background: #fff1d7;
    border: 1px solid orange;
}

.percentageHidden .highcharts-root,
.percentageHidden .highcharts-container {
    overflow: visible !important;
}

.datePickerSmall {
    display: inline-block;
    float: none;
}

.datePickerSmall {
    display: inline-block;
    float: none;
    position: relative;
}

.datePickerSmall .contactRankText {
    position: absolute;
    z-index: 1;
    top: -7px;
    left: 8px;
    background-color: #fff;
    width: 30px;
    text-align: center;
    font-size: 10px;
}

.datePickerSmall .startDateEndDateMainDiv .react-datepicker-wrapper input {
    padding: 4px 10px !important;
    font-size: 12px;
}

.percentageHidden .closeMark {
    position: absolute;
    right: 0px;
    background: #ef5e8c;
    color: #fff;
    right: 0px;
    top: 0px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
}

.sc-jTzLTM {
    background-color: #e0e0e0 !important;
}

.sc-jTzLTM * {
    text-align: left;
    color: #000;
    font-size: 13px;
}

.sc-jTzLTM {
    width: 130px;
    padding: 15px;
}

.sc-bxivhb,
.sc-bxivhb * {
    border: 0px !important;
    padding: 0px !important;
    width: 25px !important;
    height: 25px !important;
    line-height: 25px !important;
    text-align: center !important;
    padding: 0px !important;
}

.sc-jzJRlG.gstDmP {
    background: #e0e0e0;
}

.sc-htpNat div {
    width: 25px;
    height: 25px;
}

.sc-EHOje.gKyapV {
    background: #ef5e8c;
}

.datePickerSmall .startDateEndDateMainDiv input {
    border: 1px solid #ddd;
    max-width: 140px;
    padding: 3px 10px;
    border-radius: 10px;
    color: transparent !important
}

.dateoverlay .dateList {
    margin-right: 10px;
}

.datePickerSmall .startDateEndDateMainDiv {
    width: 120px;
}

.sc-gqjmRU {
    border-top: 1px solid #ddd;
    background: #fff;
}

.sc-bdVaJa .sc-bwzfXH {
    padding: 0px;
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
}

.sc-kAzzGY {
    background: #fff !important;
    width: 225px !important;
}

.sc-jTzLTM {
    width: 100px !important;
    padding: 10px !important;
}

.sc-jTzLTM div:first-child {
    margin-top: 50px;
}

.fKLcWV {
    max-width: 325px !important;
    width: 325px !important;
}

.sc-gzVnrw {
    height: 180px !important;
}

.sc-kpOJdX {
    border-radius: 6px !important;
    overflow: hidden;
}

.sc-jTzLTM {
    height: 180px !important;
}

.sc-gZMcBi {
    padding-top: 5px !important;
}

.sc-htoDjs {
    height: 30px !important;
}

.sc-dnqmqq {
    height: 35px !important;
}

.sc-gqjmRU button:first-child {
    background: #ddd;
    color: #000;
    font-size: 10px;
    padding: 0px 20px;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 0px;
}

.sc-gqjmRU button:last-child {
    background: #ef5e8c;
    color: #fff;
    font-size: 10px;
    padding: 0px 20px;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 5px;
}

.sc-chPdSV.bSnXtJ {
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
}

.dateoverlay span.label {
    position: absolute;
    left: 10px;
    width: 130px;
    text-align: left;
    top: 7px;
    font-size: 11px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList {
    // width: 30%;
    width: 32%;
    background: #ddd;
    border-radius: 18px !important;
    margin-right: 10px !important;
    overflow: hidden;
    padding: 25px 15px;
}

.threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList:last-child {
    margin-right: 0px !important;
}

.smallerHeadline .row {
    margin: 0px;
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 .threeList {
    background: transparent !important;
    border: 0px !important;
    margin-right: 0px !important;
    overflow: hidden;
    width: 22%;
    vertical-align: top;
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 .threeList:last-child {
    width: 34%;
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 strong.skeleton-text {
    font-size: 32px;
}

.threatWatchMainDiv.whitebgShadow .col-lg-3 {
    max-width: 20%;
}

.threatWatchMainDiv.whitebgShadow .col-lg-4 {
    max-width: 37%;
    flex: 0 0 37%;
}


.threatWatchMainDiv.whitebgShadow .col-lg-3,
.threatWatchMainDiv.whitebgShadow .col-lg-4,
.threatWatchMainDiv.whitebgShadow .col-lg-5 {
    padding-right: 0px;
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 {
    border: 1px solid #04e06e;
    background-color: #e7f6ef !important;
    background-image: unset;
}

.newHeadline .skeleton-text img {
    max-width: 25px;
}

.skeleton-text.redColor {
    color: #DA3C3C;
}

.skeleton-text.greenColor {
    color: #18935a;
}

.dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .utilityPadding {
    padding: 2.6rem 1rem !important;
}

.dashboardComponentMainDiv .dashboardGraphAndIndexMainDiv .pinkArrowIconDiv img {
    top: -4px !important;
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 .threeList {
    padding: 24px 0px;
}

#breaktime.populationRiskMainDiv {
    display: none;
}

.livearea {
    position: absolute;
    background: #159730;
    color: #fff;
    padding: 0px 20px;
    padding-left: 25px;
    right: 0px;
    border-radius: 5px;
}

.skeleton .livearea {
    background: #e6e6e6;
    color: transparent !important;
    animation: skeleton-loading 1s linear infinite alternate;

}


.threeList .skeleton-text .iconShape {
    width: 30px;
    position: relative;
    // height: 55px;
}

.threeList .skeleton-text .iconShape div.upicon {
    top: 0px;
    width: 15px;
    transform: rotate(270deg);
    left: 0px;
    margin: auto;
    height: 65px;
    bottom: -45px;
}

.threeList .skeleton-text .iconShape div.downicon {
    top: 0px;
    width: 15px;
    transform: rotate(210deg);
    left: 0px;
    margin: auto;
    height: 45px;
    bottom: -36px;
}

.threatWatchAnalyticsMainDiv {
    height: 200px;
}

.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.livearea::before {
    content: '';
    width: 8px;
    height: 8px;
    background: #fff;
    position: relative;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    left: 10px;
    animation: blinker 1s linear infinite;
}

.datePickerSmall .startDateEndDateMainDiv .textVIsible input {
    color: #000 !important;
}

.skeleton.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3.greenGradienColor {
    background: #e8edef !important;
}

.smallSelect {
    max-width: 160px;
    display: inline-block;
    float: none;
    position: relative;
    margin-right: 10px;

}

.smallSelect .ant-select-selection-overflow {
    height: 28px;
    overflow-y: scroll;
}

.smallSelect .ant-select .ant-select-selector {
    border: 1px solid #ddd !important;
    font-size: 11px;
    padding: 0px 10px;
    border-radius: 8px;
    height: 30px;
    line-height: 30px;
}

.smallSelect .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
    padding: 0px 10px !important;
}

.smallSelect .contactRankText {
    position: absolute;
    z-index: 9;
    font-size: 10px;
    top: -7px;
    left: 8px;
    padding: 0px 3px;
    background: #fff;
}

.smallSelect.smallBtn button {
    border: 0px;
    color: #fff;
    height: 28px;
    background: #ef5e8c;
    font-size: 13px;
    padding: 0px 17px;
    border-radius: 6px;
}

.helpIconAnimate {
    max-width: 20px;
    position: absolute;
    left: 20px;
    right: 0px;
    top: 10px;
    cursor: pointer;
}

.helpIconAnimate svg {
    fill: #27272f;
}

.helpIconAnimate.active svg {
    fill: #ef5e8c;
}

// .helpToolTip {
//     position: absolute;
//     width: 80px;
//     font-size: 10px;
//     background: #000;
//     padding: 10px !important;
//     left: 30px;
//     color: #fefefe !important;
//     top: 0px;
//     border-radius: 4px;
//     opacity: 0;
//     text-align: center;
// }

.helpIconAnimate:hover .helpToolTip {
    opacity: 1;
}

.smallSelect.smallBtn button[disabled] {
    opacity: .7;
}


// .helpToolTip {
//     position: absolute;
//     width: 90px;
//     font-size: 10px;
//     background: rgba(0, 0, 0, 0.65);
//     padding: 6px 10px !important;
//     left: 30px;
//     color: #fefefe !important;
//     top: 0px;
//     border-radius: 4px;
//     opacity: 0;
// }

.iconSmallHelp {
    max-width: 15px;
    margin-left: 6px;
    // opacity: .7;
}

.helpSmallInfo.iconSmallHelp svg {
    max-width: 15px;
    max-height: 15px;
    fill: #807575;
    cursor: pointer;
}

.helpSmallInfo.iconSmallHelp {
    display: inline-block;
    width: 17px;
    height: 17px;
}

.tooltipNewStyle {
    display: none;
}

// .tooltipNewStyle {
//     position: absolute;
//     background: #333;
//     border-radius: 5px;
//     text-align: left;
//     padding: 13px;
//     color: #fff;
//     font-size: 13px;
//     max-width: 200px;
//     display: none;
//     z-index: 99;
//     line-height: 1.2;
// }
.tooltipNewStyle,
.helpToolTip {
    position: absolute;
    background: #f4f4f4;
    border-radius: 5px;
    text-align: left;
    padding: 13px;
    color: #373434;
    font-size: 13px;
    max-width: 200px;
    display: none;
    z-index: 99;
    line-height: 1.2;
    box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2);
    min-width: 100px;
}

.helpIconAnimate:hover .helpToolTip {
    display: block;
}

.threeList .skeleton-text .tooltipNewStyle {
    display: none;
}

.skeleton.threeList .tooltipNewStyle {
    display: none !important;
}

.tooltipNewStyle p {
    margin-bottom: 8px;
    font-weight: normal;
}

.contactRankText img {
    max-width: 12px;
    margin-left: 5px;
}

.tooltipNewStyle h5 {
    // color: #fff;
    font-size: 14px;
}

.livearea.liveAreaHelp {
    background: transparent;
    border: 0px;
    right: 60px;
}

.tooltipcolorIndicator {
    display: inline-block !important;
    position: relative;
    top: -2px;
    height: 6px !important;
    width: 6px !important;
    margin-right: 3px;
}

.smallSelect {
    max-width: 160px;
    display: inline-block;
    float: none;
    position: relative;
    margin-right: 10px;

}

.smallSelect .ant-select-selection-overflow {
    height: 28px;
    overflow-y: scroll;
}

.smallSelect .ant-select .ant-select-selector {
    border: 1px solid #ddd !important;
    font-size: 11px;
    padding: 0px 10px;
    border-radius: 8px;
    height: 30px;
    line-height: 30px;
}

.smallSelect .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 28px;
    padding: 0px 10px !important;
}

.smallSelect .contactRankText {
    position: absolute;
    z-index: 9;
    font-size: 10px;
    top: -7px;
    left: 8px;
    padding: 0px 3px;
    background: #fff;
}

.smallSelect.smallBtn button {
    border: 0px;
    color: #fff;
    height: 28px;
    background: #ef5e8c;
    font-size: 13px;
    padding: 0px 17px;
    border-radius: 6px;
}

.helpIconAnimate {
    max-width: 20px;
    position: absolute;
    left: 20px;
    right: 0px;
    top: 10px;
    cursor: pointer;
}

.helpIconAnimate svg {
    fill: #27272f;
}

.helpIconAnimate.active svg {
    fill: #ef5e8c;
}

// .helpToolTip {
//     position: absolute;
//     width: 80px;
//     font-size: 10px;
//     background: #000;
//     padding: 10px !important;
//     left: 30px;
//     color: #fefefe !important;
//     top: 0px;
//     border-radius: 4px;
//     opacity: 0;
//     text-align: center;
// }

.helpIconAnimate:hover .helpToolTip {
    opacity: 1;
}

.smallSelect.smallBtn button[disabled] {
    opacity: .7;
}


// .helpToolTip {
//     position: absolute;
//     width: 90px;
//     font-size: 10px;
//     background: rgba(0, 0, 0, 0.65);
//     padding: 6px 10px !important;
//     left: 30px;
//     color: #fefefe !important;
//     top: 0px;
//     border-radius: 4px;
//     opacity: 0;
// }

.iconSmallHelp {
    max-width: 15px;
    margin-left: 6px;
    // opacity: .7;
}

.helpSmallInfo.iconSmallHelp svg {
    max-width: 15px;
    max-height: 15px;
    fill: #807575;
    cursor: pointer;
}

.helpSmallInfo.iconSmallHelp {
    display: inline-block;
    width: 17px;
    height: 17px;
}

.tooltipNewStyle {
    display: none;
}

// .tooltipNewStyle {
//     position: absolute;
//     background: #333;
//     border-radius: 5px;
//     text-align: left;
//     padding: 13px;
//     color: #fff;
//     font-size: 13px;
//     max-width: 200px;
//     display: none;
//     z-index: 99;
//     line-height: 1.2;
// }
.tooltipNewStyle,
.helpToolTip {
    position: absolute;
    background: #f4f4f4;
    border-radius: 5px;
    text-align: left;
    padding: 13px;
    color: #373434;
    font-size: 13px;
    max-width: 200px;
    display: none;
    z-index: 99;
    line-height: 1.2;
    box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2);
    min-width: 100px;
}

.helpIconAnimate:hover .helpToolTip {
    display: block;
}

.threeList .skeleton-text .tooltipNewStyle {
    display: none;
}

.skeleton.threeList .tooltipNewStyle {
    display: none !important;
}

.tooltipNewStyle p {
    margin-bottom: 8px;
    font-weight: normal;
}

.contactRankText img {
    max-width: 12px;
    margin-left: 5px;
}

.tooltipNewStyle h5 {
    // color: #fff;
    font-size: 14px;
}

.livearea.liveAreaHelp {
    background: transparent;
    border: 0px;
    right: 60px;
}

.facilityList .facilityHeader {
    display: flex;
    justify-content: space-between;
}

.facilityList .facilityInner {
    display: flex;
    justify-content: space-between;
}

.facilityList {
    border: 1px solid #ddd;
}

.facilityList .facilityHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: #ef5e8c;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    border: 0.1px solid;
}

.facilityList .facilityInner {
    display: flex;
    justify-content: space-between;
    padding: 7px 20px;
    font-size: 12px;
    color: #4f4545;
    border-bottom: 1px solid #ddd;
}

.facilityCompare.inFacilityArea {
    border-right: 3px dashed #e8e8e8;
}

.siteWidgetTable.siteWidgetTable2.siteWidgetTable3 .ag-cell {
    position: unset;
}

.siteWidgetTable.siteWidgetTable2.siteWidgetTable3 {
    background: transparent;
    margin-top: 0px;
}

.facilityList .facilityHeader div,
.facilityList .facilityInner div {
    flex-basis: 100%;
}

.facilityList div .facilityInner:nth-child(2n) {
    background: #f1f1f1;
}

.threeList .readmoreStyle {
    color: transparent;
    opacity: 0;
    margin-top: 0px;
    background: #32325d;
    color: #fff;
    font-size: 12px !important;
    border-radius: 20px;
    width: 85px;
    margin: 0px auto !important;
}

.liveCountPlanActual.smallHeadline .row {
    margin: 0px;
}

.liveCountPlanActual.smallHeadline div {
    font-size: 11px;
    line-height: 25px;
    text-align: center;
}

.threatWatchMainDiv.whitebgShadow .smallerHeadline .col-lg-4 strong {
    font-size: 12px;
    display: block;
    line-height: 25px;
}

.rightGoing svg {
    width: 8px;
    fill: #fff;
    transition: ease-in 0.3s;
    position: relative;
    left: 0px;
}

.ant-modal-wrap {
    user-select: none;
}

.readmoreStyle:hover .rightGoing svg {
    left: 5px;
}

.rounded.rounded-pink {
    width: 35px;
    height: 20px;
    display: inline-block;
    background: #ef5e8c;
    vertical-align: middle;
    text-align: center;
    line-height: 20px;
    color: #fff;
    font-size: 12px;
    margin-right: 3px;
}

.someTitleThing {
    margin-bottom: 15px;
    padding: 10px 20px;
    background: #eee;
    font-size: 14px;
}

.textCap.textCapYellow {
    color: #b18c49;
    font-weight: bold;
}

.textCap.textCapGreen {
    color: #12640b;
    font-weight: bold;
}

.textCap.textCapRed {
    color: #640b0b;
    font-weight: bold;
}

.textCap.textCapYellow::before,
.textCap.textCapGreen::before,
.textCap.textCapRed::before {
    content: '';
    width: 4px;
    height: 4px;
    background: #b18c49;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    border-radius: 50%;
}

.textCapitalize.textCap.textLeft {
    text-align: left;
}

.textCap.textCapGreen::before {
    background: #12640b;
}

.textCap.textCapRed::before {
    background: #640b0b;
}

.keyAGTableSmallPlan .ag-theme-alpine .ag-ltr .ag-cell {
    line-height: 27px;
}

.manpowerManagementMainDiv.manpowerManagementMainDivHeightFull {
    min-height: 100vh;
}

.smallerHeadline .col-lg-4 {
    padding: 0px 4px;
    margin: 0px;
}

.threatWatchMainDiv.whitebgShadow .smallerHeadline .col-lg-4 {
    width: 33.333333%;
    flex: 0 0 33.333333%;
}

.threatWatchMainDiv.whitebgShadow .smallerHeadline .col-lg-4:first-child {
    padding-left: 0px;
}

.threatWatchMainDiv.whitebgShadow .smallerHeadline .col-lg-4 img {
    max-width: 25px;
    filter: grayscale(50%);
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 .topThreeList .threeList {
    padding-bottom: 0px;
}

.liveCountPlanActual.smallHeadline .col-lg-1,
.liveCountPlanActual.smallHeadline .col {
    padding: 0px 4px;
}

.liveCountPlanActual.smallHeadline .col-lg-1 img,
.liveCountPlanActual.smallHeadline .col img {
    max-height: 25px;
}


.highcharts-legend div.legendStyleMobileNew span:first-child {
    width: 12px !important;
    height: 12px !important;
    border-radius: 3px !important;
    margin-right: 3px !important;
    float: left;
}

g.highcharts-legend-item.highcharts-spline-series {
    display: none;
}

.chartContainerStyle .highcharts-legend * {
    position: unset !important;
    display: inline-block;
    margin-right: 4px;
}

.chartCHanges g.highcharts-legend-item.highcharts-spline-series {
    display: block;
}

.datePickerSmall .startDateEndDateMainDiv.startDateEndDateMainDivSmallDashboard {
    width: 150px;
}