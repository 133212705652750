.mapboxgl-canvas {
    background: #14141a;
}

.absGreatStyle {
    position: absolute;
    bottom: 10px;
    left: 20px;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    // width: 575px;
    // width: 95px;
    height: 60px;
    cursor: pointer;
    transition: width ease-in 0.3s;
    background: rgba(255, 255, 255, 0.1);
}


.absGreatStyle .boxStyle {
    display: inline-block;
    text-align: center;
    padding: 7px;
    width: 60px;
    height: 60px;
    vertical-align: top;
}

.absGreatStyle .boxStyle.active,
.absGreatStyle .boxStyle.active:hover {
    transition: all ease-in 0.3s;
    background: #575757;
}

.absGreatStyle .boxStyle.active small,
.absGreatStyle .boxStyle.active:hover small {
    color: #fff;
}

.absGreatStyle .boxStyle img {
    max-width: 35px;
}

.absGreatStyle .boxStyle small {
    font-weight: bolder;
    text-transform: uppercase;
    display: block;
    font-size: 9px;
}

.saveButton {
    position: absolute;
    width: 29px;
    height: 29px;
    right: 15px;
    bottom: 30px;
    overflow: hidden;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    outline: none;
    overflow: hidden;
    padding: 0;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
    cursor: pointer;
    text-align: center;
}

.saveButton svg {
    max-width: 16px;
    vertical-align: middle;
    margin-top: 4px;
}

.absGreatStyle .currentStyle {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 60px;
    height: 60px;
}

.absGreatStyle .expandMode {
    position: absolute;
    left: 60px;
    width: 300px;
    top: 0;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-bottom-left {
    display: none !important;
}

.absGreatStyle.Dark * {
    color: #fff !important;
}