body {
  width: 100%;
  height: 100%;
  background: #ecf2f9 !important;
  font-family: sans-serif;
  color: #32325d !important;
}

.mainAppDiv {}

.RightSideDiv {
  float: left;
}

.withLoginRightSideDiv {
  margin-left: 13rem;
  width: calc(100% - 13rem);
  overflow-x: hidden;
}

.withoutLoginRightSideDiv {
  width: 100%;
  margin-left: 0;
}

.commonHeading {
  color: #32325d;
  line-height: 1.5;
  font-weight: 600;
}

.successToast {
  background: #00FF00 !important;
  color: #FFFFFF !important;
}

.errorToast {
  background: #ff0000 !important;
  color: #FFFFFF !important;
}

.ReactModal__Overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  box-shadow: none !important;
}