.manpowerManagementMainDiv.productivityManagementDiv .chartMainDiv .chart .titleBox .title {
  font-size: 1rem;
  width: 100%;
}

.al-rt {
  float: right;
}

.manpowerManagementMainDiv.productivityManagementDiv .chartMainDiv .chart .titleBox .title .btn.btn-small {
  padding: 0px 10px;
  background-color: #ef5e8c;
  margin-right: 3px;
  font-size: 13px;
  color: #fff;
  border: 1px solid #ef5e8c;
}

.manpowerManagementMainDiv.productivityManagementDiv .chartMainDiv .chart .titleBox .title .btn-transparent.btn.btn-small {
  background-color: #fff;
  color: #000;
  border-color: #ddd;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  background: #ef5e8c;
  border-color: #ef5e8c;
}

.manpowerManagementMainDiv .chartMainDiv.chartMainDivProductivity .chart {
  position: relative;
}

.chartMainDiv.chartMainDivProductivity .helpIconAnimate {
  right: 70%;
  left: auto;
  width: 20px;
}

.StackCHartSelect {
  padding-left: 20px;
  margin-bottom: 10px;
  height: 30px;
}

.StackCHartSelect .helpIconAnimate {
  top: 0px;
}

.viewIcon {
  border: 1px solid;
  padding: 0px 11px;
  border-color: #ef5e8c;
  color: #ef5e8c;
  background: #fff;
  cursor: pointer;
  border-radius: 7px;
}

.modalAnnouncement .logoutModal {
  padding: 40px;
}

.modalAnnouncement .logoutModal .logoutDesc p {
  font-size: 16px;
  text-align: center;
}

.ReactModal__Content.ReactModal__Content--after-open.modalAnnouncement {
  overflow: hidden !important;
}

.closeModalImg {
  position: absolute;
  right: 0px;
  padding: 10px;
  background: #ef5e8c;
  max-width: 40px;
}

.announceMEntTitle img {
  position: relative;
  margin-top: -11px;
  margin-bottom: 20px;
  margin-left: 10px;
}

h5.errorStyle {
  color: #d53232;
  background: #f2f2f2;
  padding: 10px;
  font-size: 13px;
  border-left: 4px solid;
}

.manpowerManagementEmployeeListMainDiv {
  .btn.btn-group {
    border: 1px solid #ddd;
    margin-left: 5px;
    min-width: 120px;
    text-align: center;
    display: inline-block;

    &:hover {
      background-color: #ef5e8c;
      color: #fff;
      border: 1px solid #ef5e8c;
    }
  }

  .btn.btn-group.btn-active {
    border: 1px solid #ef5e8c;
    margin-left: 5px;
    background-color: #ef5e8c;
    color: #fff;

    &:hover {
      background-color: #f88daf;
      color: #fff;
      //  border: 1px solid #ddd;
    }
  }
}

.manpowerManagementEmployeeListMainDiv .listingSearchMainDiv .startDateEndDateMainDiv {
  display: inline-block;
  max-width: 50%;
}

.manpowerManagementEmployeeListMainDiv .listingSearchMainDiv .startDateEndDateMainDiv .react-datepicker-wrapper {
  float: left;
  width: 100px;
}

.manpowerManagementEmployeeListMainDiv .startDateEndDateMainDiv label {
  float: left;
  line-height: 40px;
  padding-right: 10px;

}

.manpowerManagementEmployeeListMainDiv .listingSearchMainDiv .listingSearchMainDiv {
  text-align: right;
}

.chart.disabled {
  opacity: .9;
  background: #ddd !important;
}

.siteViewMainDiv.manpowerManagementMainDiv.productivityManagementDiv .mostInteractedMainDiv.row {
  padding: 0px;
  box-shadow: unset;
  margin-top: 20px !important;
}

.manpowerManagementMainDiv .employeeDetailsMainDiv .manpowerManagementEmployeeListProductivity .mostInteractedMainDiv .tableBox th {
  font-size: .8rem;
}

.manpowerManagementMainDiv .areaIndexChartMainDiv.manpowerManagementEmployeeListProductivity .mostInteractedMainDiv.row {
  padding: 15px;
  box-shadow: unset;
}

.Parencat {
  padding: 6px;
  border-radius: 0px;
  font-weight: 600;
  background: #f5f7fa;
  margin: -15px -15px 0px -15px;
  border: 1px solid #ddd;
}

.SecondLevelCat {
  padding: 6px 15px;
  border: 1px solid #ddd;
  font-weight: bold;
  margin-top: 10px;
}

.ThirdLevelCat {
  padding: 10px 40px;
  padding-right: 30px;
  border: 1px solid #ddd;
  margin-top: 10px;
  font-weight: bold;
  margin-top: 10px;
}

.accordIcon svg {
  max-width: 10px;
}

.accordIcon {
  float: left;
  cursor: pointer;
  text-align: center;
  line-height: 20px;
  margin-right: 5px;
  width: 20px;
}

.innerDivAccord.active .ag-header-row.ag-header-row-column,
.innerDivAccord.active .ag-header-cell,
.innerDivAccord.active .ag-theme-alpine .ag-header-cell-resize,
.innerDivAccord.active .ag-header {
  height: 35px !important;
  min-height: 35px !important;
}

.align-right.ct-data.ct-data-site.pos-relative-data .ct-graph-data.ct-graph-data-false {
  position: relative;
  height: auto;
}

.stackChart .highcharts-point {
  cursor: pointer;
}

.innerDivAccord.active .ag-cell {
  line-height: 35px;
}

.highlightTyp svg {
  max-width: 12px;
  margin-right: 5px;
}

.innerDivAccord.active .SecondLevelCat.active+.ChildSecondSeries {
  display: block;
}

.SecondLevelCat .highlightTyp svg {
  max-width: 8px;
  margin-right: 5px;
}

.ChildSecondSeries {
  display: none;
}

.ChildThirdSeries {
  display: none;
}

.innerDivAccord {
  padding: 15px;
  border: 1px solid #f5f7fa;
  margin-bottom: 10px;
}

.innerDivAccord .SecondLevelCat {
  display: none;
}

.innerDivAccord .ThirdLevelCat {
  display: none;
}

.innerDivAccord {
  padding-bottom: 0px;
}

.innerDivAccord.active {
  padding-bottom: 15px;
}

.innerDivAccord.active .Parencat {
  margin-bottom: 15px;
}

.innerDivAccord.active .SecondLevelCat {
  display: block;
}

.innerDivAccord.active .ThirdLevelCat {
  display: block;
}

.nochild {
  display: none;
}

.innerDivAccord.active .nochild {
  display: block;
}

.innerDivAccord .ag-root-wrapper.ag-layout-normal.ag-ltr {
  border-radius: 0px;
}

.siteViewMainDiv .manpowerManagementEmployeeListProductivity .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv {
  font-size: .7rem;
  margin-bottom: 1.1rem;
}

.siteViewMainDiv .manpowerManagementEmployeeListProductivity .siteViewDetailsLeftSideDiv .recommendMainDiv .recommendListMainDiv .eachRecommendCardDiv .text-right.col-lg-4 {
  line-height: 24px;
}

.donut-inner {
  // padding: 10px;
  // border: 1px solid #ddd;
  float: left;
  width: 100%;
}

.donut-inner .donutInnerTable {
  float: left;
  width: 100%;
  display: table;
}

.donut-inner .donutInnerTable .wt-70 {
  width: 60%;
  display: table-cell;
}

.donut-inner .donutInnerTable .wt-30 {

  width: 40%;
  display: table-cell;
  vertical-align: middle;
}

.donut-inner h6 {
  font-weight: bold;
  // position: absolute;
  z-index: 99;
  text-align: center;
}

.legendarea .legendList {
  font-size: 12px;
  padding-right: 0px;
  width: 100%;
  display: inline-block;
  text-align: left;
  border-bottom: 0px;
  margin-bottom: 7px;
}

.ct-graph-data.ct-graph-data-false .text-whiteSpace.row .col {
  height: 35px;
}

.ct-graph-data.ct-graph-data-false .text-whiteSpace.row {
  height: 35px;
}

.legendarea .legendList span.textTitle {
  width: auto;
  top: 0px;
  padding-right: 0px;
  margin-right: 0px;
}

.legendarea {
  text-align: center;
  margin-bottom: 10px;
  margin: 10px auto;
  width: 90%;
  margin-top: -10px;
}

.legendarea .legendList span {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 2px;
  border-radius: 2px;
  float: left;
  margin-right: 5px;
}

.manpowerManagementEmployeeListMainDiv .startDateEndDateMainDiv.date-label-full label {
  width: 100%;
  line-height: 1;
}

.manpowerManagementEmployeeListMainDiv .startDateEndDateMainDiv.date-label-full .react-datepicker-wrapper {
  width: 100%;
}

.manpowerManagementEmployeeListMainDiv .startDateEndDateMainDiv.date-label-full {
  width: 130px;
  display: inline-block;
}

.ct-data .ct-graph-data .text-whiteSpace.row .col .font-bold {
  white-space: normal;
  word-wrap: break-word;
  height: auto;
}

.ct-data .ct-graph-data .text-whiteSpace.row .col .font-bold span {
  color: #ef5e8c;
  font-weight: bold;
  padding-right: 1px;
}

.highcharts-plot-band {
  fill: #f0efef !important;
}

.subLineTab .sublinetablist.disabled {
  cursor: not-allowed;
  pointer-events: auto;
  opacity: .5;
}

.xaxis-legend.xaxis-legend-12 .legendDate {
  position: absolute;
  background: #f0efef;
  height: 17px;
  margin-top: -2px;
  line-height: 18px;
  padding: 0px 5px;
  border-radius: 4px;
}

.xaxis-legend.xaxis-legend-12 .legendDate::before {
  content: attr(date);
  font-size: 12px;
  font-weight: bold;
}

.ag-theme-alpine .ag-row .ag-cell:first-child {
  text-transform: capitalize;
}

.subLineTab .sublinetablist {
  font-size: 14px;
  color: #000;
  display: inline-block;
  margin-right: 20px;
  border-bottom: 2px solid transparent;
  line-height: 30px;
  margin-bottom: -1px;
  font-weight: 600;
  cursor: pointer;
}

.subLineTab .sublinetablist.active {
  border-bottom: 2px solid #ef5e8c;
  color: #ef5e8c;
}

.subLineTab {
  border-bottom: 1px solid #ddd;
}

.siteListMainDiv .row .btn.btn-group:first-child {
  margin-left: 0px;
}

.innerDivAccord.active .SecondLevelCat .title {
  text-transform: capitalize;
}

.productiveTable::before {
  content: '';
  width: 4px;
  height: 4px;
  background: #51a333;
  position: absolute;
  border-radius: 50%;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0px;
}

.productiveTable {
  position: relative;
  font-size: 13px;
  padding-left: 10px;
  color: #51a333;
  font-weight: 500;
}

.employeeDetailsMainDiv .manpowerManagementEmployeeListMainDiv.manpowerManagementEmployeeListProductivity .batteryMainDiv.batteryMainDiv2 {
  margin: 0px;
  padding: 8px 20px;
}

.siteViewMainDiv .manpowerManagementEmployeeListProductivity .siteViewDetailsLeftSideDiv .subHeaderDiv {
  font-size: 0.8rem;
}

.siteViewMainDiv .manpowerManagementEmployeeListMainDiv.manpowerManagementEmployeeListProductivity .siteViewDetailsLeftSideDiv .headerNameDiv {
  font-size: 1.25rem;
  line-height: 1;
}

.danger.productiveTable::before {
  background: #db0c0c;
}

.danger.productiveTable {
  color: #db0c0c
}

.warning.productiveTable::before {
  background: orange;
}

.warning.productiveTable {
  color: orange
}

.manpowerManagementMainDiv.productivityManagementDiv .chartMainDiv .chart .titleBox .title .btn.btn-small:focus {
  box-shadow: unset;
}

.manpowerManagementEmployeeListMainDiv .listingSearchMainDiv {
  position: relative;
  z-index: 99;
}

.globalSearch.globalSearchAdvance .suggestion {
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  font-weight: normal;
}

.globalSearch.globalSearchAdvance .catTitle {
  background: #f5f7fa;
  padding: 5px 10px;
  border-bottom: 1px solid #ddd;
  font-size: 0.875rem;
  font-weight: bold;
}

.globalSearch.globalSearchAdvance .catTitle strong {
  float: right;
  background: #000000d9;
  width: 20px;
  text-align: center;
  color: #fff;
  font-weight: normal;
  font-size: 13px;
}

.globalSearch.globalSearchAdvance input {
  width: 100%;
  border-radius: 0.35rem;
  border: 1px solid #cad1d7;
  color: #8898aa;
  outline: none;
  padding: 0.5rem;
  box-shadow: unset;
}

.tab-view.tab-view-2 {
  display: table;
}

.tab-view.tab-view-2 .tab {
  height: 40px;
  vertical-align: middle;
  font-size: 12px;
  line-height: 1.2;
  display: table-cell;
  border-collapse: collapse;
  border-left: 2px solid #202236;
  border-radius: 9px;
  padding: 0px;
}


.siteWidgetTable.siteWidgetTable2 .ag-header-cell,
.siteWidgetTable.siteWidgetTable2 .ag-header-cell-resize,
.siteWidgetTable.siteWidgetTable2 .ag-header,
.siteWidgetTable.siteWidgetTable2 .ag-header-row {
  height: 35px !important;
  min-height: 35px !important;
  line-height: 35px !important;
}

.siteWidgetTable.siteWidgetTable2 .ag-theme-alpine .ag-row {
  font-size: 13px;
}

.siteWidgetTable.siteWidgetTable2 .ag-cell::before {
  width: 5px;
  height: 5px;
  content: '';
  background: #ef5e8c;
  position: absolute;
  display: block;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 15px;
}

.siteWidgetTable.siteWidgetTable2 .ag-cell {
  padding-left: 25px;
  line-height: 30px;
}

.siteWidgetTable.siteWidgetTable2 .ag-cell {
  position: relative;
}

button.ant-btn.button-angle.btn {
  color: #000 !important;
}

.ant-btn.ant-btn-default.button-angle.btn.active {
  background: #EF5F8C !important;
  color: #fff !important;
}

.ant-btn.ant-btn-default.button-angle.btn:focus,
.ant-btn.ant-btn-default.button-angle.btn:active {
  box-shadow: none !important;
  outline: none !important;
}

.ant-btn.ant-btn-default.autoplayStop.btn-smaller {
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
  padding: 0px !important;
  width: 100%;
}

.ant-btn.ant-btn-default.autoplayStop.btn-smaller::after {
  display: none !important;
}

.ant-btn.ant-btn-default.autoplayStop.btn-smaller svg {
  fill: #ef5f8c;
  max-width: 60px;
  width: 60px;
  position: absolute;
  left: 0;
  right: 0;
  top: -12px;
  margin: auto;
}

.nicerFormatRange.row .ant-slider-mark-text {
  font-size: 10px;
  width: 40px;
}

.nicerFormatRange.row .ant-slider.ant-slider-disabled.ant-slider-horizontal.ant-slider-with-marks {
  margin-bottom: 0px;
}

.nicerFormatRange.row .ant-slider-mark-text {
  font-size: 10px;
  width: 40px;
  margin-top: 12px;
}

.nicerFormatRange .ant-slider.ant-slider-horizontal.ant-slider-with-marks {
  margin-bottom: 0px;
}

.nicerFormatRange.row {
  margin-top: 30px;
  border: 2px solid #ddd;
  border-radius: 25px;
  text-align: center;
  background-color: #fff;
  margin-bottom: 30px;
}

.titleBox .ant-select-selector {
  border: 1px solid #ddd !important;
  font-size: 13px;
  height: 23px !important;
  padding: 0px 10px !important;

  .ant-select-selection-placeholder {
    color: #000 !important;
    line-height: 23px;
  }

  .ant-select-selection-item {
    color: #000 !important;
    line-height: 20px;
    text-align: left;
    text-transform: capitalize;
  }
}

.leftSelect {
  padding-left: 10px;
  display: inline-block;
  margin-right: 5px;
}

.righSelect {
  display: inline-block;
  text-align: right;
  padding-right: 10px;
}

.leftSelect .ant-select.ant-select-single:last-child {
  padding-left: 5px;
}

.ant-select-item-option-content {
  text-transform: capitalize;
}

.ant-select-dropdown.smallerDrop .ant-select-item {
  padding-top: 0px;
  padding-bottom: 0px;
  height: 20px !important;
  min-height: 20px !important;
  line-height: 20px !important;
  font-size: 13px !important;
}

.manpowerManagementMainDiv .chartMainDiv.chartMainDivProductivity .chart {
  width: 49%;
}

.manpowerManagementMainDiv .chartMainDiv .chart {
  margin-right: 2%;
}

.chartMainDivProductivity .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
}

.manpowerManagementMainDiv .chartMainDiv .chart .titleBox {
  min-height: 23px;
}

.noRecordStyle .Parencat.col-lg-12 {
  border-left: 3px solid #a22626;
  color: #a22626;
}

.bghideDisplaySearch {
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.manpowerManagementMainDiv .chartMainDiv .chart .titleBox .filterSelected {
  margin-left: 15px;
  line-height: 20px;
  border: 1px solid #ddd;
  padding: 0px 9px;
  font-size: 13px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  padding-right: 30px;
  opacity: .9;
  height: 20px;
  cursor: pointer;
}

.manpowerManagementMainDiv .chartMainDiv .chart .titleBox .filterSelected span {
  background-color: #ee1717;
  width: 16px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  text-align: center;
  right: 0px;
  line-height: 17px;
  color: #fff;
}

.Parencat.Parencatheader {
  margin: 10px 0;
  background: #e5e8ec;
  border: 2px solid #ddd;
}

//Mess Css For Productivit new //
.Parencat.Parencatheader .col-lg-2 {
  text-align: center;
}

.Parencat.Parencatheader .col-lg-2:first-child {
  text-align: left;
}

.Parencat .col-lg-2 {
  text-align: center;
}

.Parencat .col-lg-2:first-child {
  text-align: left;
}

.innerDivAccord.active .SecondLevelCat .col-lg-2 {
  text-align: center;
}

.innerDivAccord.active .SecondLevelCat .col-lg-2:first-child {
  text-align: left;
}

.innerDivAccord.active .SecondLevelCat .col-lg-2:nth-of-type(2) {
  padding-left: 0px;
  padding: 0px;
  // margin-left: -10px;
  margin-left: -20px;
}

.innerDivAccord.active .SecondLevelCat .col-lg-2:nth-of-type(3) {
  padding-left: 20px;
  padding-right: 0px;
}

.innerDivAccord.active .SecondLevelCat .col-lg-2:nth-of-type(4) {
  padding-left: 25px;
  padding-right: 0px;
}

.innerDivAccord.active .SecondLevelCat .col-lg-2:nth-of-type(5) {
  padding-right: 0px;
  // padding-left: 45px;
  padding-left: 25px;
}

.innerDivAccord.active .SecondLevelCat .col-lg-2:nth-of-type(6) {
  padding-right: 0px;
  // padding-left: 60px;
  padding-left: 25px;
}

//Mess Css For Productivit new //
.errorThing {
  text-align: center;
  height: 270px;
  vertical-align: middle;
  line-height: 270px;
  font-size: 20px;
}

.align-right.ct-data.ct-data-site.ct-data-site-2.pos-relative-data .font-bold {
  padding-right: 5px;
}

.align-right.ct-data.ct-data-site.ct-data-site-2.pos-relative-data .col:last-child {
  padding-right: 0px;
  text-align: right;
}

.align-right.ct-data.ct-data-site.ct-data-site-2.pos-relative-data .col:last-child h6 {
  padding-right: 0px;
}

@media screen and (min-width: 1698px) {
  .align-right.ct-data.ct-data-site.ct-data-site-2.pos-relative-data .font-bold {
    padding-right: 5px;
    font-size: 12px;
    line-height: 30px !important;
  }
}



.StackCHartSelect .stackChart-btn {
  background: #fff;
  border: 1px solid #ddd;
  padding: 3px 10px;
  font-size: 12px;
  font-weight: bold;
}

.StackCHartSelect .stackChart-btn.btnActive {
  background-color: #ef5e8c;
  color: #fff;
  border-color: #ef5e8c;
}

.ant-switch-checked {
  background-color: #ef5e8c;
}

.viewIcon svg {
  fill: #ef5e8c;
  max-width: 17px;
}

.viewIcon {
  border: 0px !important;
  background: transparent;
  padding: 0px;
}

.badgeOuter {
  padding: 15px;
  height: 159px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.bdrAbs::before {
  content: '';
  top: -1rem;
  right: -5px;
  height: calc(100% + 2rem);
  position: absolute;
  background: #ecf2f9;
  width: 10px;
  bottom: -1rem;
}

.badgeArea img {
  max-height: 100px;
}

.tabDiv .performanceType .tabBtn {
  line-height: 25px;
  background-color: #ddd;
  padding: 0px 10px;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;

}

.tabDiv .performanceType .tabBtn.tab-transparent.activePerformance.active {
  background-color: #ef5e8c;
  color: #fff;
}

.manpowerManagementEmployeeListProductivity .errorMsg {
  margin: 5px;
  margin-bottom: 5px;
  padding: 6px 20px;
  background: #ddd;
  border-left: 3px solid red;
  margin-bottom: 15px;
}

.loaderStyle.loaderStyle2 {
  padding: 0px;
}

.loaderStyle.loaderStyle2 * {
  font-size: 13px;
}

.topHeaderArea .loaderStyle.loadStyleError h4 {
  font-size: 12px !important;
}

@media only screen and (min-width:767px) {
  .donut-inner {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .Parencat.Parencatheader .row {
    display: table;
    width: 100%;
  }

  .Parencat.Parencatheader .row div {
    display: table-cell;
  }

  .Parencat.Parencatheader .row .col-lg-1 {
    padding: 0px;
  }

  .innerDivAccord .Parencat .row .col-lg-1 {
    padding: 0px;
  }

  .innerDivAccord.active .SecondLevelCat .col-lg-1 {
    padding-left: 15px;
  }
}

.ag-theme-alpine .ag-row .ag-cell:first-child div.greenHeader,
.greenHeader {
  background: #c9f0da !important;
  color: #325c14;
  margin: 0px -20px;
  text-align: center;
}

.ag-theme-alpine .ag-row .ag-cell:first-child div.orangeHeader,
.orangeHeader {
  background: #ffd5a7 !important;
  color: #aa671f;
  margin: 0px -20px;
  text-align: center;
}



.emp24record ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

.emp24record ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

// .ag-center-cols-clipper .ag-center-cols-viewport {
//   height: 630px !important;
//   overflow-x: scroll;
//   overflow-y: scroll;
// }

.accordIcon.disabled {
  display: none;
}

.ag-theme-alpine .ag-row .ag-cell:first-child div.redHeader,
.redHeader {
  background: #ffa7a7 !important;
  color: #aa261f;
  margin: 0px -20px;
  text-align: center;
}

.ag-theme-alpine .ag-row .ag-cell:first-child div.disabledCell,
.disabledCell {
  background: #9a9292;
  color: #1a1919de;
  opacity: .5;
  margin: 0px -20px;
  text-align: center;
}

.loadingAnimate:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}

.loadingAnimate {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
  width: 60px;
  color: transparent !important;
}

.ag-theme-alpine .ag-row .ag-cell:first-child div.loadingAnimate {
  background-color: #DDDBDD;
  height: 1em;
}

.manpowerManagementEmployeeListMainDivScroll .ag-body-horizontal-scroll {
  width: 100%;
  display: flex;
  position: relative !important;

}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.manpowerManagementEmployeeListMainDivScroll .ag-header-icon.ag-sort-order {
  display: none !important;
}

.productivityManagementDivLesserSpace .ag-header-cell.ag-header-cell-sortable {
  padding: 0px 10px !important;
}

.productivityManagementDivLesserSpace .ratingFill.ratingAG svg {
  max-width: 13px;
}

.productivityManagementDivLesserSpace .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height {
  padding: 0px 10px;
  font-size: 13px;
}

.ant-select-dropdown.smallerDrop.smallerDroplessWidth {
  width: 130px !important;
}

.VerySmall svg {
  width: 15px;
  margin-left: 10px;
}

.VerySmall {
  max-width: 30px;
  display: inline-block;
  cursor: pointer;
}

.announceMEntTitle {
  text-transform: capitalize;
}



.nonproductiveannounce .logoutModal {
  background: #fff1f1;
}

.nonproductiveannounce .logoutModal .logoutTitle {
  border-color: #f0b6b6 !important;
}

.nonproductiveannounce .logoutModal * {
  color: #672323;
}

.nonproductiveannounce .logoutModal .logoutTitle {
  border-color: #e3cdcd !important;
}

.productiveannounce .logoutModal {
  background: #e6ffea;
}

.productiveannounce .logoutModal .logoutTitle {
  border-color: #aad796 !important;
}

.productiveannounce .logoutModal * {
  color: #11500f;
}

.lessproductiveannounce .logoutModal {
  background: #fdefd3;
}

.lessproductiveannounce .logoutModal .logoutTitle {
  border-color: #e8d2b0 !important;
}

.lessproductiveannounce .logoutModal * {
  color: #3d332a;
}

.modalAnnouncement2 .closeModalImg {
  background: #a29f9f;
  width: 30px;
  height: 30px;
  padding: 8px;
}