.tab-view {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
    .tab.tab-activity {
        width: 32%;
        display: inline-block;
        text-align: center;
        font-size: 14px;
        padding: 10px;
        background-color: #fff;
        border-radius: 4px;
        vertical-align: middle;
        color: #000;
        
        .count {
            display: block;
            font-size: 24px;
            font-weight: 600;
        }
    }
    .tab.tab-activity.active {
        background-color: #ef5e8c;
        color: #fff;
    }
    .tab.tab-activity:nth-of-type(2) {
        margin-left: 2%;
        margin-right: 2%;
    }
    
}
.create-new-button {
    background-color: #ef5e8c;
    padding: 10px;
    line-height: 30px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    .float-end {
        float: right;
        max-width: 35px;
    }
}
.cursor-pt{cursor: pointer;}
.eachRecommendCardDiv {
    
        .icon-click{
        text-align: right;
        div {
            display: inline-block;
            padding: 0px 5px;
            width: 28px;
            height: 28px;
            // background-color: rgb(32, 34, 54,0.3);
            margin-right: 6px;
            border-radius: 4px;
        }
        img{
            max-width: 16px;
        }
    }
    .bg-highlight {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid #fff;
    }   
    .mg-bottom-10{
        margin-bottom: 10px;
    }
    .cols-1-list {
        padding-right: 0px;
    }
    .cols-2-list {
        padding: 0px;
    }
    .cols-3-list {
        text-align: right;
    }
    .cols {
        line-height: 20px;
    }
    h4.fnt-20 {
        color: #fff;
        font-size: 18px;
    }
    .mg-bottom-20{margin-bottom: 10px;}
}
.error-message {
	font-size: 12px;
	color: #7b1e1e;
	border: 1px solid;
	padding: 0px 11px;
	margin-bottom: 5px;
	background-color: #f2f2f2;
}
.siteManagementMainDiv.createPolicyContainer.loading .createPolicyMainDiv {
	opacity: .5;
    cursor: auto;
    pointer-events: none;
}


.siteManagementMainDiv.siteManagementMainDivAuto .siteListMainDiv .listingRecordMainDiv .eachCard .col:first-child h6 {
	text-transform: capitalize;
}
.border-outer.pad-10 {
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .ht-auto .tableBox {
	height: auto;
}
.create-policy-modal.loading .loading-div {
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	background-color: rgba(255, 255, 255, 0.9);
	z-index: 9999;
	top: 0;
	bottom: 0;
}
.create-policy-modal.loading .loading-div img {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	top: 0;
}

.loaderDiv {
	text-align: center;
    display:none;
}
.siteManagementMainDiv.loading .loaderDiv {
	display: block;
 }
.siteManagementMainDiv  .listingRecordMainDiv .eachCard .col:first-child h6{	text-transform: capitalize;}
.recommendMainDiv .loaderDiv{display: none}
.recommendMainDiv.loading .loaderDiv { margin-bottom: 1.25rem;
	padding: 1rem;
    display: block;
	border-radius: 0.45rem;
	background-image: linear-gradient(279deg, #404255, #5c5e74);
	font-size: 1rem;
	text-align: center;
}
.siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard:last-child {
	margin-bottom: 0px;
}
.viewAllEmployeesButton.disabledBtn {
	cursor: auto;
	opacity: .5;
	pointer-events: none !important;
}
.recommendMainDiv.loading .loaderDiv img{max-width:100px;}
.recommendMainDiv.loading .eachRecommendCardDiv {
	display: none;
}

.last-updated-on.row {
	margin-top: 10px;
	border-top: 1px solid #6c6e76;
	padding-top: 10px;
}
.eachRecommendCardDiv .last-updated-on.row .cols-1-list, .eachRecommendCardDiv .last-updated-on.row .cols-3-list {
	font-size: 13px;
	line-height: 16px;
}
.eachRecommendCardDivOverflow.eachRecommendCardDiv {
	max-width: calc(100% - 15px);
}
.thumb-vertical2 {
	background: #515368 !important;
    border-radius:4px;
}
.track-vertical2 {
	right: 0;
	height: 100%;
	background-color: #000;
	border-radius: 4px;
}
.delete-policy-modal {
	width: 360px !important;
    height: 200px !important;
    .delete-policy-conatiner {
        padding: 20px;
    }
     .logoutTitle {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 15px;
        color:#10141a;
    }
    .actionButton {
        margin-top: 15px;
        text-align: right;
        .btnText {
            padding: 10px 20px;
            display: inline-block;
            background-color: #dcddde;
            font-weight: 600;
            border-radius: 4px;
            float: right;
            color: #10141a;
            min-width: 95px;
            text-align: center
        }
        .btnText.okBtn {
            background-color:#ef5e8c;
            color: #fff;
            margin-left: 12px;
        }
    }
    .Policy-name {
        border: 1px solid #ddd;
        text-align: center;
        padding: 0px 20px;
        font-size: 15px;
        color: #10141a;
        font-weight: 500;
        line-height: 40px;
        border-radius: 4px;
    }
}
.policy-management-date .startDateEndDateMainDiv label {
    float: left;
    display: inline-block;
}
.policy-management-date .startDateEndDateMainDiv .react-datepicker-wrapper {
    float: left;
    width: 100%;
    margin-left: 0px;
}
.font-bold.clr-white {
	color: #fff;
}
.siteManagementMainDiv.siteManagementMainDivAuto .siteListMainDiv {
	padding-left: 0px;
	padding-right: 0px;
}
.siteManagementMainDiv.siteManagementMainDivAuto .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .flexDiv {
	justify-content: center;
}
.siteManagementMainDiv.siteManagementMainDivAuto .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .flexDiv:first-child{justify-content: left;}
.siteManagementMainDiv.siteManagementMainDivAuto .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .sorticon {
	justify-content: right;
}
.siteManagementMainDiv.siteManagementMainDivAuto .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .sorticon {
	margin: 0px;
	position: absolute;
	right: 5px;

}
.siteManagementMainDiv.siteManagementMainDivAuto .siteListMainDiv .listingRecordMainDiv .eachCard .col:first-child{
    text-align: left;
}
.siteManagementMainDiv.siteManagementMainDivAuto .siteListMainDiv .listingRecordMainDiv .eachCard .col {
	justify-content: center;
	text-align: center;
}
.siteManagementMainDivAuto .eachTab {
	font-weight: 600;
	border: 1px solid #ddd;
	line-height: 30px;
    border-radius:4px !important;
}
.siteManagementMainDivAuto .eachTab.activeTabBG {
	border-color: #ea5d8b;
}
.white-bg.wrapper.policy-management-date.areaIndexChartMainDiv {
    height: 100%;
}
.eachRecommendCardDiv .icon-click div {
	background-color:transparentize(rgb(32, 34, 54),.7)  ;
    transition: all ease-in 0.3s;
}
.eachRecommendCardDiv .icon-click div:hover {
	background-color:transparentize(rgb(32, 34, 54),0)  ;
    transition: all ease-in 0.3s;
}
.white-bg.wrapper.policy-management-date.areaIndexChartMainDiv,.siteViewMainDiv .ht-100 {
	min-height: calc(100vh - 100px);
}
.eachRecommendCardDiv .icon-click div.delete.cursor-pt:hover {
	background: #c11010;
}
.no-policy-avail {
	text-align: center;
	margin-top: 25px;
}
.eachRecommendCardDiv .icon-click div.delete.cursor-pt {
	padding: 0px;
	text-align: center;
	margin-right: 0px;
}
.siteViewMainDiv .loading-div {
	display: none;
}
.siteViewMainDiv.loading .loading-div {
	display: block;
}
.siteViewMainDiv.loading .employeeDetailsMainDiv{
    display: none;
}

.siteViewMainDiv.loading .loading-div {
	position: fixed;
	left: 0;
	right: 0;
	margin: auto;
	top: 0;
	bottom: 0;
	width: 200px;
	height: 200px;
}
.siteManagementMainDiv.loading .canvas2.hide-loading{display:none;}