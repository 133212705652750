// body iframe:not(.skiptranslate) {
//     display: none !important;
// }

.leftSideBarDiv {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    right: 0;
    left: 0;
    overflow-x: hidden;
    max-width: 13rem;
    overflow-y: auto;
    height: 100%;
    //border-right: thin black solid;
    background: #FFFFFF;
    float: left;

    .logo {
        padding: 0 1.5rem;
        text-align: center;
        margin-top: 1.5rem;
        width: 100%;
    }

    .adminName {
        text-align: center;
        margin-top: 2.5rem;
        font-weight: 600;
    }

    .designation {
        text-align: center;
        margin-top: 0.8rem;
        margin-bottom: 1rem;
        font-size: 20px;
    }

    ul {
        padding: 0;
        padding-left: 1.5rem;
        margin: 0;
        list-style: none;
        margin-top: 2rem;

        div {
            display: block;
            cursor: pointer;
            margin-bottom: 0.2rem;
            color: #202236;
            text-transform: capitalize;
            transition: all ease-in-out .3s;
            opacity: 1 !important;

            &:hover {
                background: #ecf2f9;
                border-top-left-radius: 60px;
                border-bottom-left-radius: 60px;
            }


            img {
                width: 25px;
                height: 25px;
                vertical-align: middle;
                padding-left: 0.5rem;
            }

            span {
                font-size: 12px;
                margin-left: 0.5rem;
                vertical-align: middle;

            }

            a {
                text-decoration: none;
                width: 100%;
                padding: 0.5rem 0;
                display: block;
                color: inherit;
            }
        }

        div.activeTab {
            background-color: #ecf2f9;
            border-top-left-radius: 60px;
            border-bottom-left-radius: 60px;
            color: #ea5d8b !important;

        }
    }

    .LogoutDiv {
        position: absolute;
        bottom: 1rem;
        padding: 0.5rem 1rem;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 0.5rem;

        span {
            cursor: pointer;
            text-decoration: none;
            color: #202236;
        }

        &:hover {
            background: #ecf2f9;
        }

    }
}

.logoutModal {
    background-color: #f9fafa;

    .logoutTitle {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 1.7;
        padding: 20px 0 10px;
    }

    .logoutDesc {
        font-size: 1.125rem;
        font-weight: 500;
        text-align: center;
        padding: 10px 0 42px;
    }

    .actionButtons {
        display: -webkit-box;
        border-top: 0.063rem solid #ebeced;
        text-align: center;
        cursor: pointer;
        float: left;
        width: 100%;

        .btnText {
            font-size: 1.125rem;
            display: block;
            text-align: center;
            text-decoration: none;
            line-height: 1.7;
            padding: .65em 0;
            width: 49.7%;
            color: #7c828a;
        }

        .okBtn {
            color: #f73e5d !important;
        }

        .borderRight {
            border-right: 0.063rem solid #ebeced;
        }
    }
}

.wholePageLoaderMainDiv {
    position: fixed;
    z-index: 10001;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);

    img {
        position: absolute;
        top: 40%;
        transform: translateX(-50%);
        left: 50%;
    }
}

.LogoutDiv.LogoutDiv2 {
    position: relative;
    font-size: 13px;
    margin-top: 20px;
    background: #ef5e8c;
    width: 72px;
    line-height: 1;
    padding: 5px;
    color: #fff;

    span {
        color: #fff;
    }
}

.holidayLoading {
    height: 100vh;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
}

.holidayLoading img {
    position: absolute;
    height: 200px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}