.compliance-header {
	padding: 15px;
	border: 1px solid #ddd;
	border-radius: 9px;
}
.compliance-header p {
	margin-bottom: 0px;
	color: #131314;
}
.compliance-header h6 {
	font-weight: bold;
	color: #0d0d0e;
}
.ov-hidden {
    overflow: hidden;
    border-radius: 0px 0px 6px 6px;
}
.mostInteractedMainDiv.mostInteractedMainDiv2 .viewAllEmployeesButton {
	min-width: 100px;
	text-align: center;
    font-weight: 600;
    padding: 0.35rem 1.25rem;
}
.breached-member {
	float: right;
	max-width: 130px;
	display: block;
	width: 105px;
	text-align: left;
}
.breached-member.non-violated {
	color: #28a745;
}
.breached-member.non-violated::before {
	content: '';
	display: inline-block;
	width: 8px;
	height: 8px;
	background: #28a745;
	border-radius: 50%;
	margin-right: 8px;
}
.breached-member.violated {
	color: #ef5e8c ;
}
.employee-detail-table-tr.disabled-row {
	opacity: .5;
	cursor: auto;
	pointer-events: none;
}
.breached-member.violated::before {
	content: '';
	display: inline-block;
	width: 8px;
	height: 8px;
	background: #ef5e8c ;
	border-radius: 50%;
	margin-right: 8px;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .tableBox .occurence-center {
	text-align: center;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv.ht-auto .tableBox{
	height: auto;
}
.mostInteractedMainDiv.mostInteractedMainDiv2.m-t-lg {
	min-height: calc(100vh - 100px);
}
.mostInteractedMainDiv.mostInteractedMainDiv2.m-t-lg .alertDiv {
	bottom: -20px;
	position: relative;
}
.manpowerManagementMainDiv .employeeDetailsMainDiv .mostInteractedMainDiv .tableBox .occurence-center .sorticon {
	position: absolute;
	right: 20px;
	top: 0px;
	bottom: 0px;	margin: auto;
}
.policy-compliance-location .mostInteractedMainDiv.mostInteractedMainDiv2.m-t-lg .alertDiv  {
	bottom: -20px;
	float: right;
}