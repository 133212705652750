.compliance-header .startDateEndDateMainDiv.date-start,
.compliance-header .startDateEndDateMainDiv.date-end {
	text-align: left;
}
.compliance-header .startDateEndDateMainDiv.date-start label, 
.compliance-header .startDateEndDateMainDiv.date-end label {
	margin-bottom: 0px;
	padding-right: 10px;
	max-width: 200px;
	float: right;
	text-align: left;
	width: 100%;
}
.compliance-header .startDateEndDateMainDiv.date-start .react-datepicker-wrapper, .compliance-header .startDateEndDateMainDiv.date-end .react-datepicker-wrapper {
	float: right;
	width: 100%;
	max-width: 200px;
}

.date-align-right .p-l-0 {
	display: inline-block !important;
}
.date-align-right {
	text-align: right;
}
.viewAllEmployeesButton.mr-10 {
	margin-right: 10px;
}
.alertDiv.alertDiv-down-10 {
	position: relative;
	top: 20px;
}
.top-title h6 {
	line-height: 40px;
	font-weight: bold;
	color: #0d0d0e;
	text-transform: capitalize;
}
.alert-sucess .actionButton .bnt-close-bigger {
	width: 200px;
	margin: 0px !important;
}
.alert-sucess {
	text-align: center;
	padding: 40px !important;
	height: 290px !important;
}
.sucess-inner img {
	max-width: 70px;
}
.alert-sucess .actionButton {
	margin-top: 10px !important;
}
.alert-sucess.alert-fail .actionButton .btnText {
	width: 150px;
	background-color: #e1e1e1;
	border-radius: 5px;
	height: 50px;
}
.alert-sucess.alert-fail .actionButton .btnText:first-child{margin-right: 10px;}