.job-card {
    border-radius: 0px;
    /* margin-bottom: 20px; */
    padding: 15px;
    border-right: 1px solid #ddd;
    height: 100%;
}

.job-header {
    color: white;
    padding: 10px;
    border-radius: 10px;
}

.job-content {
    display: flex;
    justify-content: space-between;
}



.start-btn {
    background-color: green;
    color: white;
}

.end-btn {
    background-color: orange;
    color: white;
}

.job-card.inprogress .job-header {
    background: #ffa50036;
}

.job-card.lapsed .job-header {
    background: #8080802e;
}

.job-card.active .job-header {
    background: #00800024;
}


.job-card.overtime .job-header {
    background: #0000ff24;
}

.job-card.completed .job-header {
    background: #ff00001f;
}

.statusJobs {
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    font-size: 11px;
    float: right;
}

.SecondColJob.col-lg-2,
.ThirdColJob.col-lg-2 {
    padding-left: 0px;
    height: 100%;
}

.col-lg-2 .job-card {
    min-height: 185px;
}

.addTask.btn.button {
    background: #ef5e8c;
    color: #fff;
    font-size: 10px;
}

.job-card ul {
    padding-left: 15px;
}

.jobListStyle {
    background: #fff;
    margin-bottom: 15px;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
}

.job-content,
.job-card ul {
    color: #373232;
}

.SecondColJob .job-card ul {
    margin-bottom: 0px;
}

.job-actions {
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
}

.job-card div button {
    background: #277e39;
    border: 0px;
    color: #fff;
    margin-right: 4px;
    margin-top: 6px;
    font-size: 12px;
    padding: 2px 10px;
    border-radius: 4px;
}

.job-card div button:last-child {
    background: #808080;
}

.ant-modal-root {
    z-index: 9999;
    position: relative;
}

.ant-modal.modalStartEndStyle .ant-modal-footer {
    justify-content: flex-start;
    text-align: left;
}

.ant-modal.modalStartEndStyle .ant-modal-footer .ant-btn.ant-btn-primary {
    background: #ef5e8c;
    border-color: #ef5e8c;
    width: 50%;
}

.ant-modal.modalStartEndStyle .ant-modal-footer .ant-btn.ant-btn-default {
    background: #808080;
    width: 48%;
}

.ant-modal.modalStartEndStyle .ant-modal-title .anticon {
    top: -4px;
    position: relative;
}

.ant-picker-dropdown.ant-picker-dropdown-range {
    z-index: 9999;
}

.ant-select-selection-item {
    padding-left: 10px !important;
}

.ant-btn.ant-btn-primary {
    background: #ef5e8c !important;
    border-color: #ef5e8c !important;
    border-radius: 4px !important;
}

.ant-btn.ant-btn-text.ant-btn-dangerous {
    background: #b60f0f;
    color: #fff !important;
}

.ant-row.ant-form-item {
    margin-bottom: 10px;
}

.ant-btn-dangerous.overrideStyle span.anticon.anticon-delete {
    background: transparent !important;
    padding: 3px;
}

.job-actions .end-btn,
.job-actions .start-btn {
    padding: 3px 14px;
    border: 0px;
    border-radius: 4px;
}

.ant-btn.ant-btn-text.ant-btn-dangerous .anticon.anticon-delete {
    width: 30px;
    background: transparent !important;
    height: 30px;
    padding: 2px;
}

.job-card div button.editButton {
    margin: 0px;
    background: #79757e;
    border-radius: 7px;
    padding: 2px;
    width: 27px;
    height: 27px;
}

.job-card div button.editButton,
.SecondColJob div button.editButton,
.ThirdColJob div button.editButton {
    margin: 0px;
    margin-left: 0px;
    background: #79757e;
    border-radius: 10px;
    padding: 2px;
    width: 27px;
    height: 27px;
    float: right;
    margin-left: 7px;
}

.inlineField input {
    width: 80%;
    margin-right: 3%;
}

.ant-list-item .ant-input {
    border: 0px;
    border-bottom: 1px solid transparent;
    outline: none;
    box-shadow: none;
    padding: 0px;
}

.ant-btn.ant-btn-text.ant-btn-icon-only.ant-btn-dangerous.extrasmallIcon .anticon.anticon-delete {
    max-width: 17px;
    height: 17px;
    line-height: 1;
    padding: 0px;
}

.ant-btn.ant-btn-text.ant-btn-icon-only.ant-btn-dangerous.extrasmallIcon .anticon.anticon-delete svg {
    max-width: 12px;
    fill: #a33030;
}

.ant-modal-body .listStyleSmall ul li.ant-list-item {
    padding: 4px 10px !important;
    border: 1px solid #ddd;
}

.listStyleSmall .ant-btn.ant-btn-text.ant-btn-icon-only.ant-btn-dangerous.extrasmallIcon {
    padding: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
}

.ant-modal-body ul.ant-list-items {
    border-bottom: 1px solid #ddd;
    overflow: auto;
}

.job-card div button.geoMapButton {
    padding: 5px !important;
    background: transparent !important;
    margin-top: 0px !important;
    top: -3px;
}

.job-card div button.geoMapButton svg {
    fill: #ef5e8c;
}

.job-card div button.infoButton {
    padding: 6px;
    background: transparent !important;
    margin-top: 0px !important;
}

.job-card div button.infoButton svg {
    fill: #251616;
    width: 21px;
    height: 21px;
}

.ant-btn-text[disabled]:hover {
    background: #79757e;
}

.circularLog {
    position: absolute !important;
    top: 10px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background: #3f3e40;
    line-height: 20px;
    border-radius: 50%;
    font-size: 9px;
}

.ant-modal.roundedCorner .ant-modal-content {
    overflow: hidden;
    border-radius: 16px !important;
}

.statusJobs {
    min-width: 100px;
    text-align: center;
}

#PeriscopeManagement img {
    height: auto;
    width: 27px;
}

.jobLogList {
    border-bottom: 1px solid #ddd;
    overflow: auto;
}

.jobLogList.jobLogListNoFound {
    background: #ee9494;
    padding: 7px 14px;
    border: 1px solid #722222 !important;
    color: #722222;
}

.job-card div button.infoButton.infoButtonOrange svg {
    fill: #a65e00;
}

.job-card div button.infoButton.infoButtonOrange .circularLog {
    background: #a65e00;
}

.jobLogList .jobDivList {
    width: 25%;
    display: inline-block;
}

.jobLogList.jobLogListHeader {
    background: #b3b3b3;
    color: #fff;
}

.jobLogList.jobLogListHeader .jobDivList {
    padding: 4px 10px;
}

.ant-btn.ant-btn-text.ant-btn-icon-only.geoMapButton[disabled] {
    opacity: 0.5;
}