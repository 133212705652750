/* indonesia */
html[lang="id"] .threeList h4 {
    font-size: 14px;
    height: 40px;
}

html[lang="id"] .threeList .newHeadline small {
    font-size: 13px;
    margin-top: 5px;
    line-height: 1;
}

html[lang="id"] .threeList .newHeadline small.readmoreStyle {
    width: 102px !important;
    font-size: 11px !important;
    line-height: 25px;
}


html[lang="id"] .threatWatchAnalyticsMainDiv .contaminatedMainDiv .threeList {
    overflow: hidden;
    padding: 18px 15px;
    height: 100%;
}

html[lang="id"] .threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 strong.skeleton-text {
    font-size: 28px !important;
}

body>iframe[style*='2147483647'] {
    display: none;
}

html[lang="id"] .livearea {
    font-size: 11px;
}


html[lang="id"] aside ul li a font {
    vertical-align: top !important;
    line-height: 24px;
}

.ovEllepsis .font-bold {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

html[lang="id"] .subLineTab .sublinetablist {
    max-width: 150px;
    height: 40px;
    line-height: 1;
    vertical-align: top;
}

html[lang="id"] .report-filter label {
    font-size: 11px;
}

html[lang="id"] .listingRecordMainDiv .subLineTab .sublinetablist {
    line-height: 35px !important;
}

html[lang="id"] .threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 strong.skeleton-text {
    font-size: 31px;
}

html[lang="id"] .threeList .newHeadline {
    font-weight: bold;
    line-height: 50px;
}

html[lang="id"] .logoutDesc {
    font-size: 15px !important;
}

html[lang="id"] .threeList .skeleton-text .iconShape svg {
    width: 10px;
}

html[lang="id"] .threeList .newHeadline {
    font-size: 28px;
    font-weight: bold;
}

/* end indonesia */