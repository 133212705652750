.mapLiveStyle {
  text-align: center;
  background: #aebbc3;
  position: relative;
  min-width: 673px;
  min-height: 481px;
  height: 100%;
  width: 100%;
}
.mapLiveStyle img,.mapLiveStyle .mapBox {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 481px;
  margin: auto;
  width:673px
}
.area1 {
  background: radial-gradient(circle, #e60f0f9c 35%, #00aa15, 60%, #e8a504 70%);
  width: 33px;
  height: 33px;
  color: transparent;
  filter: blur(2px);
  background: radial-gradient(circle, #ae1e1e 20%,#5cae5c 50%, #ffd800 100%);
  border-radius: 26px;
  left: 4%;
  position: absolute;
  top: 7%;
}
.heatmap { width:100%; height:100%; }

.heatmapLiveRender {
  width: 673px;
  height: 485px;
}
.bgImg {
  position: absolute;
  z-index: -9;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mapLiveStyle #heatmap {
  float: none;
  margin: 0 auto;
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 481px;
  margin: auto;
  width: 673px;
}