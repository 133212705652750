.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 .threeList.threeList.threelist50 {
    width: 50%;
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 .threeList.threeList.threelist50 {
    width: 50%;
    top: 0;
    vertical-align: top;
}

.pAbs {
    text-align: center;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: 20px;
}

.threeList.threeList3.greenGradienColor .newHeadline small {
    margin-top: 0px;
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3.threatWatchAnalyticsMainDiv4 {
    height: 120px;
}

.threeList.threeList3.greenGradienColor {
    padding: 15px !important;
}

.threatWatchAnalyticsMainDiv.threatWatchAnalyticsMainDiv5.threatWatchAnalyticsMainDiv4.threatWatchAnalyticsMainDiv2.threatWatchAnalyticsMainDiv3 .threeList.threeList3.greenGradienColor {
    width: 33.33%;
}

.costBodaySubDept {
    padding: 5px 15px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
}

.customAccord {
    border: 0px;
}

.customAccord .ant-collapse-item {
    border: 1px solid #d9d9d9;
    margin-bottom: 10px;
}

.customAccord .ant-collapse-item .ant-collapse-header {
    padding: 7px 15px;
}

.customAccord .ant-collapse-item:first-child {
    margin-top: 10px;
}

.customAccord .ant-collapse-content-box .ant-collapse {
    border: 0px;
}

.customAccord .ant-collapse-content>.ant-collapse-content-box {
    padding: 10px;
}

.customAccord .ant-collapse-content>.ant-collapse-content-box .costBodaySubDept:last-child {
    margin-bottom: 0px;
}

.headerAreaCost {
    padding: 10px;
    border: 1px solid #ddd;
    font-weight: bold;
}

.costBodaySubDept .col-lg-4 {
    padding-left: 25px;
}

.costBodayDept,
.costBodaySubDept {
    font-weight: 600;
    color: #32325d !important;
}

.costBodaySubDept.costBodaySubDeptAgency {
    margin-bottom: 0px;
    margin-top: 10px;
}

.btnINline .btn.btn-pink.btn-primary[disabled]:not(.active) {
    opacity: .3;
}