$font-bold: 600;
@import url('../../assets/styles/font.css');

body * {
    font-family: 'Switzer';
}

.site-color {
    color: #908fab;
}

.labelfixchart {
    letter-spacing: .4px;
}

.tool-tip-status {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 50%;
}

.white-bg {
    background: #FFFFFF;
}

.Toastify__toast--error {
    color: #756a6a;
    padding: 0px 10px;
    height: 45px;
    max-height: 45px;
    min-height: 45px;
}

.Toastify__toast--error .Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar--error {
    background: #b51e1e;
}

.ag-header,
.ag-pinned-left-header,
.ag-pinned-right-header {
    display: inline-block;
    overflow: visible !important;
    position: relative;
}

.Toastify__toast-container.Toastify__toast-container--top-right .Toastify__close-button.Toastify__close-button--error svg {
    fill: #000;
}

.Toastify__toast--error .Toastify__toast-body {
    color: #fff !important;
    line-height: 1.1;
}

.wrapper {
    padding: 1.5rem;
}

.p-l-0 {
    padding-left: 0 !important;
}

.p-r-0 {
    padding-right: 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

.p_0_5rem {
    padding: 0.5rem
}

.m-l-r-0 {
    margin-left: 0;
    margin-right: 0;
}

.font-bold {
    font-weight: $font-bold;
}

.font-normal {
    font-weight: 500;
}

.m-t-xs {
    margin-top: 5px;
}

.m-t-sm {
    margin-top: 10px;
}

.m-t {
    margin-top: 15px;
}

.m-t-md {
    margin-top: 20px;
}

.m-t-lg {
    margin-top: 25px;
}

.m-t-7rem {
    margin-top: 7rem;
}

.m-r-sm {
    margin-right: 10px;
}

.m-r {
    margin-right: 15px;
}

.m-r-md {
    margin-right: 20px;
}

.m-r-lg {
    margin-right: 25px;
}

.m-l-sm {
    margin-left: 10px;
}

.m-l {
    margin-left: 15px;
}

.m-l-md {
    margin-left: 20px;
}

.m-l-lg {
    margin-left: 25px;
}

.m-b-sm {
    margin-bottom: 10px;
}

.m-b {
    margin-bottom: 15px;
}

.m-b-md {
    margin-bottom: 20px;
}

.m-b-lg {
    margin-bottom: 25px;
}

.m-b-xs {
    margin-bottom: 5px;
}

.b-l {
    border-left: 1px solid #e9ecef;
}

.b-r {
    border-right: 1px solid #e9ecef;
}

.b-b {
    border-bottom: 1px solid #e9ecef;
}

.commonLanguageDropDownDiv {
    width: 100%;
}

.dangerColor {
    color: red;
}

.redBorderColor {
    border: solid 1px #ff6f55;
}

.greenBorderColor {
    border: solid 1px #06e06d;
}

.successTextColor {
    color: green;
}

.container {
    max-width: 100% !important;
}

.thumbIconDiv.greenBorderColor.red {
    border-color: #fb8e67;
}

.siteViewMainDiv .siteViewRightSideDiv .increaseDecreasePercentageDiv.font-bold {
    padding: 0.05rem 0.8rem;
    border-radius: 14px;
    border: solid 1px #ffffff;
    background-color: #ffffff;
    vertical-align: middle;
    display: inline-block;
    margin-left: 0.5rem;
    font-size: 0.75rem;
    height: 24px;
}

.eachIndexDiv h5.font-bold {
    font-size: 16px;
}

.eachPeakHoursDiv span.time-tip.font-bold {
    padding-left: 30px;
}

.eachCard .card-body .locationNameDiv {
    text-transform: capitalize;
}

.ant-checkbox-checked::after {
    border: 1px solid #ef5e8c !important;
}

.gridHeaderTitileCustom span {
    display: block;
    font-size: 12px;
    color: #32325d;
    font-weight: bold;
}

.listingRecordMainDiv .eachCard .col {
    padding-left: 8px;
    padding-right: 8px;
}

.commonHeadingDateMainDiv {
    width: 15%;
    display: inline-block;
    vertical-align: text-bottom;
    padding: 0 1rem;
    padding-left: 0;
    text-align: center;
    min-width: 120px;

    .react-datepicker-wrapper {
        width: 60% !important;
        vertical-align: middle;
        cursor: pointer;

        input {
            border: none !important;
            outline: none !important;
            background: none !important;
            width: 100% !important;
            text-align: left;
            padding-left: 10px;
            cursor: pointer;
            caret-color: transparent;
            min-width: 80px;
        }
    }
}

.dashboardPeopleAndDateMainDiv {
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    margin-bottom: 1rem;

    .dashboardPeopleAndEmployeeMainDiv {
        display: inline-block;
        padding: 0.2rem 0.5rem;
        border-radius: 25px;
        background-color: #55597c;
        color: #FFFFFF;
        font-size: 12px;
        width: 32rem;
        font-size: 0.75rem !important;
        text-align: center;
        font-weight: bold;

        .deviceStatus {
            padding: 0 0 4px;
        }

        .peopleOnPremisesInnerDiv {
            padding-left: 1rem;
            text-align: left;
            display: inline-block;
            vertical-align: middle;
            padding-top: 0.25rem;
            width: 120%;


            img {
                margin-right: 0.5rem;
            }

            span {
                vertical-align: middle;
                display: inline-block;
                width: 47%;
            }
        }

        .employeeCountInnerDiv {
            text-align: left;
            display: inline-block;
            vertical-align: middle;

            .empCount {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

.dashboardLanguageMainDiv {
    display: inline-block;
    vertical-align: super;
    width: 25%;
}

.font-bold.top-seperator {
    margin-top: 10px;
}

.startDateEndDateMainDiv {
    padding: 0 0.75rem;
    position: relative;
    // background: url('../images/down-arrow.png') no-repeat; 

    .react-datepicker-wrapper {

        vertical-align: middle;
        cursor: pointer;
        width: 100%;

        input {
            border: solid 1px #d2d3dd !important;
            outline: none !important;
            padding: 0.5rem;
            border-radius: 0.25rem;
            background-color: #FFFFFF !important;
            width: 100% !important;
            cursor: pointer;
            caret-color: transparent;
            background: url('../images/down-arrow.png') no-repeat 95%;
            background-size: 18px;
        }

        input[type="text"]:disabled {
            background: #dddddd !important;
        }

    }

    // .dropdownIconDiv{
    //     position: absolute;
    //     top: 0.5rem;
    //     right: 1.2rem;

    //     img{
    //         width: 16px;
    //     }
    // }   
}


.commonBlackColor {
    color: #4d4f5f;
}

.cursor-pointer {
    cursor: pointer;
}

.commonLangaugeStyleDiv {
    width: 20%;
    vertical-align: middle;
    display: inline-block;
}

.v-middle {
    vertical-align: middle;
}

.greenGradientBGColor {
    background: linear-gradient(181deg, #04e06e, #a5e006)
}

.orangeGradientColor {
    background: linear-gradient(181deg, #fa7e5e, #fecf89)
}

.yellowGradientBGColor {
    background: linear-gradient(181deg, #f6bd3c, #ffd782)
}

.displayNone {
    display: none !important;
}


.closeModalIconDiv {
    position: absolute;
    top: 0px;
    right: -1rem;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background: #FFFFFF;

}


.viewAllEmployeesButton {
    background-color: #ef5e8c !important;
    color: white !important;
    box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.3);
    padding: 0.625rem 1.25rem;
    border: thin solid transparent;
    outline: none;
    font-size: 0.875rem;
    display: inline-block;
    border-radius: 0.35rem;
    cursor: pointer;
}

.gridHeaderTitileCustom {
    font-size: 14px;
}

.employee-detail-table-tr {
    border-bottom: 0.063rem solid #bfc2c7;
    display: table;
    width: 100%;
    padding: 1rem;
    table-layout: fixed;

    &:last-child {
        border-bottom: none;
    }
}

.employee-detail-table-tr-td {
    text-align: right;
}

.manpowerManagementEmployeeListMainDiv .listingRecordMainDiv .eachCard .col {
    padding-left: 15px;
    padding-right: 15px;
}

.modal-mapowermanagement.risk .siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .flexDiv:first-child {

    min-width: 2% !important;
    max-width: 2% !important;
}

.modal-mapowermanagement.risk .listingRecordMainDiv .eachCard .card-body .col:first-child {
    min-width: 2% !important;
    max-width: 2% !important;
}

.modal-mapowermanagement.risk .siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .flexDiv:nth-of-type(2) {
    min-width: 15% !important;
    max-width: 15% !important;
}

.modal-mapowermanagement.risk .listingRecordMainDiv .eachCard .card-body .col:nth-of-type(2) {
    min-width: 15% !important;
    max-width: 15% !important;
}

.modal-mapowermanagement.risk .siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .flexDiv:nth-of-type(3) {
    min-width: 10% !important;
    max-width: 10% !important;
}

.modal-mapowermanagement.risk .listingRecordMainDiv .eachCard .card-body .col:nth-of-type(3) {
    min-width: 10% !important;
    max-width: 10% !important;
}

.modal-mapowermanagement.risk .siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .flexDiv:nth-of-type(4) {
    min-width: 11% !important;
    max-width: 11% !important;
}

.listingRecordMainDiv .eachCard .card-body .col:nth-of-type(4) {
    min-width: 11% !important;
    max-width: 11% !important;
}

.modal-mapowermanagement.risk .siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .flexDiv:nth-of-type(5) {
    min-width: 11% !important;
    max-width: 11% !important;
}

.listingRecordMainDiv .eachCard .card-body .col:nth-of-type(5) {
    min-width: 11% !important;
    max-width: 11% !important;
}

.CloseFixSize {
    // width: 160px;
    text-align: center;
}

.font-bold.CloseFixSize {
    text-align: center;
    width: 100%;
}

.siteManagementMainDiv .siteListMainDiv .listingRecordMainDiv .eachCard .card-body .flexDiv:last-child,
.listingRecordMainDiv .eachCard .col:last-child {
    border-right: 0px;
}

.MobilityFixSize {
    width: 100px;
    text-align: center;
}

.VisitedFixSize {
    width: 135px;
    text-align: center;
}

.text-align-center {
    text-align: center
}

.MobilityModelSize {
    width: 130px;
    text-align: center;
}

.leftSideBarDiv ul li.disabled-li {
    pointer-events: none !important;
    cursor: auto !important;
    opacity: .5 !important;

}

body .ant-btn {
    color: #fff !important;
}

.eachMostVisitedAreaDiv.eachMostVisitedAreaDivCapacity .areaNameDiv {
    width: 100% !important;
    padding-left: 0px;
}

.eachMostVisitedAreaDiv.eachMostVisitedAreaDivCapacity .areaNameDiv div {
    float: right;
}

.eachMostVisitedAreaDiv.eachMostVisitedAreaDivCapacity .areaNameDiv .font-bold {
    float: left;
    width: 90%;
    line-height: 40px;
    padding-bottom: 0px;
}

.eachMostVisitedAreaDiv.eachMostVisitedAreaDivCapacity .categoryName {
    border: 0px;
    line-height: 40px;
}

.eachMostVisitedAreaDiv.eachMostVisitedAreaDivCapacity .areaNameDiv {
    border-bottom: 1px solid #ddd;
    padding: 0px;
}

.top-title {
    background-color: #ecf2f9;
    line-height: 40px;
    display: block;
    padding: 0px 15px;
    margin-bottom: 8px;
    border-radius: 4px;
}

.top-title h6 {
    line-height: 40px;
    font-weight: bold;
    color: #0d0d0e;
}

.mostInteractedMainDiv2 th,
.mostInteractedMainDiv2 td {
    color: #000;
}

.manpowerManagementMainDiv.mostInteractedMainDiv2 .employeeDetailsMainDiv .mostInteractedMainDiv .tableBox th {
    font-size: 14px;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
    border-color: #ef5e8c !important;

}

.ant-radio-checked::after {
    border: 1px solid #ef5e8c !important;
}

.text-right.col-lg-5 .testedPositiveDatePickerMainDiv {
    max-width: 85px;
    float: right;
}

.text-right.col-lg-5 .testedPositiveDatePickerMainDiv .react-datepicker__input-container {
    max-width: 62px;
    margin-left: 10px;
}

.text-right.col-lg-5 .testedPositiveDatePickerMainDiv input {
    border: 0px;
    line-height: 25px;
    vertical-align: middle;
}

.close-modal-new {
    position: absolute;
    line-height: 24px;
    font-size: 15px;
    border: 1px solid #ef5e8c;
    height: 30px;
    width: 30px;
    text-align: center;
    color: #ef5e8c;
    border-radius: 50%;
    right: 10px;
    top: 10px;
    padding: 0px;
    font-weight: 600;
    cursor: pointer;
}

.highcharts-point-select-2.highcharts-point-select-active {
    fill: #ef5e8c !important;
    stroke: #ef5e8c !important;
}

.ag-cell-wrapper .bottom-val {
    line-height: 1;
    color: #32325d !important;
    font-size: 12px !important;
    margin-top: 4px;
}

.ag-cell-wrapper .font-bold {
    margin-bottom: 0px;
    font-size: 16px;
    line-height: 22px;
}

.ag-cell-wrapper .bottom-val b {
    font-weight: 600 !important;
}

.cell-size-75 .ag-cell {
    line-height: 75px !important;
    font-weight: 600;

}

.cell-size-55 .ag-cell {
    line-height: 55px !important;
    font-weight: 600;
}

.riskDivCovid {
    text-align: center;
    border-radius: 20px;
    font-weight: bold;
    font-size: 12px;
    padding: 4px 0;
    text-transform: capitalize;
    color: var(--green);
    border: 2px solid var(--green);
    line-height: 20px;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 100px;
    margin: auto;
    bottom: 0;
    height: 30px;
    top: 0;
}

.riskDivCovid.riskDivCovid-positive,
.riskDivCovid.riskDivCovid-Positive,
.riskDivCovid.riskDivCovid-qurantine {
    color: var(--red);
    border: 2px solid var(--red);
    line-height: 20px;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 100px;
    margin: auto;
    bottom: 0;
    height: 30px;
    top: 0;
}

.ag-cell {
    cursor: pointer;
}

.ag-theme-alpine .ag-checkbox-input-wrapper.ag-checked::after {
    color: var(--ag-checkbox-checked-color, var(--ag-alpine-active-color, #ea5d8b)) !important;
}

.ag-theme-alpine .ag-row-selected {
    background-color: var(--ag-selected-row-background-color, rgba(238, 238, 238, 0.7)) !important;
}

.ag-overlay-no-rows-center {
    font-size: 20px;
    top: 60px;
    position: absolute;
}


.tooltipcustom {
    height: 16px;
    width: 16px;
    margin-left: 5px;
    margin-right: 5px;
}

.tooltipcustom img {
    max-width: 16px;
    position: absolute
}

.ag-header-viewport {
    position: relative;
    z-index: 9;
}

.descHelp.tootltipHelp {
    font-weight: normal !important;
}

.tooltipcustom:hover .descHelp {
    display: block;
    top: 35px;
    white-space: normal !important;

}

.ag-header-cell-text.less-width-status span {
    display: block;
    font-size: 12px;
}

.ag-header-cell-text {
    white-space: normal !important;
}

.ag-cell .riskDiv,
.ag-cell .riskDivNegative {
    text-align: center;
    color: #FF0000;
    border: 2px solid #FF0000;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;
    padding: 4px 0;
    width: 100%;
    text-transform: capitalize;
    line-height: 15px;
}

.ag-cell .riskDivNegative {
    color: var(--green);
    border: 2px solid var(--green)
}

.font-bold.text-cap {
    text-transform: capitalize;
}

.descHelp.tootltipHelp {
    width: auto;
}

.ag-header-row .ag-header-cell:last-child .descHelp.tootltipHelp {
    right: 0px !important;
    /* display: none !important; */
}

.ag-cell[col-id="name"] {
    text-transform: capitalize;
}

.ag-center-cols-clipper .ag-cell {
    text-align: center;
}

.ag-center-cols-clipper .ag-cell:first-child {
    text-align: left;
}

.ReactModalPortal {
    z-index: 9999;
    position: relative;
}

.Toastify {
    z-index: 9999999;
    position: relative;
}



/*Some Manipulation*/
.ag-header {
    overflow: visible !important;
}

.ag-header-viewport {
    overflow: visible !important;
    z-index: 9;
}

.ag-header-viewport .ag-header-container {
    overflow: visible !important;
}

.ag-header-row {
    position: absolute;
    overflow: visible;
    height: 400px !important;
}

.ag-theme-alpine .ag-header-cell-resize {
    // align-items: flex-start !important;
    height: 50px !important;
}

.ag-header {
    display: flex;
    width: 100%;
    white-space: nowrap;
    min-height: 50px !important;
    height: 50px !important;
}

.ag-header-row {
    min-height: 50px !important;
    height: 50px !important;
}

.ag-cell-label-container {
    // align-items: flex-start !important;
    overflow: visible !important;
}

// .ag-header-group-cell-label{align-items: flex-start !important;}
// .ag-header-cell-label{align-items: flex-start !important;}
// .ag-header-cell{align-items: flex-start !important;}
.ag-header-cell {
    // align-items: flex-start !important;
    overflow: visible !important;
    height: 50px !important;
}

.ag-header-row {
    overflow: visible !important;
}

.ag-theme-alpine .ag-ltr .ag-header-select-all {
    margin-right: 10px;
}

.ag-root-wrapper.ag-layout-normal.ag-ltr {
    border: 1px solid rgb(202, 209, 215);
    border-radius: 6px;
    z-index: 9;

}

.ag-header-cell-label {
    justify-content: center;
}

.ag-header-row .ag-header-cell:first-child .ag-header-cell-label {
    justify-content: left;
}

/**/

.ant-select-dropdown {
    z-index: 2147483647 !important;
}

.ag-theme-alpine .ag-row .ag-cell:first-child div {
    background: transparent;
}

.ag-theme-alpine .ag-checkbox-input-wrapper::after {
    bottom: 0;
    margin: auto;
    height: 20px;
    background: transparent;
}

.ag-theme-alpine .ag-checkbox-input-wrapper:focus-within,
.ag-theme-alpine .ag-checkbox-input-wrapper:active {
    outline: none;
    box-shadow: none !important;
}

.apiLoaderPermission img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
}

.apiLoaderPermission {
    position: absolute;
    right: 0;
    margin: auto;
    left: 0;
    text-align: center;
    height: 100vh;
}

.errorPermission {
    background: #fbb;
    font-size: 16px;
    padding: 10px;
    margin-top: 30px;
    border-left: 4px solid #b03434;
}

.center404 {
    text-align: center;
    background: #ebf3fa;
}

div.disable,
.disable {
    opacity: .9;
    position: relative;
}

.hover-message {
    z-index: 99;
}

.hover-message:hover .descHelp.tootltipHelp {
    display: block;
    z-index: 99;
}

.dashboardPeopleAndDateMainDiv .dashboardPeopleAndEmployeeMainDiv .col-lg-3 {
    width: 25%;
    flex: 0 0 25%;
}

span.textTitle {
    float: left;
    width: calc(100% - 30px);
}

.innerDivAccord .Parencat .row {
    display: table;
    width: 100%;
}

.innerDivAccord .SecondLevelCat .row div[class*="col"],
.innerDivAccord .Parencat .row div[class*="col"] {
    display: table-cell;
    vertical-align: middle;
}

.styledDate {
    border-radius: 25px;
    color: #55597c;
}

.manpowerManagementEmployeeListMainDiv .listingSearchMainDiv .startDateEndDateMainDiv label {
    margin-bottom: 0px;
}

.ant-select-dropdown.smallerDrop {
    width: 160px !important;
}

.header-breadcrumb.container .tabHeader .tabHeaderList {
    display: inline-block;
    cursor: pointer;
}

.header-breadcrumb.container .tabHeader .tabHeaderList h5 {

    font-size: 13px;
    background-color: #fff;
    padding: 9px 20px;
}

.header-breadcrumb.container .tabHeader .tabHeaderList:first-child h5 {
    border-right: 1px solid #ddd;
}

.header-breadcrumb.container .tabHeader .tabHeaderList.active h5 {
    color: #fff;
    background-color: #ef5e8c;
}

.report-filter-time-picker .MuiFormControl-root.MuiTextField-root .MuiInputBase-input {
    padding: 5px 20px;
}

.rmdp-day-picker div .rmdp-week .rmdp-day:nth-of-type(1) {
    opacity: .5;
    cursor: auto;
    pointer-events: none;
}

.rmdp-week-day {
    font-size: 12px;
    color: #000;
    font-weight: 600;
}

.datePickerSmall .rmdp-input {
    max-width: 90px;
    padding: 0px 10px;
    font-size: 11px;
    height: 27px;
}

#deckgl-wrapper {
    z-index: 9 !important;
    // background: #000 !important;
    // height: 300px !important;
    border: 1px solid #ddd;
    height: 600px !important;
    // background: #000;
}

.clockWIthTIme svg {
    max-width: 16px;
    fill: #32325d;
}

div.skiptranslate {
    display: none !important;
}

#google_translate_element .skiptranslate {
    display: block !important;
}

body {
    top: 0px !important;
}

.react-datepicker__tab-loop {
    display: inline-block;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
    display: none !important;
}

#google_translate_element .goog-te-gadget-simple .goog-te-gadget-icon {
    display: none !important;
}

.goog-te-gadget-simple {
    padding: 7px 10px !important;
    border: 1px solid #e1e1e1 !important;
    border-radius: 7px !important;
}

.livearea.liveAreaHelp::before {
    display: none;
}

font {
    vertical-align: top !important;
    box-shadow: unset !important;
}

.googleWidget {
    position: absolute;
    left: auto;
    right: 190px;
    top: 10px;
    z-index: 9;
}

.manpowerManagementMainDiv {

    min-height: 100vh !important;
}

.textCapUppercase {
    text-transform: capitalize;
}

.modalHeader h4 {
    width: 100%;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    line-height: 40px;
    position: relative;
}

.modalHeader h4 .closeBtn {
    position: absolute;
    right: 0;
    top: 5px;
    width: 25px;
    height: 25px;
    background: #ef5e8c;
    color: #fff;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
}

.ant-select.bdrSelect.ant-select-single {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #ddd;
    box-shadow: unset !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
    position: absolute;
    right: 0 !important;
    left: 0 !important;
}

.ErrorNoFound {
    padding: 10px 20px;
    font-size: 15px;
    border-left: 3px solid #862121;
    color: #862121;
    background: #e6e6e6;
    margin-top: 20px;
}

.boxStatsSmall .borderRightDashed {
    border-right: 2px dashed #ddd;
}

.boxStatsSmall .boxStatSmallHeader .borderRightDashed {
    font-weight: bolder;
    font-size: 10px;
    background: #f4f4f4;
    padding: 0px 5px;
}

.boxStatsSmall {
    padding: 0px;
    border: 1px dashed #ddd;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 13px;
    text-align: center;
    background-color: #f4f4f4;
}

.boxStatsSmall .boxStatsContent .borderRightDashed {
    line-height: 1;
    padding-bottom: 7px;
    font-size: 14px;
    background: #f4f4f4;
}

.boxStatsContent .row .borderRightDashed:last-child,
.boxStatSmallHeader .row .borderRightDashed:last-child {
    border: 0px;
}

body .ant-tooltip.ant-slider-tooltip .ant-tooltip-inner {
    padding: 0px;
    min-height: 10px;
    font-size: 10px;
    color: #9b9494 !important;
}

.mgSpacenewTooltip2 .ant-slider-track.ant-slider-track-1::before {
    background-image: linear-gradient(96deg, #f58080 20%, #d6150b 111%) !important;
}

.dashboardLanguageMainDiv .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 10px !important;
}

.commonLangaugeStyleDiv {
    display: none;
}

.ant-select-selection-placeholder {
    padding-left: 10px !important;
}