.manStyle {
  border-radius: 0.5rem;

  .inlineBlockFilter {
    display: inline-block;
    width: auto;
    padding: 5px 12px;

  }

  .inlineBlockFilter .ant-select {
    border: 1px solid #ddd;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    padding-left: 10px;
  }

  border-radius: 0.5rem;

  .inlineBlockFilter {
    display: inline-block;
    width: auto;
    padding: 5px 12px;

  }

  .inlineBlockFilter .ant-select {
    border: 1px solid #ddd;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 0px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    padding-left: 10px;
  }

}

#smplr-container canvas+div {
  left: 17% !important;
  height: calc((60%/9) * 16) !important;
  bottom: 17% !important;
}

.manStyle .inlineBlockFilter label {
  font-weight: bold;
  font-size: 13px;
}

.legendArea.manStyle {
  padding: 20px;
}

.legendList {
  border-bottom: 1px solid #ddd;
  float: left;
  width: 100%;

  div {
    font-size: 12px;
    list-style: 25px;
  }

  .legendLeftAreaProd {
    // float: left;
    // width: 80%;
  }

  .legendRightAreaProd {
    // float: left;
    // width: 20%;
    // text-align: right;

  }
}

.legendList.disable {
  opacity: .7;
}

.inlineBlockFilter .button.btn-productive-filter {
  background: #f5f5f5;
  font-size: 13px;
  margin-right: 10px;
  border: 1px solid #d9d9da;
  height: 35px;
  padding: 0px 19px;
  transition: all ease-in 0.3s;
  border-radius: 4px;
}

.inlineBlockFilter .button.btn-productive-filter:hover,
.inlineBlockFilter .button.btn-productive-filter.active {
  background: #ef5e8c;
  color: #fff;
  border-color: #ef5e8c;
}

.inlineBlockFilter .button.btn-productive-filter[disabled] {
  opacity: .7;
  cursor: auto;
  pointer-events: none;
}

.legendList .legendLeftAreaProd.legendLeftAreaProdSmall {
  width: 23%;
  text-align: center;
  font-size: x-small;
}

.legendList .legendLeftAreaProd {
  float: left;
  width: 31%;
}

.legendRightAreaProd.disabled.nonCLickable {
  opacity: .5;
  cursor: auto;
  pointer-events: none;
}

.legendLeftAreaProd.legendLeftAreaProdSmall.legendLeftAreaProdSmallRed {
  color: #a12525;
  //font-weight: 700;
}

.legendLeftAreaProd.legendLeftAreaProdSmall.legendLeftAreaProdSmallOrange {
  color: #ce622d;
  //font-weight: 700;
}

.legendLeftAreaProd.legendLeftAreaProdSmall.legendLeftAreaProdSmallGreen {
  color: #20862b;
  //font-weight: 700;
}

.legendList .legendRightAreaProd svg {
  cursor: pointer;
}

.legendList.legendHeader div {
  line-height: 1;
  margin-bottom: 2px;
}

.legendList .legendRightAreaProd svg {
  // width: 13px;
  text-align: left;
  float: none;
}

.greenLive::before {
  content: '';
  width: 6px;
  height: 6px;
  background: #4fbb1d;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 5px;
  border-radius: 50%;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.greenLive {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  padding: 0px 10px 0px 15px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 13px;
  line-height: 20px;
  float: right;
  margin-top: 13px;
  margin-right: 13px;
}

.timerLive {
  display: inline-block;
  text-align: right;
  float: right;
  margin-top: 8px;
}

.timerLive .toptime {
  display: block;
  line-height: 1;
  margin-right: 10px;
  color: #ef5e8c;
  font-size: 12px;
  font-weight: bold;
}

.timerLive .currentDate {
  display: block;
  margin-top: 0px;
  line-height: 1;
  margin-right: 10px;
  font-size: 12px;
}

.manStyle .inlineBlockFilter .ant-select.ant-select-multiple .ant-select-selector {
  height: 34px;
  overflow-y: scroll;
}

.manStyle .inlineBlockFilter .ant-select {
  border: 1px solid #ddd;
  height: 37px;
  background: #f5f5f5;
}

.manStyle .inlineBlockFilter .ant-select.ant-select-multiple .ant-select-selection-item {
  display: inline-block;
  font-size: 12px;
}

.manStyle .inlineBlockFilter .ant-select.ant-select-multiple .ant-select-selection-item .ant-select-selection-item-content {

  padding-right: 25px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

}

.manStyle .inlineBlockFilter .ant-select-multiple .ant-select-selection-item-remove {
  position: absolute;
  right: 0px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 7px;
  line-height: 10px;
  background: #ef5e8c;
  color: #fff;
  padding: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
}


.sc-ftvSup.sc-papXJ.sc-iqcoie.cWzGmc.kOKHDm.jBSAFQ {
  display: none !important;
}

#smplr-container canvas+div {
  left: 17% !important;
  height: calc((60%/9) * 16) !important;
  bottom: 17% !important;
}

#smplr-container {
  width: 140% !important;
  height: calc((140%/9) * 16) !important;
  margin-left: calc(-10px - 20%);
  margin-right: calc(-10px - 20%);
  height: calc((100%/9) * 16) !important;
  margin-top: -30%;
}

.manStyle {
  position: relative;
  overflow: hidden;
}

.greenLive:not(.disabledClick):hover {
  background: #ef5e8c;
  color: #fff;
  border-color: #ef5e8c;
}

.greenLive:not(.disabledClick):hover::before {
  background: #fff;
}

.greenLive {
  cursor: pointer;
}

.greenLive.disabledClick {
  cursor: auto;
  pointer-events: none;
}

.align-with-title {
  display: inline-block;
}

.headerCategory {
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 50px;
}

.filter-parent {
  cursor: pointer;

  svg {
    max-width: 18px;
    position: relative;
    top: -2px;
    margin-left: 10px;
  }
}

.filter-icon.active svg {
  max-width: 10px;
}

.headerCategory {
  display: table;
  width: 100%;
  margin-right: 0px;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 100%;
  box-shadow: 0px 0px 5px 0px #fdfdfdbf;
  margin-bottom: 10px;
  display: table;
  padding: 5px 0px;

  .align-middle {
    display: table-cell;
  }
}

.contentCategory {
  margin-right: 0px;
  // border: 1px solid #e1e1e1;
  // border-radius: 4px;
  height: 100%;
  // box-shadow: 0px 0px 5px 0px #fdfdfdbf;
  // margin-bottom: 10px;
  // border-bottom: 1px solid black;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  display: table;
  width: 110%;

  .sub-stats {
    line-height: 1;

    strong {
      color: #f15f8d;
    }
  }

  .align-middle {
    display: table-cell;
    cursor: pointer;
  }

  .my-header-class {
    font-size: 10px;
  }

  .my-cell-class {
    font-size: 10px;
  }


  h4 {
    display: table-cell;
    margin-bottom: 0px;
    font-size: 16px;
    color: #212337;
    display: table-cell;
  }

  .align-wrapper {
    display: flex;
    align-items: center;
    max-width: 120%;
    cursor: pointer;
  }

  .Category-header {
    display: table;
    width: 100%;
  }

  .Category-content {
    height: 0px;
    transition: height ease-in 0.3s;
    overflow: hidden;
  }

  .align-with-title {
    display: table-cell;
    vertical-align: middle;
  }
}

.accordion-icon {
  display: table-cell;
  width: 20px;
  vertical-align: middle;
  cursor: pointer;

  svg {
    max-width: 12px;
    fill: #596367;
  }

  &:hover {
    svg {
      fill: #f15f8d;
    }
  }

}

.contentCategory:hover {
  box-shadow: 0px 0px 5px 0px #cabdbd7a;
}

.contentCategory .Category-content.active .row {
  margin: 0px;
}


.contentCategory .Category-content.active {
  height: auto;
  padding: 5px;
  border-top: 1px solid #ddd;
}

.contentCategory .Category-content .subContentTable {
  border: 1px solid #ddd;
}

.contentCategory .Category-header {
  padding: 2px 0px;
}

.contentCategory.active .Category-header {
  background: #ffeef3;
}


.headerCategory .align-middle.siteManagementlast {
  max-width: 315px;
  min-width: 315px;
  float: right;
  text-align: center;
}

td {
  text-align: center;
  vertical-align: middle;
  //border: 0.5px solid black;
  padding: 5px;
  font-size: 14px;
}


table {
  border-collapse: collapse;
  width: 100%;
}

th {
  //border: 0.5px solid #ddd;
  padding: 10px;
  text-align: left;
}

th {
  //background-color: palevioletred;
  background-color: rgba(220, 20, 60, 0.3);
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  font-size: 10px;
}

tr {
  background-color: #f5f5f5;
}

tbody td:first-child {
  font-size: 12px;
}

.eyes {
  height: 40;
}

.customContainer {
  display: flex;
  align-items: center;
}

.customLeftArea {
  // margin-right: 8px;
}

.customPlanned {
  // margin-right: 12px;
}

.customDataLength {
  // margin-right: 12px;
}

.customRightArea {
  display: flex;
  align-items: center;
}

.customEye {
  margin-left: 24px;
}

.customContainer {
  display: flex;
  align-items: center;
}

.customLeftArea {
  // margin-left: 25px;
}

.customPlanned {
  // margin-left: 66px;
  font-size: 10px;
}

.customDataLength {
  // margin-left: 40px;
  font-size: 10px;
}

.customRightArea {
  // margin-left: 42px;
  margin-top: 3px;
}

td.locationName {
  font-size: 10px;
  width: 80%;
  margin-left: 12px;
  color: grey;
  display: flex;
  align-items: left;
  text-align: left;
  text-transform: capitalize;
  text-overflow: ellipsis;
}

.btn.btn-pink.btn-tabCategory {
  position: absolute;
  font-size: 11px;
  border: 1px solid #ddd;
}

.tooltipTime {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
  left: -20px;
  width: 80px;
  text-align: center;
  position: absolute;
  background: #f4f4f4;
  border-radius: 5px;
  text-align: left;
  padding: 0px;
  font-size: 10px;
  z-index: 99;
  box-shadow: 0 5px 15px 0 rgba(85, 89, 124, 0.2);
  min-width: 80px;
  visibility: visible;
  color: #000;
  text-align: center;
}

.slider-point.hovered-point .tooltipTime {
  opacity: 1;
  visibility: visible;
}

.liveDataPlayInner .ant-slider.ant-slider-horizontal {
  width: 97%;
  margin: 10px auto;
  top: 3px;
}

.highlightTillPlay {
  width: 96%;
  height: 100%;
  background: #ef5e8c;
  border-radius: 20px;
}

.ant-tooltip.ant-slider-tooltip .ant-tooltip-inner {
  background-color: #f4f4f4;
  color: #000;
}

.ant-tooltip.ant-slider-tooltip .ant-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: linear-gradient(to left, #f4f4f4 50%, #f4f4f4 50%) no-repeat -10px -10px;
  content: '';
  color: #000;
}

// iframe {
//   z-index: -1 !important;
// }

.playPauseThing svg {
  fill: #fff;
}

.playPauseThing {
  width: 40px;
  margin-left: 20px;
  float: left;
  margin-top: 10px;
  background: #ef5e8c;
  border-radius: 50%;
  height: 40px;
  padding: 2px;
  vertical-align: middle;
  cursor: pointer;
}

.liveDataRightArea {
  width: calc(100% - 60px);
  float: right;
}